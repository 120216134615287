import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchJobApplicants,
  updateJobApplicationStatus,
} from "../../../../../redux/actions/jobActions";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ChangeStatusTag = ({ initialStatus, applicationId, token }) => {
  const { jobId } = useParams();
  const [status, setStatus] = useState();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.job?.loading);
  const prevLoading = useRef(loading);
  const [toastShown, setToastShown] = useState(false);

  useEffect(() => {
    setStatus(initialStatus);
  }, [applicationId]);

  let color, textColor;

  switch (status) {
    case "Shortlisted":
      color = "rgba(205, 231, 217, 1)";
      textColor = "#038840";
      break;
    case "Rejected":
      color = "rgba(218, 0, 0, 0.2)";
      textColor = "#DC0000";
      break;
    case "Fit-For-Job":
      color = "rgba(255, 149, 0, 0.2)";
      textColor = "#FF9500";
      break;
    case "Under-Review":
      color = "grey";
      textColor = "black";
      break;
    case "Requested":
      color = "rgb(36 67 177 / 26%)";
      textColor = "#1865c1";
      break;

    default:
      color = "grey";
      textColor = "black";
  }
  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;

    if (status === "Requested") {
      toast.warning("Add credits so that candidate can give interviews.");
      setStatus(status);
      return;
    }

    if (newStatus === "Fit-For-Job") {
      toast.warning("Recruiters can't change to this status.");
      setStatus(status);
      return;
    }

    let newStatusId;
    switch (newStatus) {
      case "Shortlisted":
        newStatusId = 1;
        break;
      case "Rejected":
        newStatusId = 2;
        break;
      case "Under-Review":
        newStatusId = 3;
        break;
      case "Fit-For-Job":
        newStatusId = 4;
        break;
      case "Requested":
        newStatusId = 6;
        break;
      default:
        newStatusId = 3;
    }
    try {
      setStatus(newStatus);
      await dispatch(
        updateJobApplicationStatus(token, applicationId, newStatusId)
      );
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  };

  return (
    <select
      style={{
        backgroundColor: color,
        color: textColor,
        fontSize: "12px",
        fontWeight: "600",
        padding: "4px 25px",
        borderRadius: "4px",
      }}
      value={status}
      onChange={handleStatusChange}
    >
      <option
        value="Shortlisted"
        style={{ backgroundColor: "#fff", color: "#038840" }}
      >
        Shortlisted
      </option>
      <option
        value="Rejected"
        style={{ backgroundColor: "#fff", color: "#DC0000" }}
      >
        Rejected
      </option>
      <option
        value="Under-Review"
        style={{ backgroundColor: "#fff ", color: "#FF9500" }}
      >
        Under-Review
      </option>
      <option
        value="Fit-For-Job"
        style={{ backgroundColor: "#fff ", color: "#FF9500" }}
        // disabled={true}
      >
        Applied
      </option>
      <option
        value="Requested"
        style={{ backgroundColor: "#fff ", color: "#1865c1" }}
      >
        Requested
      </option>
    </select>
  );
};

export default ChangeStatusTag;
