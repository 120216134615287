import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchDemoInterviewDetails } from "../../redux/actions/jobActions";
import {
  alert_terminated,
  clock_icon,
  inbox_box,
  Qus_line,
  timer_ic,
} from "../../assets/images/images";
import { Navbar } from "../../components/DashboardComponents/Navbar/Navbar";
import { Modal } from "react-bootstrap";
import "./TryHaire.css";

const TryHaireAssesment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { interviewId } = location.state || {};
  const token = useSelector((state) => state.auth.token);
  const [interviewDetails, setInterviewDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchDetails = async () => {
      if (token && interviewId) {
        try {
          const details = await fetchDemoInterviewDetails(token, interviewId);
          setInterviewDetails(details);
        } catch (error) {
          message.error("Failed to fetch interview details");
          console.error(error);
        }
      } else {
        message.error("Missing token or interview ID");
      }
    };

    fetchDetails();
  }, [token, interviewId]);

  // Show modal after 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    navigate("/dashboard");
  };

  const formatDurationTime = (duration) => {
    if (!duration) return "N/A";
    const [hours, minutes, seconds] = duration.split(":");
    const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
    const totalSeconds = Math.floor(parseFloat(seconds));
    return `${totalMinutes}:${totalSeconds.toString().padStart(2, "0")}`;
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Navbar />

      {interviewDetails ? (
        <div className="container-fluid top-m">
          <div className="try-haire-transcript">
            <div className="transcript_header_sub-line">
              <h5>Demo Report</h5>
              <div className="timer-set">
                <div className="timer-set-inner">
                  <img src={timer_ic} alt="timer" />
                  <p>5 minutes</p>
                </div>
                <div className="timer-set-inner">
                  <img src={Qus_line} alt="timer" />
                  <p>4 Questions</p>
                </div>
              </div>
              <div className="blue_line_min"></div>
            </div>
            {/* Assessment Section */}
            <div className="Transcript_main_pd">
              <div className="transcript_header">
                <div className="transcript_header_sub">
                  <h5>Interview Assessment</h5>
                  <br />
                </div>
              </div>

              {interviewDetails?.interview?.tab_switch_count >= 3 && (
                <p className="terminated">
                  <img src={alert_terminated} alt="terminated" />
                  Interview Terminated
                </p>
              )}
            </div>
            <hr />
            {interviewDetails.assessment && (
              <div>
                <ul className="Transcript_page">
                  <li className="trans_question">
                    <span>Technical Skills:</span>
                    <span className="rating_section">
                      {" "}
                      Rating :{" "}
                      {interviewDetails.assessment.data.technical_skills.rating}
                      /10
                    </span>{" "}
                    <br />
                    <span className="blur-text">
                      Vishal's responses suggest a foundational understanding of
                      testing frameworks and tools. However, he demonstrates a
                      lack of depth when detailing specific processes and
                      methodologies. His responses indicate an adherence to
                      procedural customer tools.
                    </span>
                  </li>
                  <li className="trans_question">
                    <span>Problem Solving:</span>
                    <span className="rating_section">
                      {" "}
                      Rating :{" "}
                      {interviewDetails.assessment.data.problem_solving.rating}
                      /10
                    </span>{" "}
                    <br />
                    <span className="blur-text">
                      Vishal shows an ability to tackle common testing problems,
                      but his problem-solving strategies seem generic. The
                      absence of innovative or creative problem-solving
                      techniques in his responses could be a hindrance in
                      dynamic and fast-paced testing environments.
                    </span>
                  </li>
                  <li className="trans_question">
                    <span>Overall Impression:</span>
                    <span className="rating_section">
                      {" "}
                      Rating : {interviewDetails.assessment.data.overall_rating}
                      /10
                    </span>{" "}
                    <br />
                    <span className="blur-text">
                      Vishal seems to be at an intermediate level in his testing
                      career, with a better grasp on manual testing than
                      automation. His approach appears to be more reactive than
                      proactive, which may not align well with agile testing
                      practices and trends.
                    </span>
                  </li>
                  <li className="trans_question">
                    <span>Behavioral Skills:</span>
                    <span className="rating_section">
                      {" "}
                      Rating :{" "}
                      {
                        interviewDetails.assessment.data.behavioral_skills
                          .rating
                      }
                      /10
                    </span>{" "}
                    <br />
                    <span className="blur-text">
                      Vishal seems to be at an intermediate level in his testing
                      career, with a better grasp on manual testing than
                      automation. His approach appears to be more reactive than
                      proactive, which may not align well with agile testing
                      practices and trends.
                    </span>
                  </li>
                  <li className="trans_question">
                    <span>Communication Skills:</span>
                    <span className="rating_section">
                      {" "}
                      Rating :{" "}
                      {
                        interviewDetails.assessment.data.communication_skills
                          .rating
                      }
                      /10
                    </span>{" "}
                    <br />
                    <span className="blur-text">
                      While Vishal can convey his thoughts, his answers tend to
                      be circuitous and sometimes include irrelevant details.
                      This lack of clarity and precision in communication could
                      lead to misunderstandings in a professional setting,
                      especially in roles that require quick decision-making and
                      clear instructions.
                    </span>
                  </li>
                </ul>
              </div>
            )}

            <hr />
            {/* Transcript Section */}
            <div className="Transcript_main_pd">
              <div className="transcript_header">
                <div className="transcript_header_sub">
                  <h5>Interview Transcription</h5>
                </div>
              </div>

              {interviewDetails?.interview?.tab_switch_count >= 3 && (
                <p className="terminated blur-text">
                  {" "}
                  {/* Apply blur here */}
                  <img src={alert_terminated} alt="terminated" />
                  Interview Terminated
                </p>
              )}
            </div>
            <hr />
            {interviewDetails.transcripts?.length > 0 ? (
              <div>
                {[...interviewDetails.transcripts]
                  .reverse()
                  .map((item, index, arr) => (
                    <div key={index}>
                      <p className="trans_question">
                        <strong>Question {index + 1} : Hairo AI</strong> <br />
                        <span className="blur-text">{item?.ai_question}</span>
                      </p>

                      <p className="trans_answer">
                        <strong className="strong_answer">
                          Answer : {interviewDetails?.user_full_name}{" "}
                          {item?.answer_duration && (
                            <>
                              {" "}
                              | <img src={clock_icon} alt="Clock Icon" />{" "}
                              {formatDurationTime(item?.answer_duration)}
                            </>
                          )}
                          <br />
                        </strong>{" "}
                        <span className="blur-text">
                          {item?.candidate_answer}
                        </span>
                      </p>
                      <p className="trans_interpretation">
                        <strong>
                          AI interpretation :
                          <br />
                        </strong>{" "}
                        <span className="blur-text">
                          {item?.ai_interpretation}
                        </span>
                      </p>
                      <hr className="mr-left" />
                    </div>
                  ))}
              </div>
            ) : (
              <p>No transcripts available.</p>
            )}
          </div>
        </div>
      ) : (
        <p>Loading interview details...</p>
      )}

      {/* Modal for TryHaire */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Body className="custom-wd-md">
          <div className="guideline guideline1">
            <img src={inbox_box} alt="inbox" />
            <h1>Unlock your Demo Assessment! Upgrade Now</h1>
            <p>
              To access the detailed transcription of your AI interview,
              purchase a plan that best suits your needs.
            </p>
            <button onClick={handleClick} className="Explore_plans">
              Explore Plans
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TryHaireAssesment;
