import $ from "jquery";
import React, { useState } from "react";
import { resetJobDraftState, setJobPageFilters } from "../../../redux/reducers/jobReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom"; // Ensure Link is imported
import "../DashboardSideBar/DashboardSideBar.css";
import { isEmpty } from "../../../utils/utils";
import { mob_line, cross_icn } from "../../../assets/images/images";
import { resetCompany } from "../../../redux/reducers/companyReducer";
import { Button, Drawer, Space } from "antd";
import { toast } from "react-toastify";
import { zeroCreditError } from "../../../utils/constants";

const DashboardSideBar = ({ tabList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [drawer, setDrawer] = useState(false);
  const companyDetails = useSelector((state) => state.company.details);
  const credits = companyDetails?.company_credits ?? 0
  const mobNav = () => {
    $(".mobile_sidebar_dash_body_sec").toggleClass("left_place");
    $(".cross").toggle();
    $(".Line").toggle();
    $("body").toggleClass("no-scroll");
  };

  const handleClick = () => {
    if (!companyDetails?.id) {
      dispatch(resetCompany());
      navigate("/company-details", { state: { isEmpty: true } });
    } else if (credits === 0) {
      toast.error(zeroCreditError);
    } else {
      dispatch(resetJobDraftState());
      navigate("/dashboard/job-details");
    }
  };
  const showDrawer = () => {
    setDrawer(true);
  };
  const onClose = () => {
    setDrawer(false);
  };
  const handleTabClick = () => {
    setDrawer(false);
  };
  return (
    <>
      <div className="company_details_sec_new_inner_left desk_top_version ">
        <ul className="company_details_sec_new_inner_left_list tab-buttons sticky_left">
          <li>
            <button onClick={handleClick} className="post_blue_btn">
              <span>
                <i className="fas fa-plus"></i>
              </span>
              Post a Job
            </button>
          </li>
          {tabList.map((tab, index) => {
            // We'll use a separate component for each NavLink
            return <SidebarLink key={index} tab={tab} onClick={handleTabClick} />;
          })}
        </ul>
      </div>
      <div className="mobile_sidebar_dash_main_sec">
        <button onClick={showDrawer} className="mobile-ham">
          <img src={mob_line} alt="" className="Line" />
          <img src={cross_icn} alt="" className="cross" />
        </button>
        <Drawer onClose={onClose} open={drawer} placement="left">
          <ul className="company_details_sec_new_inner_left_list tab-buttons">
            {tabList.map((tab, index) => {
              // We'll use a separate component for each NavLink
              return <SidebarLink key={index} tab={tab} onClick={handleTabClick} />;
            })}
          </ul>
        </Drawer>
        <div className="mobile_sidebar_dash">
          <h6>
            {user ? (
              <p className="header_font">{user.full_name} </p>
            ) : (
              <p className="header_font">Please log in</p>
            )}
          </h6>
        </div>
      </div>
    </>
  );
};

const SidebarLink = ({ tab, onClick }) => {
  const dispatch = useDispatch();
  const resolved = useResolvedPath(`/dashboard/${tab.route}`);
  const match = useMatch({ path: resolved.pathname, end: true });
  const onLinkClick = () => {
    if (!match) {
      dispatch(setJobPageFilters({ current: 1 }));
      if (onClick) {
        onClick();
      }
    }
  };
  return (
    <li onClick={onLinkClick}>
      <NavLink to={`/dashboard/${tab.route}`}>
        <img
          src={match ? tab.activeIcon : tab.icon}
          alt={tab.key}
          className="tab-icon"
        />
        <p
          style={{
            color: match ? "#1865c1" : "black",
            fontWeight: match && "700",
          }}
        >
          {tab.key}
        </p>
      </NavLink>
    </li>
  );
};

export default DashboardSideBar;
