import { InfoCircleOutlined } from "@ant-design/icons";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { Button, Form, Input, Select, Tooltip } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateJobDetails } from "../../../../redux/reducers/jobReducer";
import { isEmpty, renderFeatures } from "../../../../utils/utils";
import { tick } from "../../../../assets/images/images";

const InterviewBudgetControl = ({ form }) => {
  const dispatch = useDispatch();
  const [dailyBudget, setDailyBudget] = useState();
  const [budgetType, setBudgetType] = useState();
  const [error, setError] = useState("");
  const [dateError, setDateError] = useState("");

  const companyDetails = useSelector((state) => state.company?.details);
  const initialValues = useSelector((state) => state.job?.details);

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      form.setFieldsValue({
        credits_alloted: initialValues.credits_alloted,
        budgetType: initialValues.budgetType || 2,
        custom_end_date: initialValues.custom_end_date,
      });
      setDailyBudget(initialValues.credits_alloted || 50);
    }
  }, [initialValues, form]);

  const handleBudgetChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      const numericValue = Math.max(0, Number(value));
      setDailyBudget(numericValue);
      form.setFieldsValue({ credits_alloted: numericValue });
      if (numericValue > (companyDetails?.company_credits ?? 0)) {
        setError(
          `Insufficient credits: Your budget cannot exceed ${
            companyDetails?.company_credits ?? 0
          } Credits for this job`
        );
      } else {
        setError("");
      }
    } else {
      setError("Please enter only positive numbers.");
    }
  };

  const handleBudgetTypeChange = (value) => {
    setBudgetType(value);
    form.setFieldsValue({ budgetType: value });
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : "";
    if (!dayjs(date).isValid()) {
      setDateError("Invalid date format. Please select a valid date.");
      form.setFieldsValue({ custom_end_date: "" });
    } else if (date && dayjs(date).isBefore(dayjs().startOf("day"))) {
      setDateError("Please select a current or future date.");
      form.setFieldsValue({ custom_end_date: "" });
    } else {
      setDateError("");
      form.setFieldsValue({ custom_end_date: formattedDate });
      dispatch(
        updateJobDetails({
          ...form.getFieldsValue(),
          custom_end_date: formattedDate,
        })
      );
    }
  };

  const handleSubmit = (values) => {
    if (error !== "" || dateError !== "" || dailyBudget < 0) {
      return; // Don't dispatch if there are errors
    } else {
      dispatch(updateJobDetails(values));
    }
  };

  const selectAfter = (
    <Form.Item name="budget">
      <Select
        defaultValue={2}
        value={budgetType}
        onChange={handleBudgetTypeChange}
        options={[
          {
            value: 2,
            label: "Total",
          },
          {
            value: 1,
            label: "Daily",
          },
        ]}
      />
    </Form.Item>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <section className="form_sec_new_inner_first normalize_padding">
        <div className="Monitization_section_credit_section">
          <div className="Monitization_section_credit_section-left">
            <Form
              form={form}
              onFinish={handleSubmit}
              onValuesChange={(changedValues, allValues) => {
                dispatch(updateJobDetails(allValues));
              }}
            >
              <div className="credit_sec_main">
                <h2 className="head_sec_main">Allot Credits *</h2>
                <p className="head_sec_main_p">
                  We recommend 50 credits based on your job description.{" "}
                  <Tooltip title="We recommend 50 credits based on your job description">
                    <Button type="text" icon={<InfoCircleOutlined />} />
                  </Tooltip>
                </p>
                <Form.Item
                  name="credits_alloted"
                  validateStatus={error ? "error" : ""}
                  help={error}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    min={0}
                    required
                    addonAfter={selectAfter}
                    value={dailyBudget}
                    onChange={handleBudgetChange}
                    placeholder="Enter your daily budget"
                    maxLength={10}
                    className="Add_credit"
                    style={{ fontSize: "16px", fontWeight: "900" }}
                  />
                </Form.Item>
                {initialValues.budget === 1 ? (
                  <>
                    <h2 className="head_sec_main">Custom End Date *</h2>
                    <p className="head_sec_main_p">
                      Until when would you like to allocate credits for AI
                      interviews?{" "}
                    </p>
                    <Form.Item
                      name="custom_end_date"
                      className="Date_cust"
                      validateStatus={dateError ? "error" : ""}
                      help={dateError}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <DemoContainer components={["DatePicker"]}>
                        <DemoItem>
                          <DatePicker
                            value={
                              form.getFieldValue("custom_end_date")
                                ? dayjs(form.getFieldValue("custom_end_date"))
                                : null
                            }
                            disablePast
                            onChange={handleDateChange}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </Form.Item>
                    <div className="list-features">
                      <h6>Features:</h6>
                      <ul className="list-features_ul">
                        {renderFeatures(companyDetails?.company_sub?.type).map(
                          (feature, index) => (
                            <li key={index}>
                              <img src={tick} alt="" className="tick" />{" "}
                              {feature}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="head_sec_main_p green_p">
                      With 50 credits, you can schedule interviews until they're
                      exhausted, totaling approximately 17 - 19 interviews at 30
                      minutes each.
                    </p>
                    <p className="head_sec_main_p green_p">
                      With 50 credits, you can schedule around 17 - 19 daily
                      interviews at 30 minutes each.
                    </p>
                  </>
                )}
              </div>
            </Form>
          </div>
          {/* <div className="Monitization_section_credit_section-right">
            <div>
              <h4 className="head_sec_main">Add Ons:</h4>
              <div className="bg-blue1">
                <h5 className="head_sec_main_p first">
                  Individual Interview Transcripts
                </h5>
                <p className="head_sec_main_p wdt_p">
                  Get audio and video transcripts for each interview at 2 credit
                  per session.
                </p>
                <div className="add_credit_btn">
                  <p className="head_sec_main">
                    2 Credits <span>per interview</span>
                  </p>
                  <button className="Add_button_credit">Add</button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </LocalizationProvider>
  );
};

export default InterviewBudgetControl;
