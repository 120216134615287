import React, { useEffect, useState } from "react";
import { usePricingModal } from "../../../../contexts/PricingModalContext";
import "./MonitizationPage.css";
import AddCardModal from "./components/AddCardModal";
import BillingModal from "./components/BillingModal";
import SubscriptionTabbar from "./components/SubscriptionTabbar";
import BillingInformation from "./tabs/BillingInformation";
import CreditHistory from "./tabs/CreditHistory";
import PaymentHistory from "./tabs/PaymentHistory";
import SubscriptionOverview from "./tabs/SubscriptionOverview";
import { useDispatch, useSelector } from "react-redux";
import { fetchBillingInfo } from "../../../../redux/actions/billingActions";

const Monitization = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.token);
  const { showPricingModal } = usePricingModal();
  const [activeTab, setActiveTab] = useState("overView");
  const [showreport, setshowreport] = useState(false);
  const [showBilling, setbilling] = useState(false);
  const [criteria, setSortCriteria] = useState(null);

  useEffect(() => {
    if (activeTab === "billingInfo") {
      dispatch(fetchBillingInfo(token)); // Dispatch the action when the billing info tab is active
    }
  }, [activeTab, dispatch]);

  const hideModal = () => {
    setshowreport(false);
    setbilling(false);
  };

  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
  };

  const editBilling = () => {
    setbilling(true);
  };

  const addPayment = () => {
    setshowreport(true);
  };
  const opensubs = () => {
    showPricingModal();
  };

  const tabComponents = {
    BillingInfo: (
      <BillingInformation addPayment={addPayment} editBilling={editBilling} />
    ),
    PaymentHistory: <PaymentHistory addPayment={addPayment} />,
    overView: <SubscriptionOverview onCancelPlan={opensubs} />,
    creditHistory: <CreditHistory />,
  };

  return (
    <>
      <div className="comp_det_rigt_main tab-content subscription_plan_mt">
        <div className="company_details_sec_new_inner_right">
          <SubscriptionTabbar
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />

          {tabComponents[activeTab]}
        </div>
      </div>
      {/* ADD card Modal Start ---------------------------------------- */}
      <AddCardModal show={showreport} onHide={hideModal} />
      {/* Billing Modal start---------------------------------------- */}
      <BillingModal show={showBilling} onHide={hideModal} />
    </>
  );
};

export default Monitization;
