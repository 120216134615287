import { useNavigate } from "react-router-dom";
import { border_corner } from "../../../assets/images/images";
import "../../Home/home.css";
import "../../Home/home_responsive.css";

const NeedHelp = () => {
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    navigate("/sign-up");
  };
  return (
    <section className="future_hiring_easy Need-help-main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="future_hiring_easy_main">
              <div className="need-help text-start d-flex Need-help-main_das">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h3>Need Help Understanding Our Pricing? </h3>
                  <p>Book a demo now with our specialist and see how our plans can fit your hiring needs!</p>
                </div>
                <div className="future_hiring_easy_right text-center col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <button className="explore_btn" onClick={handleClick}>
                    Let's Explore
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NeedHelp;
