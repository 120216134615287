import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import instance from "../../api/instance";
import { connect_img, Location_pin } from "../../assets/images/images";
import "../../pages/Features/Features.css";
import LoaderApply from "../CommonComponents/Loader/LoaderApply";
import "../../pages/Home/New_Home.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const GetInTouch = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone: "",
    message: "",
    company_name: "",
    designation: "",
    company_size: "",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    // Disable the send button if there are any errors
    const hasErrors = Object.keys(errors).length > 0;
    setIsFormValid(!hasErrors);
  }, [errors]);

  const validateField = (name, value) => {
    const newErrors = { ...errors };

    const isNotEmpty = (value) => value.trim().length > 0;
    const isValidFullName = (name) => /^[a-zA-Z\s]+$/.test(name);
    const isValidPhone = (phone) => /^[1-9]\d{9}$/.test(phone);
    const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    switch (name) {
      case "full_name":
        if (!isNotEmpty(value)) {
          newErrors.full_name = "Full name is required";
        } else if (!isValidFullName(value)) {
          newErrors.full_name =
            "Full name should not contain numbers or special characters";
        } else {
          delete newErrors.full_name;
        }
        break;
      case "phone":
        if (!isNotEmpty(value)) {
          newErrors.phone = "Contact number is required";
        } else if (!isValidPhone(value)) {
          newErrors.phone =
            "Contact number must be exactly 10 digits and should not start with 0";
        } else {
          delete newErrors.phone;
        }
        break;
      case "email":
        if (!isNotEmpty(value)) {
          newErrors.email = "Email is required";
        } else if (!isValidEmail(value)) {
          newErrors.email = "Invalid email format";
        } else {
          delete newErrors.email;
        }
        break;
      case "company_name":
        if (!isNotEmpty(value)) {
          newErrors.company_name = "Company name is required";
        } else {
          delete newErrors.company_name;
        }
        break;
      case "designation":
        if (!isNotEmpty(value)) {
          newErrors.designation = "Designation is required";
        } else {
          delete newErrors.designation;
        }
        break;
      case "company_size":
        if (!value) {
          newErrors.company_size = "Company size is required";
        } else {
          delete newErrors.company_size;
        }
        break;
      case "message":
        if (!isNotEmpty(value)) {
          newErrors.message = "Message is required";
        } else {
          delete newErrors.message;
        }
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value); // Validate the field on change
  };

  const validateForm = (data) => {
    const errors = {};

    for (const field in data) {
      validateField(field, data[field]);
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      try {
        setIsLoading(true);
        const response = await instance.post(
          "accounts/send-inquiry/",
          formData
        );

        if (response.status === 200 || response.status === 201) {
          toast.success("Submitted successfully", {
            position: toast.POSITION.TOP_END,
          });
          setFormData({
            full_name: "",
            email: "",
            phone: "",
            message: "",
            company_name: "",
            designation: "",
            company_size: "",
          });
          setErrors({});
        } else {
          console.error("Validation Errors:", response.data);
        }
      } catch (error) {
        if (error.response) {
          console.error("Error:", error.response.data);
        } else if (error.request) {
          console.error("Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      } finally {
        setIsLoading(false); // Set loading back to false after submission
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <>
      <section className="get_in_touch new_get_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="get_in_touch_inner">
                <div
                  className="get_in_touch_sec_left_new wow animate__slideInLeft"
                  data-wow-duration="2s"
                >
                  <h6>We are here to help you!</h6>
                  <p>
                    Please leave your details and our team will contact you
                    shortly.
                  </p>
                  <a href="mailto:support@haire.ai" className="email-link">
                    <span>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    support@haire.ai
                  </a>
                </div>

                <div
                  className="get_in_touch_inner_left wow animate__slideInRight"
                  data-wow-duration="4s"
                >
                  <div className="get_in_touch_inner_left_form_sec ">
                    <form onSubmit={handleSubmit} className="get_touch">
                      <div className="get_in_touch_inner_left_form_sec_grid">
                        <div className="get_in_touch_inner_left_form_sec_single">
                          <label>
                            Full Name{" "}
                            <span className="required_asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Full Name"
                            name="full_name"
                            value={formData.full_name}
                            onChange={handleChange}
                          />
                          {errors.full_name && (
                            <span className="error">{errors.full_name}</span>
                          )}
                        </div>
                        <div className="get_in_touch_inner_left_form_sec_single">
                          <label>
                            Contact No
                            <span className="required_asterisk">*</span>
                          </label>
                          <input
                            type="tel"
                            placeholder="Enter Contact No"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            pattern="\d*"
                            title="Please enter a valid phone number."
                          />
                          {errors.phone && (
                            <span className="error">{errors.phone}</span>
                          )}
                        </div>
                      </div>
                      <div className="get_in_touch_inner_left_form_sec_grid">
                        <div className="get_in_touch_inner_left_form_sec_single">
                          <label>
                            Email Address
                            <span className="required_asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Email Address"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                          {errors.email && (
                            <span className="error">{errors.email}</span>
                          )}
                        </div>
                        <div className="get_in_touch_inner_left_form_sec_single">
                          <label>
                            Company Name
                            <span className="required_asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Company Name"
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                          />
                          {errors.company_name && (
                            <span className="error">{errors.company_name}</span>
                          )}
                        </div>
                      </div>

                      <div className="get_in_touch_inner_left_form_sec_grid">
                        <div className="get_in_touch_inner_left_form_sec_single">
                          <label>
                            Designation
                            <span className="required_asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Designation"
                            name="designation"
                            value={formData.designation}
                            onChange={handleChange}
                          />
                          {errors.designation && (
                            <span className="error">{errors.designation}</span>
                          )}
                        </div>
                        <div className="get_in_touch_inner_left_form_sec_single">
                          <label>
                            Company Size
                            <span className="required_asterisk">*</span>
                          </label>
                          <select
                            name="company_size"
                            value={formData.company_size}
                            onChange={handleChange}
                          >
                            <option value="">Select company size</option>
                            <option value="1">10-1000 employees</option>
                            <option value="2">2000 - 5000 employees</option>
                            <option value="3">5000 - 10000 employees</option>
                            <option value="4">10000+ employees</option>
                          </select>
                          {errors.company_size && (
                            <span className="error">{errors.company_size}</span>
                          )}
                        </div>
                      </div>

                      <div className="get_in_touch_inner_left_form_sec_single custom-textarea-landing">
                        <label>
                          Tell Us How We Can Help
                          <span className="required_asterisk">*</span>
                        </label>
                        <textarea
                          rows={4}
                          placeholder="Tell Us How We Can Help"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                        {errors.message && (
                          <span className="error">{errors.message}</span>
                        )}
                      </div>

                      <div
                        className="text-center"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          type="submit"
                          className="try-button"
                          style={{
                            position: "relative",
                            display: "inline-block",
                            overflow: "hidden",
                          }}
                          disabled={!isFormValid}
                        >
                          {isLoading && (
                            <LoaderApply
                              style={{
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            />
                          )}
                          {!isLoading && "Send"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div
                  className="get_in_touch_inner_right"
                  style={{ display: "none" }}
                >
                  <div className="get_in_touch_inner_right_img">
                    <img src={connect_img} alt="" />
                  </div>
                  <div className="get_in_touch_inner_right_single">
                    <span>
                      <i className="fas fa-envelope"></i>
                    </span>
                    <a href="mailto:support@haire.ai">support@haire.ai</a>
                  </div>
                  <div className="get_in_touch_inner_right_single">
                    <span>
                      <i className="fas fa-location-dot"></i>
                    </span>
                    <a
                      href="https://maps.app.goo.gl/GZTKxuprex47w5Fr5"
                      className="address-one"
                    >
                      Trade World, Kamala Mills, C-208, near Lodha Park, Lower
                      Parel, Mumbai, Maharashtra 400013
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GetInTouch;
