import "firebase/auth";
import Cookies from "js-cookie";
import { Dispatch } from "redux";
import {
  GoogleAuthProvider,
  OAuthProvider,
  auth,
  signInWithPopup,
  signInWithEmailAndPassword,
} from "../../config/firebaseConfig";
import {
  setAuthToken,
  setError,
  setJobseekerId,
  setLoading,
  setNewUser,
  setRole,
  setUser,
  setUserId,
} from "../reducers/authReducer";
import { AuthUser } from "../types/authTypes";
// @ts-ignore
import instance from "../../api/instance";
import { RESET_APP } from "./resetActions";

export const signInWithGoogle =
  (navigate: Function, redirectUrl?: string, role?: number) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true));

    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });
      const result = await signInWithPopup(auth, provider);
      const userKey = result.user.uid;

      try {
        const existsResponse = await instance.get(
          `/accounts/check-user-exists/${userKey}/`
        );

        if (existsResponse.data.data) {
          const requestBody = {
            user_id: userKey,
            // full_name: confirmedUser.user.displayName,
            email: result.user.email,
            // type: 1,
            // phone: confirmedUser.user.phoneNumber,
            key: userKey,
            // role: existsResponse.data.role,
          };
          const response = await instance.post(
            "/accounts/social-login/",
            requestBody
          );

          Cookies.set("token", response.data.data.token);
          dispatch(setAuthToken(response.data.data.token));
          dispatch(setJobseekerId(response.data.data.jobseeker_id));
          dispatch(setNewUser(response.data.data.is_new_user));
          dispatch(setUserId(response.data.data.user.id));

          const user: AuthUser = {
            user_id: response.data.data.user.id,
            job_seeker_id: response.data.data.jobseeker_id,
            full_name: response.data.data.user.full_name,
            email: result.user.email,
            type: 1,
            country_code: null,
            phone: result.user.phoneNumber,
            key: result.user.uid,
            role: response.data.data.user.role,
            registered_company_name:
              response?.data?.data?.user.registered_company_name,
            company_id: response.data.data.company_id,
            languages: null,
            websites: null,
            profile_pic: null,
            designation: null,
            dob: null,
            gender: null,
            address: null,
            introduction: null,
            resume: response.data.data.user.resume,
            resume_json: null,
            is_active: null,
            is_deleted: null,
            profile_completed: response.data.data.profile_completed,
          };

          dispatch(setUser(user));
          dispatch(setJobseekerId(response.data.data.jobseeker_id));
          dispatch(setNewUser(response.data.data.is_new_user));

          if (response.data.data.user.role === 1) {
            navigate("/dashboard");
          } else if (response.data.data.user.role === 2) {
            if (redirectUrl) {
              navigate(redirectUrl);
            } else {
              navigate("/applicant-dashboard");
            }
          } else {
            navigate("/user-role");
          }
          dispatch(setLoading(false));
        } else {
          const requestBody = {
            user_id: userKey,
            full_name: result.user.displayName,
            email: result.user.email,
            type: 1,
            key: userKey,
            role: role,
          };
          const response = await instance.post(
            "/accounts/social-login/",
            requestBody
          );

          navigate("/user-role");
          Cookies.set("token", response.data.data.token);
          dispatch(setAuthToken(response.data.data.token));
          dispatch(setJobseekerId(response.data.data.jobseeker_id));
          dispatch(setNewUser(response.data.data.is_new_user));
          dispatch(setUserId(response.data.data.user.id));

          const user: AuthUser = {
            user_id: response.data.data.user.id,
            job_seeker_id: response.data.data.jobseeker_id,
            full_name: response.data.data.user.full_name,
            email: result.user.email,
            type: 1,
            country_code: null,
            phone: result.user.phoneNumber,
            key: result.user.uid,
            role: response.data.data.user.role,
            registered_company_name:
              response.data.data.user.registered_company_name,
            company_id: response.data.data.company_id,
            languages: null,
            websites: null,
            profile_pic: null,
            designation: null,
            dob: null,
            gender: null,
            address: null,
            introduction: null,
            resume: response.data.data.user.resume,
            resume_json: null,
            is_active: null,
            is_deleted: null,
            profile_completed: response.data.data.profile_completed,
          };

          dispatch(setUser(user));
          dispatch(setJobseekerId(response.data.data.jobseeker_id));
        }
        dispatch(setLoading(false));
      } catch (error) {
        console.error("Error checking user existence:", error);
        navigate("/user-role");
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      console.error("Google sign-in error:", error);
      dispatch(
        setError(error.message || "An error occurred during Google sign-in")
      );
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const signInWithEmailPassword =
  (email: string, password: string, navigate: Function, redirectUrl?: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true));

    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const userKey = result.user.uid;

      // Fetch additional user data if needed
      const response = await instance.get(`/accounts/user-data/${userKey}/`);
      Cookies.set("token", response.data.token);
      dispatch(setAuthToken(response.data.token));
      dispatch(setUserId(userKey));

      const user: AuthUser = {
        user_id: response.data.data.user.id,
        job_seeker_id: response.data.data.jobseeker_id,
        full_name: response.data.data.user.full_name,
        email: result.user.email,
        type: 1,
        country_code: null,
        phone: result.user.phoneNumber,
        key: result.user.uid,
        role: response.data.data.user.role,
        registered_company_name:
          response.data.data.user.registered_company_name,
        company_id: response.data.data.company_id,
        languages: null,
        websites: null,
        profile_pic: null,
        designation: null,
        dob: null,
        gender: null,
        address: null,
        introduction: null,
        resume: response.data.data.user.resume,
        resume_json: null,
        is_active: null,
        is_deleted: null,
        profile_completed: response.data.data.profile_completed,
        // additional fields
      };

      dispatch(setUser(user));

      // Redirect based on role
      if (user.role === 1) {
        navigate("/dashboard");
      } else if (user.role === 2) {
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate("/applicant-dashboard");
        }
      } else {
        navigate("/user-role");
      }
    } catch (error: any) {
      console.error("Sign-in error:", error);
      let errorMessage = "Failed to sign in.";
      if (
        error.code === "auth/user-not-found" ||
        error.code === "auth/wrong-password"
      ) {
        errorMessage = "Incorrect email or password.";
      }
      dispatch(setError(errorMessage));
      navigate("/login");
    } finally {
      dispatch(setLoading(false));
    }
  };

export const signInWithApple =
  (navigate: Function) => async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");

    try {
      provider.setCustomParameters({ prompt: "select_account" });
      const result = await signInWithPopup(auth, provider);
      const requestBody = {
        user_id: result.user.uid,
        full_name: result.user.displayName,
        email: result.user.email,
        type: 2,
        country_code: null,
        phone: result.user.phoneNumber,
        key: result.user.uid,
        role: 1,
        registered_company_name: null,
        company_id: null,
        languages: null,
        websites: null,
        profile_pic: null,
        designation: null,
        dob: null,
        gender: null,
        address: null,
        introduction: null,
        resume: null,
        resume_json: null,
        is_active: null,
        is_deleted: null,
      };
      const response = await instance.post(
        "/accounts/social-login/",
        requestBody
      );
      Cookies.set("token", response.data.data.token);
      dispatch(setAuthToken(response.data.data.token));
      dispatch(setRole(response.data.data.user.role));
      dispatch(setUserId(response.data.data.user.id));
      dispatch(setJobseekerId(response.data.data.jobseeker_id));
      const user: AuthUser = {
        user_id: response.data.data.user.id,
        job_seeker_id: null,
        full_name: result.user.displayName,
        email: result.user.email,
        type: 2,
        country_code: null,
        phone: result.user.phoneNumber,
        key: result.user.uid,
        role: 1,
        registered_company_name:
          response.data.data.user.registered_company_name,
        company_id: response.data.data.company_id,
        languages: null,
        websites: null,
        profile_pic: null,
        designation: null,
        dob: null,
        gender: null,
        address: null,
        introduction: null,
        resume: response.data.data.user.resume,
        resume_json: null,
        is_active: null,
        is_deleted: null,
        profile_completed: response.data.data.profile_completed,
      };
      dispatch(setUser(user));
      navigate("/user-role");
      dispatch(setLoading(false));
      scheduleTokenRenewal(response.data.data.user.id, dispatch);
    } catch (error: any) {
      dispatch(setError(error.message || "An error occurred"));
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };

// export const signInWithPhoneNumber =
//   (navigate: Function) => async (dispatch: Dispatch) => {
//     dispatch(setLoading(true));
//     try {
//     } catch (error: any) {
//       console.error("Google sign-in error:", error);
//       dispatch(
//         setError(error.message || "An error occurred during Google sign-in")
//       );
//     } finally {
//       dispatch(setLoading(false));
//     }
//   };

export const signOut =
  () =>
  (dispatch: Dispatch): void => {
    dispatch({ type: RESET_APP }); // This will reset the entire state
    Cookies.remove("token");
    Cookies.remove("activeTab");
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/login");
  };

const renewToken = async (userId: string, dispatch: Dispatch) => {
  try {
    const response = await instance.post(
      `/accounts/token/renew/?user_id=${userId}`
    );
    const newToken = response.data.data.token;
    Cookies.set("token", newToken);
    dispatch(setAuthToken(newToken));
  } catch (error: any) {
    dispatch(setError(error.message || "Failed to renew token"));
  }
};

const scheduleTokenRenewal = (userId: string, dispatch: Dispatch) => {
  setInterval(() => renewToken(userId, dispatch), 18000000);
};
// function getState() {
//   throw new Error("Function not implemented.");
// }
