import React from 'react';
import {client_img,backSlash} from '../../../assets/images/images'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay:true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

const ClientTestimonials = () => {
  return (
    <section className='client_testi_sec'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-12 col-md-12'>
                    <div className='client_testi_sec_inner'>
                        <div className='client_testi_sec_head wow animate__zoomIn' data-wow-duration="4s">
                            <h6>How <span>Haire</span> Achieves Efficiency for Clients</h6>
                        </div>
                        <OwlCarousel className='owl-theme client_testi_sec_inner_body' {...options}>

                            <div className='client_testi_sec_single'>
                                <img src={backSlash} className='back_slash_sec' />
                                <p>Haire.ai took my resume to the next level. The AI suggestions were spot-on. Applied to jobs confidently and landed my ideal role.</p>
                                <div className='client_testi_img'>
                                    <div className='client_testi_img_img'>
                                        <img src={client_img} />
                                    </div>
                                    <div className='client_testi_img_text'>
                                        <h6>Leslie Alexander</h6>
                                        <p>HR Manager</p>
                                    </div>
                                </div>
                            </div>
                            <div className='client_testi_sec_single'>
                                <img src={backSlash} className='back_slash_sec' />
                                <p>Haire.ai took my resume to the next level. The AI suggestions were spot-on. Applied to jobs confidently and landed my ideal role.</p>
                                <div className='client_testi_img'>
                                    <div className='client_testi_img_img'>
                                        <img src={client_img} />
                                    </div>
                                    <div className='client_testi_img_text'>
                                        <h6>Ralph Edwards</h6>
                                        <p>HR Manager</p>
                                    </div>
                                </div>
                            </div>
                            <div className='client_testi_sec_single'>
                                <img src={backSlash} className='back_slash_sec' />
                                <p>Haire.ai took my resume to the next level. The AI suggestions were spot-on. Applied to jobs confidently and landed my ideal role.</p>
                                <div className='client_testi_img'>
                                    <div className='client_testi_img_img'>
                                        <img src={client_img} />
                                    </div>
                                    <div className='client_testi_img_text'>
                                        <h6>Savannah Nguyen</h6>
                                        <p>HR Manager</p>
                                    </div>
                                </div>
                            </div>

                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default ClientTestimonials
