import { Skeleton, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { eye_icon } from "../../../../assets/images/images";
import NoJobApplicantFound from "../../../../components/CommonComponents/NoJobApplicantFound";
import { fetchJobsList } from "../../../../redux/actions/jobActions";
import { formatDate } from "../../../../utils/utils";
import { EmploymentFilter, Reset, Searchbar, SortFilter } from "./MyJobs";
import "./MyJobs.css";
import { setJobPageFilters } from "../../../../redux/reducers/jobReducer";
import { ThreeCircles } from "react-loader-spinner";
import SkeletonLoader from "../CommonRecruiterComponant/SkeletonLoader";

const ClosedJobs = ({ activeTab, status }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialRender = useRef(true);
  const initialRender2 = useRef(true);
  const [sortCriteria, setSortCriteria] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(status);
  const [selectedType, setSelectedType] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchInput);
  const closedJobList = useSelector((state) => state.job.closedJobsList);
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.job.loading);
  const closedJobsCount = useSelector((state) => state.job.closedJobsCount);
  const jobPageFilters = useSelector((state) => state.job.jobPageFilters);

  useEffect(() => {
    //on load check currentPage
    if (initialRender.current) {
      setCurrentPage(jobPageFilters.current || 1);
      setSearchInput(jobPageFilters.searchInput);
      setSelectedType(jobPageFilters.type);
      setSortCriteria(jobPageFilters.sortBy);
      dispatch(
        fetchJobsList(
          token,
          jobPageFilters.searchInput,
          jobPageFilters.type === "All" ? undefined : jobPageFilters.type,
          jobPageFilters.sortBy,
          selectedStatus,
          jobPageFilters.current
        )
      );
    }
  }, [jobPageFilters]);

  // useEffect(() => {
  //   setCurrentPage(1);
  //   dispatch(
  //     fetchJobsList(
  //       token,
  //       debouncedSearchTerm,
  //       selectedType === "All" ? undefined : selectedType,
  //       sortCriteria,
  //       selectedStatus,
  //       1
  //     )
  //   );
  // }, [debouncedSearchTerm]);

  useEffect(() => {
    if (initialRender.current) {
      // Skip the initial render
      initialRender.current = false;
    } else {
      // This will handle changes to searchInput only after the initial render
      setCurrentPage(1);
      dispatch(
        fetchJobsList(
          token,
          searchInput,
          selectedType === "All" ? undefined : selectedType,
          sortCriteria,
          selectedStatus,
          1
        )
      );
      dispatch(
        setJobPageFilters({
          current: 1,
          type: selectedType,
          sortBy: sortCriteria,
          searchInput,
        })
      );
    }
  }, [searchInput, activeTab, status]);

  const handleViewJob = (jobId) => {
    navigate(`/dashboard/my-jobs/${activeTab}/${jobId}/view-jobs-data`);
  };

  const handleSearchSubmit = (searchValue) => {
    setSearchInput(searchValue);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(
      setJobPageFilters({
        current: page,
        type: selectedType,
        sortBy: sortCriteria,
        searchInput,
      })
    );
    dispatch(
      fetchJobsList(
        token,
        searchInput,
        selectedType === "All" ? undefined : selectedType,
        sortCriteria,
        selectedStatus,
        page
      )
    );
  };

  const handleSortChange = (criteria) => {
    setCurrentPage(1);
    setSortCriteria(criteria);
    dispatch(
      setJobPageFilters({
        current: 1,
        type: selectedType,
        sortBy: criteria,
        searchInput,
      })
    );
  };

  const handleTypeChange = (type) => {
    setCurrentPage(1);
    setSelectedType(type);
    dispatch(
      setJobPageFilters({ current: 1, type, sortBy: sortCriteria, searchInput })
    );
  };

  useEffect(() => {
    if (initialRender2.current) {
      // Skip the initial render
      initialRender2.current = false;
    } else {
      dispatch(
        fetchJobsList(
          token,
          searchInput,
          selectedType === "All" ? undefined : selectedType,
          sortCriteria,
          selectedStatus,
          currentPage
        )
      );
    }
  }, [
    dispatch,
    token,
    selectedType,
    sortCriteria,
    selectedStatus,
    currentPage,
  ]);

  const handleReset = () => {
    setSearchInput("");
    setSortCriteria("");
    setSelectedType("All");
    setCurrentPage(1);
    dispatch(fetchJobsList(token, "", null, null, selectedStatus, 1));
  };
  const columns = [
    {
      title: "Job Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <div data-th="Job Title">{text}</div>,
    },
    {
      title: "Created date",
      dataIndex: "created_at",
      key: "created_at",
      render: (dateString) => (
        <div data-th="Created date">{formatDate(dateString)}</div>
      ),
    },
    {
      title: "Closed date",
      dataIndex: "closed_date",
      key: "closed_date",
      render: (dateString) => (
        <div data-th="Closed date">{formatDate(dateString)}</div>
      ),
    },
    {
      title: "Job Type",
      dataIndex: "employment_type",
      key: "employment_type",
      render: (employmentType) => {
        const types = Object.values(employmentType).join(", ");
        return <div data-th="Job Type">{types || "Not Specified"}</div>;
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text) => <div data-th="Location">{text}</div>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => <div data-th="Status">{renderStatusTag(status)}</div>,
    },
    {
      title: "View Details",
      dataIndex: "view",
      key: "view",
      render: (text, record) => (
        <a onClick={() => handleViewJob(record.id)}>
          <img src={eye_icon} alt="eye_icon" className="eye_icon" />
          View
        </a>
      ),
    },
  ];

  if (!closedJobList) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <div className="row">
        <div className="filter-container">
          <div className="filter-section ">
            <Searchbar
              inputValue={searchInput}
              setInputValue={setSearchInput}
              onSearchSubmit={handleSearchSubmit}
            />
            <EmploymentFilter
              onTypeChange={handleTypeChange}
              selectedType={selectedType}
            />
            <Reset onReset={handleReset} />
          </div>
          <div className="sort-section">
            <SortFilter
              onSortChange={handleSortChange}
              selectedValue={sortCriteria}
              activeTab={activeTab}
            />
          </div>
        </div>
        {/* Responsive layout */}
        <div className="filter-container-responsive">
          <div className="search-bar-section">
            <Searchbar
              inputValue={searchInput}
              setInputValue={setSearchInput}
              onSearchSubmit={handleSearchSubmit}
            />
          </div>
          <div className="responsive_filter">
            <div className="row  d-flex g-3 mb-1 align-items-baseline">
              <div className="col-12 ">
  <div className="row">
    <div className="col-6 d-flex flex-column align-items-baseline">
      <span className="sort-by-label sort_p1">Employement Type:</span>
      <EmploymentFilter
              onTypeChange={handleTypeChange}
              selectedType={selectedType}
            />
    </div>
    <div className="col-6 d-flex flex-column align-items-baseline">
      <span className="sort-by-label sort_p1">Sort By:</span>
      <SortFilter
              onSortChange={handleSortChange}
              selectedValue={sortCriteria}
              activeTab={activeTab}
            />
    </div>
  </div>
</div>

            </div>
          </div>
          {/* <div className="filter-options-section-responsive">
            <EmploymentFilter
              onTypeChange={handleTypeChange}
              selectedType={selectedType}
            />
            <SortFilter
              onSortChange={handleSortChange}
              selectedValue={sortCriteria}
              activeTab={activeTab}
            />
          </div> */}
          <div>
            <Reset onReset={handleReset} />
          </div>
        </div>
        {/* Responsive layout */}
        {loading ? (
        <SkeletonLoader includeTable={true} excludeDetails={true} />
      ) : closedJobList && closedJobList.length > 0 ? (
        <Table
          columns={columns}
          dataSource={closedJobList}
          pagination={{
            pageSize: 12,
            total: closedJobsCount,
            current: currentPage,
            onChange: handlePageChange,
            showSizeChanger: false,
          }}
        />
      ) : (
        <NoJobApplicantFound />
      )}
      </div>
    </>
  );
};

export default ClosedJobs;

const renderStatusTag = (status) => {
  let color, textColor;
  switch (status) {
    case "Active":
      color = "rgba(3, 136, 64, 0.20)";
      textColor = "#038840";
      break;
    case "Closed":
      color = "rgba(218, 0, 0, 0.20)";
      textColor = "#DC0000";
      break;
    default:
      color = "grey";
  }

  return (
    <span
      style={{
        backgroundColor: color,
        color: textColor,
        fontSize: "12px",
        fontWeight: "600",
        padding: "4px 25px",
        borderRadius: "4px",
      }}
    >
      {status.toUpperCase()}
    </span>
  );
};
