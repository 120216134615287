import { Button, Modal, Steps, Form, Input, Spin } from "antd";
import { useState } from "react";
import "./TryHaire.css";
import TryHaireOtp from "./TryHaireOtp";
import userStore from "../../zustand/userStore";
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidPhoneNumber } from "react-phone-number-input";

const { Step } = Steps;

const stepsContent = [
  {
    title: "Step 1",
    header: (
      <>
        <h6 className="mdl-h6">Let’s personalize this demo for you.</h6>
        <p className="verification-modal-subtitle mdl-p">
          We have few questions about your company and hiring needs.
        </p>
      </>
    ),
    formFields: [
      {
        name: "companyName",
        label: "Company Name",
        placeholder: "Enter company name",
        rules: [
          { required: true, message: "Please enter your company name" },
          {
            max: 60,
            message: "Company name cannot exceed 60 characters",
          },
          {
            validator: (_, value) => {
              if (!value || value.trim() === "") {
                return Promise.reject(
                  new Error("Company name cannot be only spaces")
                );
              }
              return Promise.resolve();
            },
          },
        ],
      },
      {
        name: "industryType",
        label: "Industry Type",
        placeholder: "Select industry type",
        type: "select",
        rules: [{ required: true, message: "Please select an industry type" }],
      },
      {
        name: "jobProfile",
        label: "Job Profile",
        placeholder: "Enter job profile",
        rules: [
          { required: true, message: "Please enter your job profile" },
          {
            max: 40,
            message: "Job profile cannot exceed 60 characters",
          },
        ],
      },
    ],
  },
  {
    title: "Step 2",
    header: (
      <>
        <h6 className="mdl-h6">Verify information to start demo.</h6>
        <p className="verification-modal-subtitle mdl-p">
          We need to collect a few basic personal details from you.
        </p>
      </>
    ),

    formFields: [
      {
        name: "fullName",
        label: "Full Name",
        placeholder: "Enter full name",
        rules: [
          { required: true, message: "Please enter your full name" },
          {
            pattern: /^[a-zA-Z\s'-]+$/,
            message:
              "Full name can only contain letters, spaces, hyphens, and apostrophes",
          },
          {
            validator: (_, value) => {
              if (!value || value.trim() === "") {
                return Promise.reject(
                  new Error("Full name cannot be only spaces")
                );
              }
              return Promise.resolve();
            },
          },
        ],
      },
      {
        name: "email",
        label: "Business Email",
        placeholder: "Enter business email",
        rules: [
          { required: true, message: "Please enter your email" },
          {
            type: "email",
            message: "Please enter a valid email address",
          },
        ],
      },
      {
        name: "phoneNumber",
        label: "Contact Number",
        placeholder: "Enter contact number",
        type: "phone",
        rules: [
          { required: true, message: "Please enter your contact number" },
        ],
      },
    ],
  },
];

const TryHaire = () => {
  const [modalOpen, setModalOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState({
    companyName: "",
    industryType: "",
    jobProfile: "",
    fullName: "",
    email: "",
    phoneNumber: "", // New field
  });
  const [otpStep, setOtpStep] = useState(false);
  const [touchedFields, setTouchedFields] = useState({});
  const [phoneError, setPhoneError] = useState(""); // Track phone number error
  const loading = userStore((state) => state.loading);
  const registerWithEmailPassword = userStore(
    (state) => state.registerWithEmailPassword
  );

  const next = () => {
    if (isStepValid()) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const goToStep2 = () => {
    setOtpStep(false);
    setModalOpen(true);
    setCurrentStep(1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let trimmedValue = value.trim();
    if (name === "jobProfile" && trimmedValue.length > 40) {
      trimmedValue = trimmedValue.slice(0, 40);
    }

    setFormValues({ ...formValues, [name]: value });
    setTouchedFields({ ...touchedFields, [name]: true });
  };

  const handlePhoneNumberChange = (value) => {
    setFormValues({ ...formValues, phoneNumber: value });
    setTouchedFields({ ...touchedFields, phoneNumber: true });

    // Check for phone number validity
    if (isValidPhoneNumber(value)) {
      setPhoneError("");
    } else {
      setPhoneError("Please enter a valid contact number");
    }
  };

  const isStepValid = () => {
    const currentFields = stepsContent[currentStep].formFields;

    for (let field of currentFields) {
      const value = formValues[field.name];

      for (let rule of field.rules || []) {
        if (rule.required && !value) {
          return false;
        }

        if (rule.type === "email" && value) {
          const emailRegex =
            /^[^\s@]+@[^\s@]+\.(com|ai|net|org|edu|gov|mil|biz|info|mobi|name|aero|asia|jobs|museum|[a-z]{2})$/i;
          if (!emailRegex.test(value)) {
            return false;
          }
        }

        if (rule.pattern && value && !rule.pattern.test(value)) {
          return false;
        }
      }

      // Check for specific fields with empty values
      if (
        ["jobProfile", "companyName", "fullName"].includes(field.name) &&
        value.trim() === ""
      ) {
        return false;
      }
    }

    // Additional check for phone number validity
    if (
      currentFields.some((field) => field.name === "phoneNumber") &&
      phoneError
    ) {
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (isStepValid()) {
      registerWithEmailPassword(formValues.email, "defaultPassword123", () => {
        setModalOpen(false);
        setOtpStep(true);
        startTimer();
      });
    }
  };

  const [timer, setTimer] = useState(60);
  const startTimer = () => {
    setTimer(60);
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 0) {
          clearInterval(interval);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  return (
    <>
      <div className="fullscreen-bg"></div>
      <div className="content">
        <Modal
          open={modalOpen}
          footer={null}
          className="verficiation_mod"
          id="Verficiation_mod"
          closeIcon={null}
          bodyStyle={{ height: loading ? "468px" : "auto", overflow: "auto" }}
        >
          {loading ? (
            <div className="loader-cust">
              <Spin size="large" className="modal-loader" />
            </div>
          ) : (
            <>
              {stepsContent[currentStep].header}
              <Steps current={currentStep} className="steps-container">
                {stepsContent.map((step, index) => (
                  <Step key={index} />
                ))}
              </Steps>
              <div className="steps-content">
                <Form layout="vertical">
                  {stepsContent[currentStep].formFields.map((field) => (
                    <Form.Item
                      label={field.label}
                      required
                      key={field.name}
                      validateStatus={
                        touchedFields[field.name] &&
                        !formValues[field.name] &&
                        field.rules?.find((rule) => rule.required)
                          ? "error"
                          : ""
                      }
                      help={
                        touchedFields[field.name] &&
                        !formValues[field.name] &&
                        field.rules?.find((rule) => rule.required)
                          ? field.rules?.find((rule) => rule.required)?.message
                          : ""
                      }
                    >
                      {field.type === "select" ? (
                        <select
                          className="custom-select2"
                          name={field.name}
                          value={formValues[field.name]}
                          onChange={handleInputChange}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            {field.placeholder}
                          </option>

                          <option value="it">IT</option>
                          <option value="finance">Finance</option>
                          <option value="healthcare">Healthcare</option>
                          <option value="education">Education</option>
                        </select>
                      ) : field.type === "phone" ? (
                        <>
                          <MuiPhoneNumber
                            name={field.name}
                            variant="filled"
                            defaultCountry="in" // Set your default country
                            value={formValues.phoneNumber} // Use phoneNumber here
                            onChange={handlePhoneNumberChange}
                            className="custom-phone-input1"
                            required
                            fullWidth
                            InputProps={{
                              disableUnderline: true,
                            }}
                            disabled={loading}
                            sx={{
                              height: 42,
                              "& .MuiInputBase-input": {
                                height: "30px",
                                padding: "6px 14px",
                              },
                              "& .MuiFilledInput-root": {
                                borderRadius: "4px",
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "inherit",
                                background: "rgba(248, 248, 248, 1)",
                              },
                            }}
                          />
                          {phoneError && (
                            <div className="ant-form-item-explain-error">
                              {phoneError}
                            </div>
                          )}
                        </>
                      ) : (
                        <Input
                          name={field.name}
                          placeholder={field.placeholder}
                          value={formValues[field.name]}
                          onChange={handleInputChange}
                          onBlur={() =>
                            setTouchedFields({
                              ...touchedFields,
                              [field.name]: true,
                            })
                          }
                          maxLength={
                            field.name === "jobProfile"
                              ? 40
                              : field.name === "companyName"
                              ? 60
                              : field.name === "fullName"
                              ? 70
                              : undefined
                          }
                        />
                      )}
                    </Form.Item>
                  ))}
                </Form>

                <div className="steps-action">
                  {currentStep < stepsContent.length - 1 && (
                    <Button
                      type="primary"
                      onClick={next}
                      disabled={!isStepValid()}
                      className={!isStepValid() ? "disabled-btn" : ""}
                    >
                      Next
                    </Button>
                  )}
                  {currentStep === stepsContent.length - 1 && (
                    <Button
                      type="primary"
                      disabled={!isStepValid()}
                      className={!isStepValid() ? "disabled-btn" : ""}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </Modal>
        {otpStep && (
          <TryHaireOtp
            timer={timer}
            setTimer={setTimer}
            startTimer={startTimer}
            formData={formValues}
            closeModal={() => setOtpStep(false)}
            goToStep2={goToStep2}
          />
        )}
      </div>
    </>
  );
};

export default TryHaire;
