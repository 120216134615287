import { Skeleton, Table } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Credit, eye_icon } from "../../../../assets/images/images";
import NoJobApplicantFound from "../../../../components/CommonComponents/NoJobApplicantFound";
import { fetchJobsList } from "../../../../redux/actions/jobActions";
import { formatDate } from "../../../../utils/utils";
import { Reset, Searchbar, SortFilter, StatusFilter } from "./MyJobs";
import "./MyJobs.css";
import { debounce, sortBy } from "lodash";
import { setJobPageFilters } from "../../../../redux/reducers/jobReducer";
import { current } from "@reduxjs/toolkit";
import SkeletonLoader from "../CommonRecruiterComponant/SkeletonLoader";

const MyJobsTable = ({ activeTab, status }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialRender = useRef(true);
  const initialRenderCount = useRef(0);
  const [sortCriteria, setSortCriteria] = useState("All");
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(status);
  const [currentPage, setCurrentPage] = useState(1);
  const loading = useSelector((state) => state.job.loading);
  const jobList = useSelector((state) => state.job.jobsList);
  const token = useSelector((state) => state.auth.token);
  const jobsCount = useSelector((state) => state.job.jobsCount);
  const jobPageFilters = useSelector((state) => state.job.jobPageFilters);

  useEffect(() => {
    //on load check currentPage
    if (initialRenderCount.current < 2) {
      initialRenderCount.current = initialRenderCount.current + 1;
      setCurrentPage(jobPageFilters.current || 1);
      setSearchInput(jobPageFilters.searchInput);
      setSelectedStatus(jobPageFilters.status);
      setSortCriteria(jobPageFilters.sortBy);
      fetchJobs(
        jobPageFilters.current,
        jobPageFilters.searchInput,
        jobPageFilters.sortBy,
        jobPageFilters.status
      );
    } else {
      setCurrentPage(jobPageFilters.current || 1);
      setSearchInput(jobPageFilters.searchInput);
      setSelectedStatus(jobPageFilters.status);
      setSortCriteria(jobPageFilters.sortBy);
    }
  }, [jobPageFilters]);

  // useEffect(() => {
  //   fetchJobs(1);
  // }, [activeTab, status]);

  const handleViewJob = (jobId) => {
    navigate(`/dashboard/my-jobs/${activeTab}/${jobId}/view-jobs-data`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchJobs(page);
    dispatch(
      setJobPageFilters({
        current: page,
        status: selectedStatus,
        sortBy: sortCriteria,
        searchInput,
      })
    );
  };

  const handleSearchSubmit = (searchValue) => {
    setSearchInput(searchValue);

    setCurrentPage((prevPage) => {
      if (prevPage !== 1) {
        return 1;
      }

      fetchJobs(1);
      return prevPage;
    });
  };

  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
    setCurrentPage(1); // Reset page to 1 whenever the sort criteria changes
    fetchJobs(1, searchInput, criteria, selectedStatus); // Immediately fetch with new criteria
    dispatch(
      setJobPageFilters({
        current: 1,
        status: selectedStatus,
        sortBy: criteria,
        searchInput,
      })
    );
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setCurrentPage(1); // Reset page to 1 whenever the status changes
    fetchJobs(1, searchInput, sortCriteria, status); // Immediately fetch with new status
    dispatch(
      setJobPageFilters({
        current: 1,
        status: status,
        sortBy: sortCriteria,
        searchInput,
      })
    );
  };

  const handleReset = () => {
    setSearchInput("");
    setSortCriteria("All");
    setSelectedStatus("All");
    setCurrentPage(1);
    fetchJobs(1, "", "All", "All");
  };

  const fetchJobs = (
    page,
    searchValue = searchInput,
    sortValue = sortCriteria,
    statusValue = selectedStatus
  ) => {
    // Ensure page is set to 1 on new searches
    const finalStatus = statusValue === "All" ? undefined : statusValue;
    dispatch(
      fetchJobsList(token, searchValue, undefined, sortValue, finalStatus, page)
    );
  };

  useEffect(() => {
    if (initialRender.current) {
      // Skip the initial render
      initialRender.current = false;
    } else {
      // This will handle changes to searchInput only after the initial render
      setCurrentPage(1);
      fetchJobs(1, searchInput, sortCriteria, selectedStatus);
      dispatch(
        setJobPageFilters({
          current: 1,
          status: selectedStatus,
          sortBy: sortCriteria,
          searchInput,
        })
      );
    }
  }, [searchInput, activeTab, status]);

  const columns = [
    {
      title: "Job Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <div data-th="Job Title">{text}</div>,
    },
    {
      title: "Publish Date",
      dataIndex: "published_date",
      key: "published_date",
      render: (text) => <div data-th="Publish Date">{formatDate(text)}</div>,
    },
    {
      title: "Applicants",
      dataIndex: "applicants_count",
      key: "applicants_count",
      render: (text) => <div data-th="Applicants">{text}</div>,
    },
    {
      title: "AI Interviewed",
      dataIndex: "ai_interviewed_count",
      key: "ai_interviewed_count",
      render: (text) => <div data-th="AI Interviewed">{text}</div>,
    },
    {
      title: "AI Shortlisted",
      dataIndex: "ai_shortlisted_count",
      key: "ai_shortlisted_count",
      render: (text) => <div data-th="AI Shortlisted">{text}</div>,
    },
    {
      title: "Remaining Credits",
      dataIndex: "credits_left",
      key: "credits_left",
      render: (text) => (
        <div data-th="Remaining Credits">
          <img
            src={Credit}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "5px" }}
          />{" "}
          {text}
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => <div data-th="Status">{renderStatusTag(status)}</div>,
    },
    {
      title: "View Details",
      dataIndex: "id",
      key: "view",
      render: (text, record) => (
        <a onClick={() => handleViewJob(record.id)}>
          <img src={eye_icon} alt="eye_icon" className="eye_icon" />
          View
        </a>
      ),
    },
  ];

  return (
    <>
      <div className="row">
        <div className="filter-container">
          <div className="filter-section ">
            <Searchbar
              inputValue={searchInput}
              setInputValue={setSearchInput}
              onSearchSubmit={handleSearchSubmit}
            />
            <StatusFilter
              onStatusChange={handleStatusChange}
              selectedStatus={selectedStatus}
            />
            <Reset onReset={handleReset} />
          </div>
          <div className="sort-section">
            <SortFilter
              onSortChange={handleSortChange}
              selectedValue={sortCriteria}
              activeTab={activeTab}
            />
          </div>
        </div>

        {/* Responsive layout */}
        <div className="filter-container-responsive">
          <div className="search-bar-section">
            <Searchbar
              inputValue={searchInput}
              setInputValue={setSearchInput}
              onSearchSubmit={handleSearchSubmit}
            />
          </div>
          <div className="responsive_filter">
            <div className="row  d-flex g-3 mb-1 align-items-baseline">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-6 d-flex flex-column align-items-baseline">
                    <span className="sort-by-label sort_p1">Status:</span>

                    <StatusFilter
                      onStatusChange={handleStatusChange}
                      selectedStatus={selectedStatus}
                    />
                  </div>
                  <div className="col-6 d-flex flex-column align-items-baseline">
                    <span className="sort-by-label sort_p1">Sort By:</span>
                    <SortFilter
                      onSortChange={handleSortChange}
                      selectedValue={sortCriteria}
                      activeTab={activeTab}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-options-section-responsive">
            {/* <StatusFilter
              onStatusChange={handleStatusChange}
              selectedStatus={selectedStatus}
            />
            <SortFilter
              onSortChange={handleSortChange}
              selectedValue={sortCriteria}
              activeTab={activeTab}
            /> */}
          </div>
          <div>
            <Reset onReset={handleReset} />
          </div>
        </div>
        {/* Responsive layout */}

        {loading ? (
          <SkeletonLoader excludeDetails={true} includeTable={true} />
        ) : jobList && jobList.length > 0 ? (
          <Table
            className="p-0"
            columns={columns}
            dataSource={jobList}
            pagination={{
              pageSize: 12,
              total: jobsCount,
              current: currentPage,
              onChange: handlePageChange,
              showSizeChanger: false,
            }}
          />
        ) : (
          <NoJobApplicantFound />
        )}
      </div>
    </>
  );
};

export default MyJobsTable;

const renderStatusTag = (status) => {
  let color, textColor, text;

  switch (status) {
    case "Published":
      color = "rgba(3, 136, 64, 0.20)";
      textColor = "#038840";
      text = "ACTIVE";
      break;
    case "Closed":
      color = "rgba(218, 0, 0, 0.20)";
      textColor = "#DC0000";
      text = status.toUpperCase();
      break;
    default:
      color = "grey";
      text = status.toUpperCase();
  }

  return (
    <span
      style={{
        backgroundColor: color,
        color: textColor,
        fontSize: "12px",
        fontWeight: "600",
        padding: "4px 25px",
        borderRadius: "4px",
      }}
    >
      {text}
    </span>
  );
};
