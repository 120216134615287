import React, { useEffect, useState } from "react";
import validator from "validator";

import "./Invites.css";

const Invites = ({ onItemsChange }) => {
  const [items, setItems] = useState([]);
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab"].includes(evt.key)) {
      evt.preventDefault();

      processInput(value);

      // const trimmedValue = value.trim();

      // if (trimmedValue && isValid(trimmedValue)) {
      //   setItems([...items, trimmedValue]);
      //   setValue("");
      // }
    }
  };

  useEffect(() => {
    onItemsChange(items);
  }, [items, onItemsChange]);

  const handleChange = (evt) => {
    setValue(evt.target.value);
    setError(null);
  };

  const handleDelete = (item) => {
    setItems(items.filter((i) => i !== item));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData("text");

    processInput(paste);

    // const emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    // if (emails) {
    //   const toBeAdded = emails.filter((email) => !isInList(email) && isValid(email));
    //   setItems([...items, ...toBeAdded]);
    // }
  };

  // const processInput = (input) => {
  //   const emailArray = input.split(/[, ]+/).map(email => email.trim());
  //   const validEmails = emailArray.filter(email => isValid(email));
  //   setItems([...items, ...validEmails]);
  //   setValue("");
  // };

  const processInput = (input) => {
    const emailArray = input.split(/[, ]+/).map(email => email.trim());
    const validEmails = [];
    let invalidEmail = null;

    emailArray.forEach(email => {
      if (isValid(email)) {
        validEmails.push(email);
      } else {
        invalidEmail = email;
      }
    });

    if (validEmails.length) {
      setItems([...items, ...validEmails]);
    }

    if (invalidEmail) {
      setValue(invalidEmail);
    } else {
      setValue("");
    }
  };

  const isValid = (email) => {
    let validationError = null;

    if (isInList(email)) {
      validationError = `${email} has already been added.`;
    }

    else if (!isEmail(email)) {
      validationError = `${email} is not a valid email address.`;
    }

    if (validationError) {
      setError(validationError);
      return false;
    }

    return true;
  };

  const isInList = (email) => items.includes(email);

  const isEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu|ai|gov|mil|biz|info|mobi|name|aero|jobs|museum|co|io|in|uk|us|ca|au|za|de|fr|eu|cn|ru|br|kr|jp|mx|es|it|nl|se|no|fi|dk|ar|cl|co|hk|my|nz|sg|th|tw|vn)$/i;
    return validator.isEmail(email) && regex.test(email);
  }

  return (
    <>
      <div className="d-block1">
        <div className="wd">
          <input
            className={`input ${error ? "has-error" : ""}`}
            value={value}
            placeholder="Type or paste email addresses and press `Enter`..."
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onPaste={handlePaste}
          />
          {error && <p className="error">{error}</p>}
        </div>
        <div className="">
          {items.map((item) => (
            <div className="tag-item" key={item}>
              {item}
              <button
                type="button"
                className="button"
                onClick={() => handleDelete(item)}
              >
                &times;
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Invites;
