import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ApplicantJob,
  JobApplications,
  JobDetails,
  jobPageFilters,
} from "../types/jobTypes";

export interface JobState {
  details: JobDetails | null;
  jobsList: JobDetails[];
  draftJobsList: JobDetails[];
  closedJobsList: JobDetails[];
  invitedJobsList: JobDetails[];
  requestedJobsList: JobDetails[];
  recentJobsList: JobDetails[];
  applicantJobsList: ApplicantJob[];
  options: { [key: string]: string };
  currencies: { [key: string]: string };
  applicantRecommendJobsList: ApplicantJob[];
  appliedJobsList: ApplicantJob[];
  jobApplicantsList: JobApplications[];
  interviewId: number | null;
  jobsCount: number | null;
  draftJobsCount: number | null;
  closedJobsCount: number | null;
  invitedJobsCount: number | null;
  requestedJobsCount: number | null;
  isDraftSaved: boolean;
  totalCount: number | null;
  nextPage: string | null;
  previousPage: string | null;
  loading: boolean;
  error: string | null;
  jobPageFilters: jobPageFilters;
}

const initialState: JobState = {
  details: null,
  jobsList: [],
  draftJobsList: [],
  closedJobsList: [],
  recentJobsList: [],
  invitedJobsList: [],
  requestedJobsList: [],
  applicantJobsList: [],
  options: {},
  currencies: {},
  applicantRecommendJobsList: [],
  appliedJobsList: [],
  jobApplicantsList: [],
  interviewId: 0,
  jobsCount: 0,
  draftJobsCount: 0,
  closedJobsCount: 0,
  invitedJobsCount: 0,
  requestedJobsCount: 0,
  totalCount: 0,
  nextPage: null,
  previousPage: null,
  isDraftSaved: false,
  loading: false,
  error: null,
  jobPageFilters: {
    current: 1,
    sortBy: "All",
    status: "All",
    searchInput: "",
    type: "All",
  },
};

const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    //Set job list/details reducer
    setJobDetails: (state: JobState, action: PayloadAction<JobDetails>) => {
      state.details = action.payload as JobDetails;
    },
    setJobList: (state: JobState, action: PayloadAction<JobDetails[]>) => {
      state.jobsList = action.payload;
    },
    setDraftsJobList: (
      state: JobState,
      action: PayloadAction<JobDetails[]>
    ) => {
      state.draftJobsList = action.payload;
    },
    setClosedJobList: (
      state: JobState,
      action: PayloadAction<JobDetails[]>
    ) => {
      state.closedJobsList = action.payload;
    },
    setInvitedJobList: (
      state: JobState,
      action: PayloadAction<JobDetails[]>
    ) => {
      state.invitedJobsList = action.payload;
    },
    setRequestedJobList: (
      state: JobState,
      action: PayloadAction<JobDetails[]>
    ) => {
      state.requestedJobsList = action.payload;
    },
    setRecentJobList: (
      state: JobState,
      action: PayloadAction<JobDetails[]>
    ) => {
      state.recentJobsList = action.payload;
    },
    setApplicantJobsList: (
      state: JobState,
      action: PayloadAction<ApplicantJob[]>
    ) => {
      state.applicantJobsList = action.payload;
    },
    setRecommendJobsList: (
      state: JobState,
      action: PayloadAction<ApplicantJob[]>
    ) => {
      state.applicantRecommendJobsList = action.payload;
    },
    setAppliedJobsList: (
      state: JobState,
      action: PayloadAction<ApplicantJob[]>
    ) => {
      state.appliedJobsList = action.payload;
    },
    setJobApplicantsList: (
      state: JobState,
      action: PayloadAction<JobApplications[]>
    ) => {
      state.jobApplicantsList = action.payload;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    setNextPage: (state, action) => {
      state.nextPage = action.payload;
    },
    setPreviousPage: (state, action) => {
      state.previousPage = action.payload;
    },
    setOptionsList: (
      state: JobState,
      action: PayloadAction<{ [key: string]: string }>
    ) => {
      state.options = action.payload;
    },

    //Update job list/details reducer
    updateJobDetails: (
      state: JobState,
      action: PayloadAction<Partial<JobDetails>>
    ) => {
      if (state.details === null) {
        state.details = action.payload as JobDetails;
      } else {
        state.details = { ...state.details, ...action.payload };
      }
    },
    updateJobList: (state, action: PayloadAction<JobDetails[]>) => {
      state.jobsList = state.jobsList.map(
        (job) =>
          action.payload.find((updatedJob) => updatedJob.id === job.id) || job
      );
    },
    updateDraftJobList: (state, action: PayloadAction<JobDetails[]>) => {
      state.draftJobsList = state.draftJobsList.map(
        (job) =>
          action.payload.find((updatedJob) => updatedJob.id === job.id) || job
      );
    },
    updateClosedJobList: (state, action: PayloadAction<JobDetails[]>) => {
      state.closedJobsList = state.closedJobsList.map(
        (job) =>
          action.payload.find((updatedJob) => updatedJob.id === job.id) || job
      );
    },
    updateInvitedJobList: (state, action: PayloadAction<JobDetails[]>) => {
      state.invitedJobsList = state.invitedJobsList.map(
        (job) =>
          action.payload.find((updatedJob) => updatedJob.id === job.id) || job
      );
    },
    updateRequestJobList: (state, action: PayloadAction<JobDetails[]>) => {
      state.requestedJobsList = state.requestedJobsList.map(
        (job) =>
          action.payload.find((updatedJob) => updatedJob.id === job.id) || job
      );
    },
    updateRecentJobList: (state, action: PayloadAction<JobDetails[]>) => {
      state.recentJobsList = state.recentJobsList.map(
        (job) =>
          action.payload.find((updatedJob) => updatedJob.id === job.id) || job
      );
    },
    updateApplicantJobsList: (state, action: PayloadAction<ApplicantJob[]>) => {
      state.applicantJobsList = state.applicantJobsList.map(
        (job) =>
          action.payload.find((updatedJob) => updatedJob.id === job.id) || job
      );
    },
    updateRecommendJobsList: (state, action: PayloadAction<ApplicantJob[]>) => {
      state.applicantRecommendJobsList = state.applicantRecommendJobsList.map(
        (job) =>
          action.payload.find((updatedJob) => updatedJob.id === job.id) || job
      );
    },
    updateAppliedJobsList: (state, action: PayloadAction<ApplicantJob[]>) => {
      state.appliedJobsList = state.appliedJobsList.map(
        (job) =>
          action.payload.find((updatedJob) => updatedJob.id === job.id) || job
      );
    },

    // Reducer to set the total my jobs count
    setJobsCount: (state, action) => {
      state.jobsCount = action.payload;
    },

    // Reducer to set the total draft jobs count
    setDraftJobsCount: (state, action) => {
      state.draftJobsCount = action.payload;
    },

    // Reducer to set the total closed jobs count
    setClosedJobsCount: (state, action) => {
      state.closedJobsCount = action.payload;
    },
    setInvitedJobsCount: (state, action) => {
      state.invitedJobsCount = action.payload;
    },

    // Reducer to set the total closed jobs count
    setRequestedJobsCount: (state, action) => {
      state.requestedJobsCount = action.payload;
    },
    // Reducer to set the total closed jobs count
    setJobPageFilters: (state, action) => {
      state.jobPageFilters = action.payload || 0;
    },
    // Reducer to set the interviewId
    setInterviewId: (state: JobState, action: PayloadAction<number>) => {
      state.interviewId = action.payload;
    },
    setCurrencies: (
      state: JobState,
      action: PayloadAction<{ [key: string]: string }>
    ) => {
      state.currencies = action.payload;
    },

    setJobLoading: (state: JobState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setJobError: (state: JobState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setDraftSaved: (state: JobState, action: PayloadAction<boolean>) => {
      state.isDraftSaved = action.payload;
    },

    resetJobDraftState: (state: JobState) => {
      state.details = initialState.details;
    },

    resetJobDetailsState: (state: JobState) => {
      state.details = initialState.details;
    },

    resetJobState: () => initialState,
  },
});

export const {
  setJobDetails,
  setJobList,
  setDraftsJobList,
  setClosedJobList,
  setInvitedJobList,
  setRequestedJobList,
  setRecentJobList,
  updateInvitedJobList,
  updateRequestJobList,
  updateJobList,
  updateDraftJobList,
  updateClosedJobList,
  updateRecentJobList,
  updateJobDetails,
  setDraftSaved,
  setJobLoading,
  setJobError,
  resetJobState,
  resetJobDraftState,
  resetJobDetailsState,
  setApplicantJobsList,
  setJobApplicantsList,
  updateApplicantJobsList,
  setRecommendJobsList,
  setAppliedJobsList,
  updateRecommendJobsList,
  updateAppliedJobsList,
  setInterviewId,
  setJobsCount,
  setTotalCount,
  setNextPage,
  setPreviousPage,
  setDraftJobsCount,
  setClosedJobsCount,
  setInvitedJobsCount,
  setRequestedJobsCount,
  setOptionsList,
  setCurrencies,
  setJobPageFilters,
} = jobSlice.actions;
export default jobSlice.reducer;
