// Create a new file: src/contexts/PricingModalContext.js

import React, { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isEmpty } from "../utils/utils";

const PricingModalContext = createContext();

export const usePricingModal = () => useContext(PricingModalContext);

export const PricingModalProvider = ({ children }) => {
  const companyDetails = useSelector((state) => state.company.details);
  const [isPricingModalOpen, setPricingModalOpen] = useState(false);

  const showPricingModal = () => {
    if (isEmpty(companyDetails)) {
      toast.error("Please create Company to get subscription details");
    } else {
      setPricingModalOpen(true);
    }
  };
  const hidePricingModal = () => setPricingModalOpen(false);

  return (
    <PricingModalContext.Provider
      value={{ isPricingModalOpen, showPricingModal, hidePricingModal }}
    >
      {children}
    </PricingModalContext.Provider>
  );
};
