import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { hairoAvatar } from "../../../../assets/images/images";
import InterviewModalComponant from "../../../../components/CommonComponents/InterviewModal/InterviewModalComponents";
import GuideLineModal from "../../../../components/CommonComponents/platformGuide/AiInterviewGuide";
import "../InterviewModule/InterviewModule.css";
import InterviewModuleNav from "./InterviewModuleNav";

const Interviewpanel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { jobId, interviewId } = useParams();
  const [permissionsGranted, setPermissionsGranted] = useState(false);
  const [show, setShow] = useState(true);
  const [showGuideLine, setShowGuideLine] = useState(false);
  const mediaPermissions = useRef(null);
  const mediaStream = useRef(null);

  const data = location?.state?.data;
  useEffect(() => {
    // Check for permissions at component mount
    const checkPermissions = async () => {
      mediaPermissions.current = await navigator.permissions.query({ name: "camera" });
      if (mediaPermissions.current.state === "granted") {
        setPermissionsGranted(true);
        startMedia();
      } else {
        setShow(true); // Only show modal if permissions are not granted
      }
    };

    checkPermissions();

    return () => {
      stopMedia();
    }
  }, []);

  const startMedia = async () => {
    try {
      mediaStream.current = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    } catch (error) {
      console.error('Error accessing media devices.', error);
    }
  };

  const stopMedia = () => {
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach(track => track.stop());
      mediaStream.current = null;
    }
  };

  const interviewClick = () => {
    navigate(
      `/applicant-dashboard/related-jobs/${jobId}/start-interview/${interviewId}/interview-panel/interview-panel-ready`,
      { state: { data: data }, replace: true }
    );
  };

  const handleCloseModal = () => {
    setPermissionsGranted(false);
    setShow(false);
    setShowGuideLine(true);
  };

  return (
    <>
      <InterviewModuleNav data={data} />
      <section className="int_panel_body_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="int_panel_body_sec_inner">
                <img
                  src={hairoAvatar}
                  alt=""
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="int_panel_body_allow_btn_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="int_panel_body_allow_btn_sec_inner">
                <div className="int_panel_body_sec_inner_btn">
                  <button onClick={interviewClick}>
                    <i className="fas fa-microphone"></i>Start When Ready
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {show && (
        <Modal
          show={show}
          backdrop="static"
          keyboard={false}
          className="allow_micro_phone_pop"
        >
          {/* Pass mediaStream.current as a prop to InterviewModalComponant */}
          <InterviewModalComponant onDone={handleCloseModal} mediaStream={mediaStream.current} />
        </Modal>
      )}
      {showGuideLine && (
        <GuideLineModal show={handleCloseModal} onHide={() => setShowGuideLine(false)} />
      )}
    </>
  );
};

export default Interviewpanel;
