import { create } from "zustand";
import * as Yup from "yup";
import {
  auth,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from "../config/firebaseConfig";
import { toast } from "react-toastify";

interface ForgetPasswordFormData {
  email: string;
}

interface ResetPasswordFormData {
  newPassword: string;
  confirmPassword: string;
}

interface ForgetPasswordFormErrors {
  [key: string]: string | undefined;
}

interface ResetPasswordFormErrors {
  [key: string]: string | undefined;
}

interface StoreState {
  forgetPasswordFormData: ForgetPasswordFormData;
  resetPasswordFormData: ResetPasswordFormData;
  forgetPasswordFormErrors: ForgetPasswordFormErrors;
  resetPasswordFormErrors: ResetPasswordFormErrors;
  setForgetPasswordFormData: (
    name: keyof ForgetPasswordFormData,
    value: string
  ) => void;
  setResetPasswordFormData: (
    name: keyof ForgetPasswordFormData,
    value: string
  ) => void;
  validateForgetPasswordForm: () => Promise<boolean>;
  validateResetPasswordForm: () => Promise<boolean>;
  validateForgetPasswordField: (
    name: keyof ForgetPasswordFormData,
    value: string
  ) => void;
  validateResetPasswordField: (
    name: keyof ResetPasswordFormData,
    value: string
  ) => void;
  sendForgotPasswordEmail: (email: string) => void;
  updateNewPassword: (
    oobCode: string,
    newPassword: string,
    navigate: Function
  ) => void;
  error: string | null;
  loading: boolean;
}

const forgetPasswordSchema = Yup.object<ForgetPasswordFormData>({
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      // Regex for more stringent email validation
      /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "Please use valid email address"
    )
    .required("Email is required"),
});

const resetPasswordSchema = Yup.object<ResetPasswordFormData>({
  newPassword: Yup.string()
    .trim() // Optional, depends on whether you want to ignore leading/trailing spaces.
    .matches(
      /^(?=.*\S).+$/,
      "Password must contain at least one non-whitespace character"
    )
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),

  confirmPassword: Yup.string()
    .trim()
    .matches(
      /^(?=.*\S).+$/,
      "Confrim Password must contain at least one non-whitespace character"
    )
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Confirm password is required"),
});

const passwordStore = create<StoreState>((set, get) => ({
  forgetPasswordFormData: {
    email: "",
  },
  resetPasswordFormData: {
    newPassword: "",
    confirmPassword: "",
  },
  forgetPasswordFormErrors: {},
  resetPasswordFormErrors: {},
  setForgetPasswordFormData: (name, value) =>
    set((state) => ({
      forgetPasswordFormData: {
        ...state.forgetPasswordFormData,
        [name]: value,
      },
    })),
  setResetPasswordFormData: (name, value) =>
    set((state) => ({
      resetPasswordFormData: {
        ...state.resetPasswordFormData,
        [name]: value,
      },
    })),

  validateForgetPasswordForm: async () => {
    try {
      await forgetPasswordSchema.validate(get().forgetPasswordFormData, {
        abortEarly: false,
      });
      set({ forgetPasswordFormErrors: {} });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = err.inner.reduce<ForgetPasswordFormErrors>(
          (acc, { path, message }) => {
            if (path) {
              acc[path as keyof ForgetPasswordFormData] = message;
            }
            return acc;
          },
          {}
        );
        set({ forgetPasswordFormErrors: errors });
      }
      return false;
    }
  },
  validateResetPasswordForm: async () => {
    try {
      await resetPasswordSchema.validate(get().resetPasswordFormData, {
        abortEarly: false,
      });
      set({ resetPasswordFormErrors: {} });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = err.inner.reduce<ResetPasswordFormErrors>(
          (acc, { path, message }) => {
            if (path) {
              acc[path as keyof ResetPasswordFormData] = message;
            }
            return acc;
          },
          {}
        );
        set({ resetPasswordFormErrors: errors });
      }
      return false;
    }
  },
  validateForgetPasswordField: async (
    name: keyof ForgetPasswordFormData,
    value: string
  ) => {
    try {
      await forgetPasswordSchema.validateAt(name, {
        ...get().forgetPasswordFormData,
        [name]: value,
      });
      set({
        forgetPasswordFormErrors: {
          ...get().forgetPasswordFormErrors,
          [name]: undefined,
        },
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        set({
          forgetPasswordFormErrors: {
            ...get().forgetPasswordFormErrors,
            [name]: error.message,
          },
        });
      }
    }
  },
  validateResetPasswordField: async (
    name: keyof ResetPasswordFormData,
    value: string
  ) => {
    try {
      await resetPasswordSchema.validateAt(name, {
        ...get().resetPasswordFormData,
        [name]: value,
      });
      set({
        resetPasswordFormErrors: {
          ...get().resetPasswordFormErrors,
          [name]: undefined,
        },
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        set({
          resetPasswordFormErrors: {
            ...get().resetPasswordFormErrors,
            [name]: error.message,
          },
        });
      }
    }
  },
  setFormErrors: (errors: any) =>
    set((state) => ({
      resetPasswordFormErrors: { ...state.resetPasswordFormErrors, ...errors },
      forgetPasswordFormErrors: {
        ...state.forgetPasswordFormErrors,
        ...errors,
      },
    })),

  sendForgotPasswordEmail: async (email) => {
    set({ loading: true });
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success(
        "Password Reset link will be sent to your email if your account is present."
      );
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        set({ error: error.message });
      }
      return false;
    } finally {
      set({ loading: false });
    }
  },
  updateNewPassword: async (oobCode, newPassword, navigate) => {
    set({ loading: true });
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      navigate("/login", { replace: true });
      toast.success("Password Changed Successfully");
    } catch (error: any) {
      // Handle Firebase error codes
      let errorMessage = "An error occurred. Please try again.";
      switch (error?.code) {
        case "auth/expired-action-code":
          errorMessage =
            "The action code has expired. Please request a new password reset.";
          break;
        case "auth/invalid-action-code":
          errorMessage =
            "The action code is invalid. Please check the link and try again.";
          break;
        case "auth/user-disabled":
          errorMessage = "The user account has been disabled.";
          break;
        case "auth/user-not-found":
          errorMessage = "No user corresponding to the given email.";
          break;
        case "auth/weak-password":
          errorMessage =
            "The password is too weak. Please choose a stronger password.";
          break;
        default:
          errorMessage = error?.message;
      }
      toast.error(errorMessage);
      set({ error: errorMessage });

      return false;
    } finally {
      set({ loading: false });
    }
  },
  error: null,
  loading: false,
  modalOpen: false,
}));

export default passwordStore;
