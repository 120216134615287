import React, { useEffect, useState } from "react";
import { Form, Switch, Table, Tag } from "antd";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";
import {
  Reset,
  Searchbar,
  SortFilterApplicant,
  StatusFilterApplicant,
} from "./MyJobs";
import "./ViewJobsData.css";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, renderCurrencyIcon } from "../../../../utils/utils";
import {
  closeJob,
  fetchInvitedApplicants,
  fetchSingleJobDetails,
} from "../../../../redux/actions/jobActions";
import "../JobDetails/JobDetails.css";
import { marked } from "marked";
import {
  draft_line,
  Bag,
  loacation_icon,
  doc_icon,
  close_job,
  Question_Mark,
  Dw,
  Up,
} from "../../../../assets/images/images";
import { employmentType, yearsOfExperience } from "../../../../utils/constants";
import { ThreeCircles } from "react-loader-spinner";

const ViewInvitedJobs = () => {
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [isClosingJob, setIsClosingJob] = useState(false);
  const [showreport, setshowreport] = useState(false);
  const [sortCriteria, setSortCriteria] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatus, setSelectedStatus] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [showDownButton, setShowDownButton] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(false);

  ////////////////////////////////useSelectors///////////////////////////////////////////
  const jobApplicants = useSelector((state) => state?.job?.jobApplicantsList);
  const jobsDetails = useSelector((state) => state.job?.details);
  const jobsCount = useSelector((state) => state.job.jobsCount);
  const token = useSelector((state) => state.auth.token);

  ////////////////////////////Navigation events////////////////////////////////////////////////////////
  const handleBackButtonClick = () => {
    navigate("/dashboard/my-jobs", { replace: true });
  };
  const handleEditJob = () => {
    navigate(`/dashboard/my-jobs/jobEdit/${jobId}/edit`);
  };

  ////////////////////////////Toggle functionality events/////////////////////////////////////////////////////////
  const toggleAccordion = () => {
    setOpen(!open);
  };

  const handleinviteJob = () => {
    setshowreport(true);
  };

  const handleSwitchChange = () => {
    setIsModalOpen(true);
    setIsClosingJob(isSwitchChecked);
  };
  const handleDownButtonClick = () => {
    toggleAccordion();
    setShowDownButton(false);
  };
  const handleUpButtonClick = () => {
    toggleAccordion();
    setShowDownButton(true);
  };
  const handleModalConfirm = () => {
    const newStatus = isSwitchChecked ? "Published" : "Closed";
    dispatch(closeJob(token, jobId, newStatus === "Published"));
    setIsSwitchChecked(!isSwitchChecked);
    setIsModalOpen(false);
  };

  ///////////////////////////Text rendering events//////////////////////////////////////////////////////
  const capitalizeFirstLetter = (string) => {
    if (typeof string !== "string" || !string) {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const renderMarkdown = (description) => {
    if (description) {
      return { __html: marked(description) };
    }
    return { __html: "" };
  };

  ///////////////////////////////////////filters and pagination///////////////////////////////////////////////
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSearchSubmit = (searchValue) => {
    setSearchInput(searchValue);
  };
  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
  };
  const handleReset = () => {
    setSearchInput("");
    setSortCriteria("All");
    setSelectedStatus("All");
  };

  ///////////////////////////////////////Useffects////////////////////////////////////////////////
  useEffect(() => {
    if (jobsDetails?.status === "Closed") {
      setIsSwitchChecked(true);
    } else if (jobsDetails?.status === "Published") {
      setIsSwitchChecked(false);
    }
  }, [jobsDetails]);

  useEffect(() => {
    const statusValue = selectedStatus === "All" ? undefined : selectedStatus;
    if (token && jobId) {
      setIsLoadingTable(true); // Start loading
      Promise.all([
        dispatch(fetchSingleJobDetails(jobId, token)),

        dispatch(fetchInvitedApplicants(token, jobId, searchInput)),
      ])
        .then(() => {
          setIsLoadingTable(false); // Stop loading when both requests are complete
        })
        .catch(() => {
          setIsLoadingTable(false); // Ensure loading is stopped even if there is an error
          // Handle error, e.g., set an error state, show a message, etc.
        });
    }
  }, [dispatch, jobId, token, searchInput, sortCriteria, selectedStatus]);

  //////////////////////////////////////////////table columns////////////////////////////////////
  const columns = [
    {
      title: "Contact Info",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Invited Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div data-th="Invited Date">{formatDate(text)}</div>,
    },
    {
      title: "Activity",
      dataIndex: "status",
      key: "status",
      render: (status) => <StatusTag status={status} />,
    },
  ];
  return (
    <>
      <div className="container view_jobs_min_sec_data">
        <DashboardHeader title="" backButtonLink={handleBackButtonClick} />
        <div className="row new_comp_sec_data_sec">
          <section className="company_details_section ">
            <div className="accordion" id="company_main_container">
              <div className="accordion-item">
                <div className="company_detail_main">
                  <div className="company_logo">
                    <img src={jobsDetails?.company_profile_pic} />
                  </div>
                  <div className="d-flex">
                    <div className="me-3">
                      <span className="switch_lable_head">
                        <Form.Item label="Close Job" valuePropName="checked">
                          <Switch
                            checked={isSwitchChecked}
                            onChange={handleSwitchChange}
                          />
                        </Form.Item>
                      </span>
                    </div>
                    <div className="my_comp_dashboard_left_edit">
                      <button
                        type="button"
                        onClick={handleEditJob}
                        className="custom-edit-button-class"
                      >
                        <i className="fas fa-pencil-alt"></i> Edit
                      </button>
                    </div>
                  </div>
                </div>
                <h2 className="headingOne" id="headingOne">
                  {jobsDetails?.title} -{" "}
                  <span> {jobsDetails?.company_name}</span>
                </h2>
                <div className="d-flex">
                  <div className="doc_file">
                    <img src={draft_line} />
                  </div>
                  <p className="comp_data">{jobsDetails?.company_overview}</p>
                </div>
                <div
                  className="company_address "
                  type="button"
                  onClick={toggleAccordion}
                  aria-expanded={open}
                  aria-controls="collapseOne"
                >
                  <span className="job_des">
                    <img src={Bag} />{" "}
                    {capitalizeFirstLetter(
                      employmentType[jobsDetails?.employment_type] ?? ""
                    )}
                  </span>
                  <span className="job_des">
                    <img src={loacation_icon} /> {jobsDetails?.location}
                  </span>
                  <span className="job_des">
                    <img src={doc_icon} />
                    {capitalizeFirstLetter(
                      yearsOfExperience[jobsDetails?.experience] ?? ""
                    )}{" "}
                    Experience
                  </span>
                  <span className="job_des">
                    {renderCurrencyIcon(jobsDetails?.currency)}
                    {jobsDetails?.salary_min} - {jobsDetails?.salary_max}
                  </span>
                </div>

                <button
                  className={`accordion-button down_button ${
                    showDownButton ? "" : "d-none"
                  }`}
                  onClick={handleDownButtonClick}
                  aria-expanded={open}
                  aria-controls="collapseOne"
                >
                  <img src={Dw} alt="" />
                </button>

                <div
                  id="collapseOne"
                  className={`accordion-collapse collapse ${
                    open ? "show" : ""
                  }`}
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="job_details_main soclia_meid_main_sec_body_single">
                    <div
                      className="job_dm"
                      dangerouslySetInnerHTML={renderMarkdown(
                        jobsDetails?.description
                      )}
                    ></div>
                    <div>
                      <button
                        className="accordion-button up_button"
                        onClick={handleUpButtonClick}
                        aria-expanded={open}
                        aria-controls="collapseOne"
                      >
                        <img src={Up} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="mt-3">
          <div className="row  d-flex mb-3 align-items-baseline">
            <div className="col-8 d-flex align-items-baseline">
              <Searchbar
                inputValue={searchInput}
                setInputValue={setSearchInput}
                onSearchSubmit={handleSearchSubmit}
              />
              <StatusFilterApplicant
                onStatusChange={setSelectedStatus}
                selectedStatus={selectedStatus}
              />
              <Reset onReset={handleReset} />
            </div>
            <div className="col-4 d-flex align-items-baseline justify-content-end">
              <SortFilterApplicant
                onSortChange={handleSortChange}
                selectedValue={sortCriteria}
              />
            </div>
          </div>
        </div>
        <div>
          {isLoadingTable ? (
            <div className="loading-container">
              <ThreeCircles
                visible={true}
                height="40"
                width="40"
                color="#1865c1"
                ariaLabel="loading-indicator"
              />
            </div>
          ) : (
            <Table
              columns={columns}
              dataSource={jobApplicants}
              pagination={{
                pageSize: 5,
                total: jobsCount,
                current: currentPage,
                onChange: handlePageChange,
                showSizeChanger: false,
              }}
              tableLayout="fixed"
            />
          )}
        </div>
      </div>
      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="custom_modal_body">
          <StayConnected
            isClosing={isClosingJob}
            onConfirm={handleModalConfirm}
            onCancel={() => setIsModalOpen(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewInvitedJobs;

const StayConnected = ({ isClosing, onConfirm, onCancel }) => {
  const closingImageUrl = close_job; // Path to closing job image
  const reactivatingImageUrl = Question_Mark; // Path to reactivating job image

  return (
    <div className="stay_connect_sec">
      <div className="Qustion_mark">
        <img src={!isClosing ? closingImageUrl : reactivatingImageUrl} />
      </div>

      <p>
        {!isClosing
          ? "Are you sure you want to close this Job?"
          : "Are you sure you want to reactivate this Job?"}
      </p>
      <div className="button-container">
        <button className="not-confirm" onClick={onCancel}>
          No
        </button>
        <button
          className={!isClosing ? "confirm-closing" : "confirm"}
          onClick={onConfirm}
        >
          Yes
        </button>
      </div>
    </div>
  );
};

const StatusTag = ({ status }) => {
  let color = "default"; // default gray
  let text = status;

  switch (status) {
    case "Registered":
    case "UnRegistered":
      color = "yellow"; // Ant Design does not directly support "yellow", might need customization
      text = "Pending";
      break;
    case "Applied":
      color = "green"; // Ant Design supports "green" directly
      text = "Responded";
      break;
    default:
      color = "default";
      break;
  }

  // Customizing the style for yellow as Ant doesn't support it out-of-the-box
  const tagStyle =
    color === "yellow"
      ? {
          backgroundColor: "#FFD700",
          color: "black",
        }
      : {};

  return (
    <Tag color={color} style={tagStyle}>
      {text}
    </Tag>
  );
};
