import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import {
  dash_black,
  dash_blue,
  msg_black,
  msg_blue,
  my_jobs_black,
  my_jobs_blue,
  submenu,
  submenufill,
} from "../../../assets/images/images";
import { Navbar } from "../../../components/DashboardComponents/Navbar/Navbar";
import DashboardSideBar from "../DashboardSideBar/DashboardSideBar";
import ResposiveContainer from "./CommonRecruiterComponant/ResponsiveContainer";
import "./Dashboard.css";
import DashboardMain from "./DashboardMain/DashboardMain";
import JobDetails from "./JobDetails/JobDetails";
import MyCompany from "./MyCompany/MyCompany";
import MyJobs from "./MyJobs/MyJobs";
import ViewJobsData from "./MyJobs/ViewJobsData";
import Monitization from "./Monitization/MonitizationPage";
import PricingModal from "./Monitization/components/PricingModal";
import { isEmpty } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { usePricingModal } from "../../../contexts/PricingModalContext";
import { loadSubscriptions } from "../../../redux/actions/subscriptionActions";

const Dashboard = () => {
  const dispatch = useDispatch();
  const companyData = useSelector((state) => state.company.details);
  const { isPricingModalOpen, hidePricingModal } = usePricingModal();
  const tabList = [
    {
      key: "Dashboard",
      route: "",
      icon: dash_black,
      activeIcon: dash_blue,
      component: DashboardMain,
      active: true
    },
    {
      key: "My Jobs",
      route: "my-jobs/*",
      icon: my_jobs_black,
      activeIcon: my_jobs_blue,
      component: MyJobs,
      active: true,
    },
    // {
    //   key: "Messages",
    //   route: "messages",
    //   icon: app_black,
    //   activeIcon: app_blue,
    //   component: Messages,
    // },
    {
      key: "My Company",
      route: "my-company",
      icon: msg_black,
      activeIcon: msg_blue,
      component: MyCompany,
      active: true,
    },
    // {
    //   key: "Settings",
    //   route: "settings",
    //   icon: set_black,
    //   activeIcon: set_blue,
    //   component: Settings,
    // },
    {
      key: "Subscription",
      route: "subscription",
      icon: submenu,
      activeIcon: submenufill,
      component: Monitization,
      active: isEmpty(companyData) ? false : true,
    },
  ];

  useEffect(() => {
    dispatch(loadSubscriptions());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <section className="company_details_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="company_details_sec_new_inner">
                {/* Dashboard Left Bar */}
                <DashboardSideBar tabList={tabList} />
                
                <div className="comp_det_rigt_main tab-content">
                  <Routes>
                  {tabList.map((tab, index) =>
                      tab.active ? (
                        <Route
                          key={index}
                          path={tab.route}
                          element={<tab.component />}
                        />
                      ) : null
                    )}
                    <Route
                      path="my-jobs/:jobType/:jobId/view-jobs-data"
                      element={<ViewJobsData />}
                    />
                    <Route
                      path="my-jobs/:jobType/:jobId/edit"
                      element={<JobDetails />}
                    />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Pricing Modal start---------------------------------------- */}
      {!isEmpty(companyData) ? (
        <PricingModal show={isPricingModalOpen} onHide={hidePricingModal} />
      ) : (
        <></>
      )}
      {/* <div className="ResposiveContainer">
        <ResposiveContainer />
      </div> */}
    </>
  );
};

export default Dashboard;
