import { Dispatch } from "redux";
// @ts-ignore
import instance from "../../api/instance";
import {
  JobState,
  setJobError,
  setJobLoading,
  updateJobDetails,
  setJobList,
  setDraftsJobList,
  setClosedJobList,
  setRecentJobList,
  resetJobDraftState,
  setApplicantJobsList,
  setRecommendJobsList,
  setAppliedJobsList,
  setJobApplicantsList,
  setDraftJobsCount,
  setClosedJobsCount,
  setJobsCount,
  setTotalCount,
  setNextPage,
  setPreviousPage,
  setOptionsList,
  setCurrencies,
  setInvitedJobList,
  setInvitedJobsCount,
  setRequestedJobList,
  setRequestedJobsCount,
} from "../reducers/jobReducer";
// @ts-ignore
import { baseURL } from "../../api/instance";
import { JobDetails } from "../types/jobTypes";
import { toast } from "react-toastify";

export const createJob =
  (token: string, jobDetails: JobState, status: string, navigate: Function) =>
  async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));
    try {
      const jobDataWithStatus = { ...jobDetails, status };

      await instance.get("/job/job-data/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      const response = await instance.post(
        "/job/create-job/",
        jobDataWithStatus,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const job = {
          ...jobDetails,
          id: response.data.data.id,
          user: response.data.data.user,
          interview_time: response.data.data.interview_time,
        };
        dispatch(updateJobDetails(job));
        navigate("/dashboard");
        dispatch(resetJobDraftState());
        dispatch(setJobLoading(false));
      } else {
        dispatch(setJobLoading(false));
        throw new Error(`API responded with status: ${response.status}`);
      }
      dispatch(setJobLoading(false));
      return response.data;
    } catch (error: any) {
      dispatch(setJobError("Failed to create job details: " + error.message));
      dispatch(setJobLoading(false));
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const updateJob =
  (
    token: string,
    jobId: number,
    jobDetails: JobDetails,
    navigate: Function,
    status?: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));
    try {
      const jobDataWithStatus = { ...jobDetails, status };

      await instance.get("/job/job-data/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      const response = await instance.put(
        `/job/update-job/${jobId}/`,
        jobDataWithStatus,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const job = {
          ...jobDetails,
          id: response.data.data.id,
          user: response.data.data.user,
        };
        dispatch(updateJobDetails(job));

        navigate("/dashboard");

        dispatch(resetJobDraftState());
        dispatch(setJobLoading(false));
        return response;
      } else {
        dispatch(setJobLoading(false));
        throw new Error(`API responded with status: ${response.status}`);
      }
    } catch (error: any) {
      dispatch(setJobError("Failed to create job details: " + error.message));
      dispatch(setJobLoading(false));
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const fetchSingleJobDetails =
  (id: number, token?: string, excludeRelatedJobs?: boolean) => async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    try {
      const response = await instance.get(`/job/job-details/${id}/`, {
        headers: {
          Authorization: token ? `Token ${token}` : "",
        },
      });
      if (response.status === 200) {
        let jobData = response.data.data;

        // If excludeRelatedJobs is true, remove the related_jobs field from the jobData
        if (excludeRelatedJobs) {
          delete jobData.related_jobs;
        }

        dispatch(updateJobDetails(jobData));// updateJobDetails action to update Redux state with job details
        dispatch(setJobLoading(false));
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
    } catch (error: any) {
      dispatch(setJobError("Failed to fetch job details: " + error.message)); // setJobError action for error handling
      dispatch(setJobLoading(false));
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const checkJobStatus = (id: number) => async (dispatch: Dispatch) => {
  dispatch(setJobLoading(true));
  try {
    const response = await instance.get(`/job/check-job-status/${id}/`);

    if (response.status === 200) {
      dispatch(setJobLoading(false));
      return response.data.active;
    }
  } catch (error: any) {
    dispatch(setJobError("Failed to check job status: " + error.message)); // setJobError action for error handling
    dispatch(setJobLoading(false));
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const fetchJobsList =
  (
    token: string,
    search?: string,
    employment_id?: number,
    sort?: string,
    status?: number,
    currentPage?: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    const url = new URL(`${baseURL}job/search-jobs/`);
    const params = new URLSearchParams();

    if (search) params.append("search", search);
    if (employment_id != null)
      params.append("employment_id", employment_id.toString());
    if (sort) params.append("sort", sort);
    if (status != null) params.append("status", status.toString());
    params.append("page", currentPage?.toString() || "");

    url.search = params.toString();

    try {
      const response = await instance.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (response.status === 200) {
        switch (status) {
          case 1:
            dispatch(setDraftsJobList(response.data.data));
            dispatch(setDraftJobsCount(response.data.count));
            break;
          case 3:
            dispatch(setClosedJobList(response.data.data));
            dispatch(setClosedJobsCount(response.data.count));
            break;
          default:
            dispatch(setJobList(response.data.data));
            dispatch(setJobsCount(response.data.count));
            break;
        }
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
      dispatch(setJobLoading(false));
    } catch (error: any) {
      dispatch(setJobError("Failed to fetch jobs list: " + error.message)); // setJobError action for error handling
      dispatch(setJobLoading(false));
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const fetchRecentJobsList =
  (token: string) => async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    try {
      const response = await instance.get("/job/company/dashboard-job-lists/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (response.status === 200) {
        dispatch(setRecentJobList(response.data.data));
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
      dispatch(setJobLoading(false));
    } catch (error: any) {
      dispatch(setJobError("Failed to fetch jobs list: " + error.message));
      dispatch(setJobLoading(false));
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const fetchJobApplicants =
  (
    token: string,
    jobId: number,
    search?: string,
    sort?: string,
    status?: string,
    totalExperience?: string,
    noticePeriod?: string,
    address?: string,
    overallRating?: string,
    technicalSkillsRating?: string,
    problemSolvingRating?: string,
    communicationRating?: string,
    behaviouralRating?: string,
    minCurrentCtc?: number,
    maxCurrentCtc?: number,
    minExpectedCtc?: number,
    maxExpectedCtc?: number,
    currentPage?: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    const url = new URL(`${baseURL}job/company/get-job-applications/${jobId}/`);
    const params = new URLSearchParams();

    // Appending parameters
    params.append("search", search ?? "");
    params.append("sort", sort ?? "");
    params.append("status", status ?? "");
    params.append("total_experience", totalExperience ?? "");
    params.append("notice_period", noticePeriod ?? "");
    params.append("address", address ?? "");
    params.append("overall_rating", overallRating ?? "");

    params.append("technical_skills_rating", technicalSkillsRating ?? "");

    params.append("problem_solving_rating", problemSolvingRating ?? "");

    params.append("communication_rating", communicationRating ?? "");

    params.append("behavioural_rating", behaviouralRating ?? "");

    params.append("min_current_ctc", minCurrentCtc?.toString() ?? "");

    params.append("max_current_ctc", maxCurrentCtc?.toString() ?? "");

    params.append("min_expected_ctc", minExpectedCtc?.toString() ?? "");
    params.append("max_expected_ctc", maxExpectedCtc?.toString() ?? "");
    params.append("page", currentPage?.toString() || "");

    url.search = params.toString(); // Complete URL encoding

    try {
      const response = await instance.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (response.status === 200) {
        dispatch(setOptionsList(response.data.options));
        dispatch(setJobApplicantsList(response.data.data));
        dispatch(setJobsCount(response.data.count));
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
    } catch (error: any) {
      console.error("Error fetching job applicants:", error);
      dispatch(setJobError("Failed to fetch job applicants: " + error.message));
      dispatch(setOptionsList({}));
      dispatch(setJobApplicantsList([]));
      dispatch(setJobsCount(0));
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const fetchJobsListJobSeeker =
  (
    token?: string,
    status?: number,
    search?: string,
    location?: string,
    sort?: string,
    work_mode?: number,
    max_salary?: number,
    min_salary?: number,
    experience_id?: number,
    currentPage?: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    const url = new URL(`${baseURL}job/jobs-listing-for-jobseeker/`);
    const params = new URLSearchParams();

    if (search) params.append("search", search);
    if (status != null) params.append("status", status.toString());
    if (location != null) params.append("location", location.toString());
    if (sort) params.append("sort", sort);
    if (work_mode != null) params.append("work_mode", work_mode.toString());
    if (max_salary != null) params.append("max_salary", max_salary.toString());
    if (min_salary != null) params.append("min_salary", min_salary.toString());
    if (experience_id != null)
      params.append("experience_id", experience_id.toString());
    if (currentPage != null) params.append("page", currentPage.toString());

    url.search = params.toString();

    try {
      const response = await instance.get(url, {
        headers: {
          Authorization: token ? `Token ${token}` : "",
        },
      });

      if (response.status === 200) {
        switch (status) {
          case 1:
            dispatch(setApplicantJobsList(response.data.data));
            dispatch(setTotalCount(response.data.count));
            dispatch(setNextPage(response.data.next));
            dispatch(setPreviousPage(response.data.previous));
            break;
          case 2:
            dispatch(setAppliedJobsList(response.data.data));
            dispatch(setTotalCount(response.data.count));
            dispatch(setNextPage(response.data.next));
            dispatch(setPreviousPage(response.data.previous));
            break;
          case 3:
            dispatch(setRecommendJobsList(response.data.data));
            dispatch(setTotalCount(response.data.count));
            dispatch(setNextPage(response.data.next));
            dispatch(setPreviousPage(response.data.previous));
            break;
          case 4:
            dispatch(setInvitedJobList(response.data.data));
            dispatch(setInvitedJobsCount(response.data.count));
            break;
          case 5:
            dispatch(setRequestedJobList(response.data.data));
            dispatch(setRequestedJobsCount(response.data.count));
            break;
          default:
            dispatch(setApplicantJobsList(response.data.data));
            dispatch(setTotalCount(response.data.count));
            dispatch(setNextPage(response.data.next));
            dispatch(setPreviousPage(response.data.previous));
            break;
        }
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
      dispatch(setJobLoading(false));
    } catch (error: any) {
      dispatch(setJobError("Failed to fetch jobs list: " + error.message)); // setJobError action for error handling
      dispatch(setJobLoading(false));
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const applyJob =
  (token: string, jobId: number) => async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));
    try {
      const response = await instance.post(
        `/jobseekers/apply-job/`,
        { job_id: jobId },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 201) {
        const interviewId = response.data.data; // Adjust according to your actual API response structure
        // Dispatch any necessary Redux actions here
        dispatch(setJobLoading(false));
        return interviewId;
      }
    } catch (error: any) {
      dispatch(setJobLoading(false));

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Use the message from the API response
        throw new Error(error.response.data.message);
      } else {
        // Fallback error message
        throw new Error("Failed to apply for the job.");
      }
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const requestInterview =
  (token: string, jobId: number) => async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));
    try {
      const response = await instance.post(
        `/jobseekers/request-interview/`,
        { job_id: jobId },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 201) {
        const interviewRequestData = response.data.data;
        dispatch(setJobLoading(false));
        toast.success("Interview request sent successfully.");
        return interviewRequestData;
      }
    } catch (error: any) {
      dispatch(setJobLoading(false));

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
        throw new Error(error.response.data.message);
      } else {
        toast.error("Failed to request an interview.");
        throw new Error("Failed to request an interview.");
      }
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const closeJob =
  (token: string, jobId: number, isActive: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));
    const status = isActive ? "2" : "3";
    try {
      const response = await instance.put(
        `/job/update-job/${jobId}/`,
        { status },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
      dispatch(setJobLoading(false));
    } catch (error: any) {
      dispatch(setJobError("Failed to fetch job applicants: " + error.message));
      dispatch(setJobLoading(false));
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const updateJobApplicationStatus =
  (token: string, jobApplicationId: number, status: number) =>
  async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));
    try {
      const response = await instance.put(
        `/jobseekers/update-job-application/${jobApplicationId}/`,
        { status: status },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
      dispatch(setJobLoading(false));
    } catch (error: any) {
      dispatch(setJobError("Failed to fetch job applicants: " + error.message));
      dispatch(setJobLoading(false));
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const reportJob =
  (token: string, message: string, jobId: number) =>
  async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));
    try {
      const response = await instance.post(
        `/job/report-job/${jobId}/`,
        { message: message },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response) {
      }
    } catch (error: any) {
      dispatch(setJobError("Failed to report job: " + error.message));
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const inviteCandidates =
  (token: string, jobId: number, emails: string[]) =>
  async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    try {
      const response = await instance.post(
        `/job/job-invite/${jobId}/`,
        {
          emails: emails,
          status: 1,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setJobLoading(false));
        return response.data;
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
    } catch (error: any) {
      console.error("Error inviting candidates:", error);
      dispatch(setJobError("Failed to invite candidates: " + error.message));
      dispatch(setJobLoading(false));
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const fetchCurrencies =
  (token: string) => async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    try {
      const response = await instance.get("/job/job-data/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (response.status === 200) {
        dispatch(setCurrencies(response.data.data.currency));
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
      dispatch(setJobLoading(false));
    } catch (error: any) {
      dispatch(setJobError("Failed to fetch jobs list: " + error.message));
      dispatch(setJobLoading(false));
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const fetchInvitedJobDetails =
  (id: number, token?: string) => async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    try {
      const response = await instance.get(`/job/get-job-invitations/${id}/`, {
        headers: {
          Authorization: token ? `Token ${token}` : "",
        },
      });

      if (response.status === 200) {
        dispatch(updateJobDetails(response.data.data));
        // updateJobDetails action to update Redux state with job details
        dispatch(setJobLoading(false));
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
    } catch (error: any) {
      dispatch(setJobError("Failed to fetch job details: " + error.message)); // setJobError action for error handling
      dispatch(setJobLoading(false));
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const fetchInvitedApplicants =
  (token: string, jobId: number, search?: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    const url = new URL(`${baseURL}job/get-job-invitations/${jobId}/`);
    const params = new URLSearchParams();

    if (search) params.append("search", search);

    url.search = params.toString();

    try {
      const response = await instance.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (response.status === 200) {
        dispatch(setJobApplicantsList(response?.data?.data));
        dispatch(setJobsCount(response?.data?.count));
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
      dispatch(setJobLoading(false));
    } catch (error: any) {
      dispatch(setJobError("Failed to fetch job applicants: " + error.message));
      dispatch(setJobLoading(false));
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const fetchRequestedApplicants =
  (token: string, jobId: number, search?: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    const url = new URL(
      `${baseURL}job/company/requested-applicants-list/${jobId}/`
    );
    const params = new URLSearchParams();

    if (search) params.append("search", search);

    url.search = params.toString();

    try {
      const response = await instance.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (response.status === 200) {
        dispatch(setJobApplicantsList(response?.data?.data));
        dispatch(setJobsCount(response?.data?.count));
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
      dispatch(setJobLoading(false));
    } catch (error: any) {
      dispatch(setJobError("Failed to fetch job applicants: " + error.message));
      dispatch(setJobLoading(false));
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const RequestInterview =
  (token: string, jobId: number) => async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));
    try {
      await instance.post(
        `/jobseekers/request-interview/`,
        { job_id: jobId },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
    } catch (error: any) {
      dispatch(setJobLoading(false));

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Failed to apply for the job.");
      }
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const fetchDemoInterviewDetails = async (
  token: string,
  interviewId: number
) => {
  try {
    const response = await instance.get(
      `/demo/get-demo-interview-details/${interviewId}/`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );

    if (response.status === 200) {
      console.log(response?.data?.data);
      return response?.data?.data;
    } else {
      throw new Error(`API responded with status: ${response.status}`);
    }
  } catch (error) {
    throw new Error("Failed to fetch interview details");
  }
};
