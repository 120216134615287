import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Select, InputNumber, Tooltip, Button } from "antd";
import ChipsInputComponent from "../../../../components/CommonComponents/ChipsInput";
import { useDispatch, useSelector } from "react-redux";
import { updateJobDetails } from "../../../../redux/reducers/jobReducer";
import "./JobDetails.css";
import useJobSkillsGenerator from "../../../../hooks/useJobSkillsGenerator";
import useResponsibilitiesGenerator from "../../../../hooks/useResponsiblitiesGenerator";
import { toast } from "react-toastify";
import { InfoCircleOutlined } from "@ant-design/icons";

const JobDetailsForm1 = ({ form }) => {
  const dispatch = useDispatch();
  const [formChanged, setFormChanged] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [vacanciesLimit, setVacanciesLimit] = useState(false);
  const jobDetails = useSelector((state) => state?.job?.details);
  const inputRef = useRef(null);
  const computeResponsibilities = () => {
    let responsibilityArray = [];

    if (typeof jobDetails?.responsibilities === "string") {
      responsibilityArray = jobDetails?.responsibilities.split(",");
    } else {
      responsibilityArray = Object.values(jobDetails?.responsibilities || {});
    }

    return responsibilityArray;
  };

  const computeSkills = () => {
    let skillsArray = [];

    if (typeof jobDetails?.skills === "string") {
      skillsArray = jobDetails?.skills.split(",");
    } else {
      skillsArray = Object.entries(jobDetails?.skills || {}).map(
        ([key, value]) => value
      );
    }

    return skillsArray;
  };

  const [selectedSkills, setSelectedSkills] = useState(computeSkills() ?? []);
  const [selectedResponsibilities, setSelectedResponsibilities] = useState(
    computeResponsibilities() ?? []
  );

  const availableSkills = useJobSkillsGenerator(
    jobDetails?.title,
    selectedSkills.join(",")
  );

  const availableResponsibilities = useResponsibilitiesGenerator(
    jobDetails?.title,
    jobDetails?.experience,
    selectedResponsibilities?.join(",")
  );

  useEffect(() => {
    if (jobDetails) {
      form.setFieldsValue(jobDetails);
    }
  }, [form, jobDetails]);

  useEffect(() => {
    setSelectedSkills(computeSkills());
  }, [jobDetails]);

  useEffect(() => {
    setSelectedResponsibilities(computeResponsibilities());
  }, [jobDetails]);

  const handleFormKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleSkillsChange = (newChips) => {
    setSelectedSkills(newChips);
    dispatch(updateJobDetails({ ...jobDetails, skills: newChips.join(",") }));
    if (jobDetails?.id && !isToastOpen) {
      toast.dismiss();
      toast.warning(
        "Click 'Generate with AI' to apply changes to the job description.",
        {
          autoClose: false,
          onOpen: () => setIsToastOpen(true),
          onClose: () => {
            setIsToastOpen(false);
            setFormChanged(false);
          },
        }
      );
    }
  };
  const handleResponsibilitiesChange = (newChips) => {
    setSelectedResponsibilities(newChips);
    dispatch(
      updateJobDetails({ ...jobDetails, responsibilities: newChips.join(",") })
    );
    if (jobDetails?.id && !isToastOpen) {
      toast.dismiss();
      toast.warning(
        "Click 'Generate with AI' to apply changes to the job description.",
        {
          autoClose: false,
          onOpen: () => setIsToastOpen(true),
          onClose: () => {
            setIsToastOpen(false);
            setFormChanged(false);
          },
        }
      );
    }
  };

  const onValuesChange = (_, allValues) => {
    dispatch(updateJobDetails(allValues));
    setFormChanged(true);
  };

  const addSuggestedSkill = (skill) => {
    if (!selectedSkills.includes(skill)) {
      const updatedSkills = [...selectedSkills, skill];
      handleSkillsChange(updatedSkills);
    }
  };

  const removeSelectedSkill = (skill) => {
    // const updatedSkills = selectedSkills.filter((s) => s !== skill);
    // handleSkillsChange(updatedSkills);
  };

  const addSuggestedResponsibility = (responsibility) => {
    if (!selectedResponsibilities.includes(responsibility)) {
      const updatedResponsibilities = [
        ...selectedResponsibilities,
        responsibility,
      ];
      handleResponsibilitiesChange(updatedResponsibilities);
    }
  };

  const removeSelectedResponsibility = (responsibility) => {
    // const updatedResponsibilities = selectedResponsibilities.filter(
    //   (s) => s !== responsibility
    // );
    // handleResponsibilitiesChange(updatedResponsibilities);
    if (jobDetails?.id && !isToastOpen) {
      toast.dismiss();
      toast.warning(
        "Click 'Generate with AI' to apply changes to the job description.",
        {
          autoClose: false,
          onOpen: () => setIsToastOpen(true),
          onClose: () => {
            setIsToastOpen(false);
            setFormChanged(false);
          },
        }
      );
    }
  };
  const handleVacancyBlur = () => {
    form
      .validateFields(["no_of_vacancies"])
      .then(() => {
        // Validation successful, blur the input
        if (inputRef.current) {
          inputRef.current.blur();
        }
      })
      .catch((errorInfo) => {
        // Validation failed
        console.error("Validation failed:", errorInfo);
      });
  };

  useEffect(() => {
    if (formChanged && jobDetails?.id && !isToastOpen) {
      toast.dismiss(); // Dismiss all existing toasts before showing a new one
      toast.warning(
        "Click 'Generate with AI' to apply changes to the job description.",
        {
          autoClose: false,
          onOpen: () => setIsToastOpen(true), // Set isToastOpen to true when the toast opens
          onClose: () => {
            setIsToastOpen(false); // Reset isToastOpen when the toast closes
            setFormChanged(false);
          },
        }
      );
    }
  }, [formChanged, jobDetails?.id]);

  return (
    <>
      <section className="form_sec_new_inner_first">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="form_sec_new_inner_first_innwer">
              <Form
                onKeyDown={handleFormKeyDown}
                form={form}
                name="jobDetailsForm1"
                onValuesChange={onValuesChange}
                className="job_details_from"
              >
                <Form.Item
                  name="title"
                  label="Job Title"
                  rules={[
                    { required: true, message: "Please input the job title!" },
                  ]}
                >
                  <Input placeholder="Job Title" id="title" />
                </Form.Item>
                <Form.Item
                  name="skills"
                  label={
                    <span>
                      <Tooltip title="Limit: 75 character per chip. Sentences will split at commas.">
                        <Button type="text" icon={<InfoCircleOutlined />} />
                      </Tooltip>
                      Skills Required{" "}
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please add at least one skill",
                    },
                  ]}
                >
                  <ChipsInputComponent
                    key="skills"
                    placeholder="Type a keyword and press enter"
                    value={selectedSkills}
                    onChipsChange={handleSkillsChange}
                    onChipRemoved={removeSelectedSkill}
                    id={"skills"}
                    formatCase="camelCase"
                  />
                </Form.Item>
                <div className="suggestion_sec_new_main">
                  <div className="suggestion_sec_new">
                    <ul className="list_item_sec">
                      {availableSkills?.map((skill, index) => (
                        <li
                          style={{ cursor: "pointer" }}
                          key={index}
                          onClick={() => addSuggestedSkill(skill)}
                        >
                          {skill}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6 text-start">
                    <Form.Item
                      name="employment_type"
                      label="Employment type"
                      rules={[
                        {
                          required: true,
                          message: "Please select an employment type",
                        },
                      ]}
                    >
                      <Select
                        id="employment_type"
                        placeholder="Select employment type"
                        className="EmployeeType"
                      >
                        <Select.Option value={1}>Full-time</Select.Option>
                        <Select.Option value={2}>Part-time</Select.Option>
                        <Select.Option value={3}>Temporary</Select.Option>
                        <Select.Option value={4}>Contract</Select.Option>
                        <Select.Option value={5}>Freelancer</Select.Option>
                        <Select.Option value={6}>Internship</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-6 text-start">
                    <Form.Item
                      name="seniority_level"
                      label="Seniority Level"
                      rules={[
                        {
                          required: true,
                          message: "Please select a seniority level",
                        },
                      ]}
                    >
                      <Select
                        id="seniority"
                        placeholder="Select Seniority type"
                        className="EmployeeType"
                      >
                        <Select.Option value={1}>Entry-Level</Select.Option>
                        <Select.Option value={2}>Mid-Level</Select.Option>
                        <Select.Option value={3}>Senior-Level</Select.Option>
                        <Select.Option value={4}>Executive</Select.Option>
                        <Select.Option value={5}>Director-Level</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6 text-start">
                    <Form.Item
                      name="experience"
                      label="Years of Experience"
                      rules={[
                        {
                          required: true,
                          message: "Please select Years of Experience",
                        },
                      ]}
                    >
                      {/* <Input
                        min={1}
                        style={{ width: "100%" }}
                        placeholder="0"
                      /> */}
                      <Select
                        id="experience"
                        placeholder="Select experience"
                        className="experience"
                      >
                        <Select.Option value={1}>0-1</Select.Option>
                        <Select.Option value={2}>1-2</Select.Option>
                        <Select.Option value={3}>2-3</Select.Option>
                        <Select.Option value={4}>3-4</Select.Option>
                        <Select.Option value={5}>4-5</Select.Option>
                        <Select.Option value={6}>5+ years</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-6 text-start">
                    <Form.Item
                      name="no_of_vacancies"
                      label="Vacancies:"
                      rules={[
                        {
                          required: true,
                          message: "Please enter valid digit in vacancy",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value === 0 || value === "0") {
                              return Promise.reject(
                                "Please enter a valid digit"
                              );
                            }
                            if (value) {
                              if (
                                isNaN(value) ||
                                value < 0 ||
                                !/^\d+$/.test(value)
                              ) {
                                return Promise.reject(
                                  "Please enter a valid digit"
                                );
                              }
                            }
                            if (value && value.toString().length > 5) {
                              return Promise.reject(
                                "Only 5 digits are allowed."
                              );
                              // setVacanciesLimit(true)
                              // value = parseInt(value.toString().slice(0, 5), 10);
                            }
                            // else {
                            //   // setVacanciesLimit(false);
                            // }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        id="vacancies"
                        ref={inputRef}
                        min={0}
                        style={{ width: "100%" }}
                        placeholder="Enter Vacancies"
                        type="text"
                        onBlur={handleVacancyBlur}
                        maxLength={6}
                      />
                      {/* <InputNumber
                        id="vacancies"
                        ref={inputRef}
                        min={0}
                        style={{ width: "100%" }}
                        placeholder="Enter Vacancies"
                        type="text"
                        onBlur={handleVacancyBlur}
                      /> */}
                    </Form.Item>{" "}
                    {/* style={{ color: 'red', paddingLeft: '5px', display: vacanciesLimit ? 'none' : 'none' }}  */}
                    <p
                      style={{
                        color: "red",
                        display: vacanciesLimit ? "block" : "none",
                      }}
                    >
                      Only 5 digits are allowed.
                    </p>
                  </div>
                </div>
                <Form.Item
                  name="responsibilities"
                  label={
                    <span>
                      <Tooltip title="Limit: 75 character per chip. Sentences will split at commas.">
                        <Button type="text" icon={<InfoCircleOutlined />} />
                      </Tooltip>
                      Key Duties{" "}
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please add at least one responsiblity",
                    },
                  ]}
                >
                  <ChipsInputComponent
                    key="responsibilities"
                    placeholder="Type a keyword and press enter"
                    value={selectedResponsibilities}
                    onChipsChange={handleResponsibilitiesChange}
                    onChipRemoved={removeSelectedResponsibility}
                    id={"responsibilities"}
                    formatCase="sentenceCase"
                  />
                </Form.Item>
                <div style={{ paddingBottom: "30px" }}>
                  <div className="suggestion_sec_new">
                    <ul className="list_item_sec">
                      {availableResponsibilities?.map(
                        (responsibility, index) => (
                          <li
                            style={{ cursor: "pointer" }}
                            key={index}
                            onClick={() =>
                              addSuggestedResponsibility(responsibility)
                            }
                          >
                            {responsibility}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobDetailsForm1;
