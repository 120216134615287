import { DownOutlined } from "@ant-design/icons";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  Button,
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
} from "antd";
import { marked } from "marked";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ThreeCircles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Bag,
  Dw,
  FilterAi,
  Question_Mark,
  Resume_img,
  Up,
  alert_terminated,
  clock_icon,
  close_job,
  doc_icon,
  download_icon,
  draft_line,
  loacation_icon,
  resumeicon,
  transcript_img,
} from "../../../../assets/images/images";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";
import Invites from "../../../../components/CommonComponents/Invites/Invites";
import {
  closeJob,
  fetchJobApplicants,
  fetchSingleJobDetails,
  inviteCandidates,
} from "../../../../redux/actions/jobActions";
import { employmentType, yearsOfExperience } from "../../../../utils/constants";
import { assesmentPdf } from "../../../../utils/pdfFiles/assesmentPdf";
import { generatePdf } from "../../../../utils/pdfFiles/transcriptPdf";
import useFilters from "../../../../utils/useFilters";
import {
  calculateTimeSpan,
  formatDateTimeToLocalWithOffset,
  formatDurationTime,
  renderCurrencyIcon,
  transformOptions,
} from "../../../../utils/utils";
import SkeletonLoader from "../CommonRecruiterComponant/SkeletonLoader";
import "../JobDetails/JobDetails.css";
import {
  Reset,
  Searchbar,
  SortFilterApplicant,
  StatusFilterApplicant,
} from "./MyJobs";
import "./ViewJobsData.css";
import ChangeStatusTag from "./components/ChangeStatusTag";

const getSuffix = (filterName, filters, maxCount) => (
  <>
    <span>
      {filters[filterName]?.length || 0} / {maxCount}
    </span>
    <DownOutlined />
  </>
);

const ViewJobsData = () => {
  const MAX_COUNT = 1;
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSwitchChecked, setIsSwitchChecked] = useState();
  const [isTranscriptModalOpen, setIsTranscriptModalOpen] = useState(false);
  const [isClosingJob, setIsClosingJob] = useState(false);
  const [selectedTranscript, setSelectedTranscript] = useState(null);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [showreport, setshowreport] = useState(false);
  const [sortCriteria, setSortCriteria] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatus, setSelectedStatus] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [invitedItems, setInvitedItems] = useState([]);
  const [showDownButton, setShowDownButton] = useState(true);
  const [drawer, setDrawer] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [showInviteButton, setShowInviteButton] = useState(true);
  const [loadingPdf, setLoadingPdf] = useState(false);
  /////////////////////////////Filters///////////////////////////////
  const initialFilters = {
    experience: [],
    noticePeriod: [],
    status: [],
    location: [],
  };
  const [filters, handleFilterChange] = useFilters(initialFilters, MAX_COUNT);
  const [technicalSkillsRating, setTechnicalSkillsRating] = useState(null);
  const [problemSolvingRating, setProblemSolvingRating] = useState(null);
  const [communicationRating, setCommunicationRating] = useState(null);
  const [behavioralSkillsRating, setBehavioralSkillsRating] = useState(null);
  const [overallRating, setOverAllRating] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const [minValue, setMinValue] = useState(null);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [activeTab, setActiveTab] = useState("1");

  ////////////////////////////////useSelectors///////////////////////////////////////////
  const jobApplicants = useSelector((state) => state?.job?.jobApplicantsList);
  const jobsDetails = useSelector((state) => state.job?.details);
  const jobsCount = useSelector((state) => state.job.jobsCount);
  const token = useSelector((state) => state.auth.token);
  const option = useSelector((state) => state.job.options);
  // const applicant =jobApplicants && jobApplicants.length > 0 ? jobApplicants[1] : null;

  ////////////////////////////Navigation events////////////////////////////////////////////////////////
  const handleBackButtonClick = () => {
    const path = window.location.pathname;

    // Determine the navigation path based on the presence of 'closedJobs' or 'myJobs'
    if (path.includes("closedJobs")) {
      navigate("/dashboard/my-jobs/closedJobs", { replace: true });
    } else if (path.includes("myJobs")) {
      navigate("/dashboard/my-jobs/myJobs", { replace: true });
    } else {
      // Fallback or default navigation
      navigate("/dashboard", { replace: true });
    }
  };

  const handleEditJob = () => {
    navigate(`/dashboard/my-jobs/jobEdit/${jobId}/edit`);
  };

  ////////////////////////////Toggle functionality events/////////////////////////////////////////////////////////
  const toggleAccordion = () => {
    setOpen(!open);
  };

  const showDrawer = () => {
    setDrawer(true);
  };
  const onClose = () => {
    setDrawer(false);
    setSearchInput("");
    setSortCriteria("All");
    setSelectedStatus("All");
    // Resetting other filters to their initial states
    handleFilterChange("experience")([]);
    handleFilterChange("noticePeriod")([]);
    handleFilterChange("status")([]);
    handleFilterChange("location")([]);
    setTechnicalSkillsRating(null);
    setProblemSolvingRating(null);
    setCommunicationRating(null);
    setBehavioralSkillsRating(null);
    setOverAllRating(null);
    setMaxValue(null);
    setMinValue(null);
    // Resetting any other state that acts as a filter
    // Resetting pagination to the first page if necessary
    setCurrentPage(1);
    // Add any additional states that need to be reset
    triggerSearch();
  };

  const handleTranscriptModalCancel = () => {
    setIsTranscriptModalOpen(false);
  };
  const handleinviteJob = () => {
    setshowreport(true);
  };
  const HideMod = () => {
    setshowreport(false);
  };
  const handleClosereport = () => {};
  const handleSwitchChange = () => {
    setIsModalOpen(true);
    setIsClosingJob(isSwitchChecked);
  };
  const handleDownButtonClick = () => {
    toggleAccordion();
    setShowDownButton(false);
  };
  const handleUpButtonClick = () => {
    toggleAccordion();
    setShowDownButton(true);
  };

  const handleModalConfirm = async () => {
    const newStatus = isSwitchChecked ? "Published" : "Closed";
    await dispatch(closeJob(token, jobId, newStatus === "Published"))
      .then(async () => {
        setIsSwitchChecked(!isSwitchChecked);
        setShowInviteButton(newStatus !== "Closed"); // Update based on new status
        setIsModalOpen(false);
        await dispatch(fetchSingleJobDetails(jobId, token));
        toast.success(`Job has been successfully ${newStatus.toLowerCase()}.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.error("Failed to update job status:", error);
        // Display an error toast message
        toast.error("Failed to update job status. Please try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    setShowInviteButton(!isSwitchChecked);
  }, [isSwitchChecked]);

  ///////////////////////////Text rendering events//////////////////////////////////////////////////////
  const capitalizeFirstLetter = (string) => {
    if (typeof string !== "string" || !string) {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getResumeFileExtension = (fileName) => {
    const ext = fileName?.split(".").pop().toLowerCase();
    switch (ext) {
      case "doc":
      case "docx":
        return "docx";
      case "pdf":
        return "pdf";
      case "png":
        return "png";
      case "jpg":
      case "jpeg":
        return "jpg";
      default:
        return ext;
    }
  };

  const renderMarkdown = (description) => {
    if (description) {
      return { __html: marked(description) };
    }
    return { __html: "" };
  };

  ///////////////////////////////////////filters and pagination///////////////////////////////////////////////
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchSubmit = (searchValue) => {
    setSearchInput(searchValue);
  };
  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
  };
  const handleReset = () => {
    setSearchInput("");
    setSortCriteria("All");
    setSelectedStatus("All");
  };
  const handleItemsChange = (items) => {
    setInvitedItems(items);
  };
  const handleViewTranscriptAndAssessment = (record) => {
    setIsTranscriptModalOpen(true);
    setSelectedTranscript(record.transcript);
    setSelectedAssessment(record.assessment);
    setSelectedApplicant(record);
  };

  const statusValue = selectedStatus === "All" ? undefined : selectedStatus;

  const triggerSearch = async () => {
    setIsLoadingTable(true); // Start loading
    await Promise.all([
      dispatch(fetchSingleJobDetails(jobId, token)),
      dispatch(
        fetchJobApplicants(
          token,
          jobId,
          searchInput.trim() || undefined, // Ensure empty strings are sent as undefined
          sortCriteria || undefined,
          statusValue,
          filters.experience.length > 0
            ? filters.experience.join(",")
            : undefined,
          filters.noticePeriod.length > 0
            ? filters.noticePeriod.join(",")
            : undefined,
          filters.location.length > 0 ? filters.location.join(",") : undefined,
          overallRating,
          technicalSkillsRating,
          problemSolvingRating,
          communicationRating,
          behavioralSkillsRating,
          minValue,
          maxValue,
          "",
          "",
          currentPage
        )
      ),
    ])
      .then(() => {
        setIsLoadingTable(false); // Stop loading when both requests are complete
      })
      .catch(() => {
        setIsLoadingTable(false); // Ensure loading is stopped even if there is an error
        // Handle error, e.g., set an error state, show a message, etc.
      });
  };

  const onApply = () => {
    triggerSearch();
    setDrawer(false);
  };
  const options = [];
  for (let i = 6; i <= 10; i++) {
    options.push({
      value: i.toString(10),
      label: i.toString(10) + "+",
    });
  }
  const handleReportSubmit = async () => {
    if (token && jobId && invitedItems.length > 0) {
      try {
        dispatch(inviteCandidates(token, jobId, invitedItems));
        setInvitedItems([]);
        toast.success("Invites sent successfully!");
        setshowreport(false);
      } catch (error) {
        console.error("Failed to send invites:", error);
        toast.error("Failed to send invites. Please try again.");
      }
    } else {
      toast.info("No candidates selected for invitation.");
    }
  };

  const handleDownloadPdf = async () => {
    if (selectedTranscript && selectedApplicant) {
      setLoadingPdf(true); // Set loading state to true before generating PDF
      await generatePdf(selectedTranscript, selectedApplicant); // Wait for the PDF generation to complete
      setLoadingPdf(false); // Set loading state to false after generating PDF
    } else {
      console.error("Selected transcript or applicant is undefined");
    }
  };

  const handleDownloadAssessmentPdf = async (
    selectedAssessment,
    selectedApplicant
  ) => {
    if (selectedAssessment && selectedApplicant) {
      setLoadingPdf(true); // Set loading state to true before generating PDF
      await assesmentPdf(selectedAssessment, selectedApplicant); // Wait for the PDF generation to complete
      setLoadingPdf(false); // Set loading state to false after generating PDF
    } else {
      console.error("Selected assessment or applicant is undefined");
    }
  };

  ///////////////////////////////////////Useffects////////////////////////////////////////////////
  useEffect(() => {
    if (jobsDetails?.status === "Closed") {
      setIsSwitchChecked(true);
      setShowInviteButton(false); // Ensure invite button is hidden when job is closed
    } else if (jobsDetails?.status === "Published") {
      setIsSwitchChecked(false);
      setShowInviteButton(true); // Show invite button only if job is published
    }
  }, [jobsDetails]);

  useEffect(() => {
    if (token && jobId) {
      triggerSearch();
    }
  }, [
    dispatch,
    jobId,
    token,
    searchInput,
    sortCriteria,
    selectedStatus,
    currentPage,
  ]);
  
  useEffect(() => {
    const contentContainers = document.querySelectorAll(
      ".Transcript_main_action"
    );
    contentContainers.forEach((container) => {
      if (container) {
        container.scrollTop = 0;
      }
    });
  }, [activeTab]);

  const columns = [
    {
      title: "Name",
      dataIndex: "user_full_name",
      key: "user_full_name",
      render: (text) => <div data-th="Name">{text || "-"}</div>,
    },
    {
      title: " Resignation Notice",
      dataIndex: "notice_period",
      key: "notice_period",
      render: (text) => <div data-th="Resignation Notice">{text || "-"}</div>,
    },
    {
      title: "Current Company",
      dataIndex: "latest_employment",
      key: "latest_employment",
      render: (text) => <div data-th="Current Company">{text || "-"}</div>,
    },
    {
      title: "Annual Compensation",
      dataIndex: "current_ctc",
      key: "current_ctc",
      render: (text) => <div data-th="Annual Compensation">{text || "-"}</div>,
    },
    {
      title: "Location",
      dataIndex: "address",
      key: "address",
      render: (text) => <div data-th="Location">{text || "-"}</div>,
    },
    {
      title: "Resume",
      dataIndex: "resume",
      key: "resume",
      render: (resume, record) =>
        record?.status === "Fit-For-Job" ? (
          <div data-th="Resume">N/A</div>
        ) : (
          <div data-th="Resume">
            <img
              src={Resume_img}
              alt="Download Resume"
              className="download_resume_icon"
              onClick={() => window.open(resume, "_blank")}
              style={{ cursor: "pointer" }}
            />
          </div>
        ),
    },
    {
      title: "Result",
      dataIndex: "Transcript",
      key: "Transcript",
      render: (_, record) =>
        record?.transcript === null ? (
          <div data-th="Result">N/A</div>
        ) : (
          <div data-th="Result">
            <img
              src={transcript_img} // Replace 'transcript_icon.png' with the path to your transcript icon image
              alt="View Transcript"
              className="transcript_icon"
              onClick={() => handleViewTranscriptAndAssessment(record)}
              style={{ cursor: "pointer" }} // Set cursor to pointer to indicate clickability
            />
          </div>
        ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text, record) => (
        <div data-th="Status">
          <ChangeStatusTag
            initialStatus={record?.status}
            applicationId={record?.id}
            token={token}
          />
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="view_jobs_min_sec_data">
        <DashboardHeader title="" backButtonLink={handleBackButtonClick} />
        <div className="row new_comp_sec_data_sec">
          <section className="company_details_section ">
            <div className="accordion" id="company_main_container">
              <div className="accordion-item">
                <div className="company_detail_main">
                  <div className="company_logo">
                    <img src={jobsDetails?.company_profile_pic} alt="" />
                  </div>
                  <div className="d-flex baseline_align">
                    <div className="me-3">
                      <span className="switch_lable_head">
                        <Form.Item label="Close Job" valuePropName="checked">
                          <Switch
                            checked={isSwitchChecked}
                            onChange={handleSwitchChange}
                          />
                        </Form.Item>
                      </span>
                    </div>
                    {jobsDetails?.status !== "Closed" && (
                      <div className="my_comp_dashboard_left_edit">
                        <button
                          type="button"
                          onClick={handleEditJob}
                          className="custom-edit-button-class"
                        >
                          <i className="fas fa-pencil-alt"></i> Edit
                        </button>
                      </div>
                    )}
                    {showInviteButton &&
                      jobsDetails?.status !== "Closed" &&
                      jobsDetails?.status !== "Inactive" && (
                        <div className="my_comp_dashboard_left_edit invite_btn_icon">
                          <button
                            type="button"
                            onClick={handleinviteJob}
                            className="custom-edit-button-class"
                          >
                            Invite
                          </button>
                        </div>
                      )}
                  </div>
                </div>
                <h2 className="headingOne" id="headingOne">
                  {jobsDetails?.title} -{" "}
                  <span> {jobsDetails?.company_name}</span>
                </h2>
                <div className="d-flex">
                  <div className="doc_file">
                    <img src={draft_line} alt="" />
                  </div>
                  <div
                    className="comp_data"
                    dangerouslySetInnerHTML={{
                      __html: marked(jobsDetails?.company_overview || ""),
                    }}
                  ></div>
                </div>
                <div className="company_address">
                  <span className="job_des">
                    <img src={Bag} alt="" />{" "}
                    {capitalizeFirstLetter(
                      employmentType[jobsDetails?.employment_type] ?? ""
                    )}
                  </span>
                  <span className="job_des">
                    <img src={loacation_icon} alt="" /> {jobsDetails?.location}
                  </span>
                  <span className="job_des">
                    <img src={doc_icon} alt="" />
                    {capitalizeFirstLetter(
                      yearsOfExperience[jobsDetails?.experience] ?? ""
                    )}{" "}
                    Experience
                  </span>
                  <span className="job_des job_des_wd">
                    {renderCurrencyIcon(jobsDetails?.currency)}
                    {jobsDetails?.salary_min} - {jobsDetails?.salary_max}
                  </span>
                </div>

                <button
                  className={`accordion-button down_button ${
                    showDownButton ? "" : "d-none"
                  }`}
                  onClick={handleDownButtonClick}
                  aria-expanded={open}
                  aria-controls="collapseOne"
                >
                  <img src={Dw} alt="" />
                </button>

                <div
                  id="collapseOne"
                  className={`accordion-collapse collapse ${
                    open ? "show" : ""
                  }`}
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="job_details_main soclia_meid_main_sec_body_single">
                    <div
                      className="job_dm"
                      dangerouslySetInnerHTML={renderMarkdown(
                        jobsDetails?.description
                      )}
                    ></div>
                    <div>
                      <button
                        className="accordion-button up_button"
                        onClick={handleUpButtonClick}
                        aria-expanded={open}
                        aria-controls="collapseOne"
                      >
                        <img src={Up} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/*---------------------------------------------------- Drawer------------------------------------------------------------------------------------- */}

        <>
          <div className="custom_filter_componant">
            <button className="FilterAi_btn" type="button" onClick={showDrawer}>
              <img src={FilterAi} alt="" />
              Filter
            </button>
            <Drawer
              title="Filter"
              // width={720}
              onClose={onClose}
              open={drawer}
              styles={{
                body: {
                  paddingBottom: 80,
                },
              }}
              extra={
                <Space>
                  <div className="filter_btn_cont">
                    <Button onClick={onClose} className="reset_filter">
                      Reset Filters
                    </Button>
                    <Button
                      onClick={onApply}
                      type="primary"
                      className="reset_filter"
                    >
                      Apply Filters
                    </Button>
                  </div>
                </Space>
              }
            >
              <Form
                layout="vertical"
                hideRequiredMark
                className="filter_section_main2"
              >
                {/*-------------------------- Experience----------------------- */}
                <label>Total Experience: </label>
                <Select
                  mode="multiple"
                  maxCount={MAX_COUNT}
                  value={filters.experience}
                  style={{
                    width: "100%",
                  }}
                  onChange={handleFilterChange("experience")}
                  suffixIcon={getSuffix("experience", filters, MAX_COUNT)}
                  placeholder="Please select"
                  options={transformOptions(option?.total_experience_groups)}
                />

                {/* -------------------------- Notice Period----------------------- */}
                <label> Resignation Notice: </label>
                <Select
                  mode="multiple"
                  maxCount={MAX_COUNT}
                  value={filters.noticePeriod}
                  style={{
                    width: "100%",
                  }}
                  onChange={handleFilterChange("noticePeriod")}
                  suffixIcon={getSuffix("noticePeriod", filters, MAX_COUNT)}
                  placeholder="Please select"
                  options={transformOptions(option?.notice_period)}
                />

                {/*-------------------------- Status----------------------- */}
                <label>Status: </label>
                <Select
                  mode="multiple"
                  maxCount={MAX_COUNT}
                  value={filters.status}
                  style={{
                    width: "100%",
                  }}
                  onChange={handleFilterChange("status")}
                  suffixIcon={getSuffix("status", filters, MAX_COUNT)}
                  placeholder="Please select"
                  options={transformOptions(option?.available_status_options)}
                />

                {/*-------------------------- Location----------------------- */}
                <label>Location: </label>
                <Select
                  mode="multiple"
                  maxCount={5}
                  value={filters.location}
                  style={{
                    width: "100%",
                  }}
                  onChange={handleFilterChange("location")}
                  suffixIcon={getSuffix("location", filters, 5)}
                  placeholder="Please select"
                  options={transformOptions(option?.locations)}
                />

                {/*-------------------------- Salary----------------------- */}
                <label>Salary: </label>

                <div style={{ display: "flex", gap: "16px" }}>
                  <div>
                    <label>Max Value: </label>
                    <Input
                      value={maxValue}
                      onChange={(e) => setMaxValue(e.target.value)}
                      type="number"
                    />
                  </div>
                  <div>
                    <label>Min Value: </label>
                    <Input
                      type="number"
                      value={minValue}
                      onChange={(e) => setMinValue(e.target.value)}
                    />
                  </div>
                </div>

                <hr />
                {/*-------------------------- Assesment Rating----------------------- */}
                <div className="filter_section_main">
                  <h5 className="Assessment_p">Assessment Rating</h5>
                  <div className="mb-4">
                    <label>Technical Skills</label>
                    <Space
                      direction="vertical"
                      style={{
                        width: "100%",
                      }}
                    >
                      <Select
                        defaultValue="6+"
                        style={{
                          width: "100%",
                        }}
                        value={technicalSkillsRating}
                        onChange={setTechnicalSkillsRating}
                        options={options}
                      />
                    </Space>
                  </div>
                  <div className="mb-4">
                    <label>Problem Solving</label>
                    <Space
                      direction="vertical"
                      style={{
                        width: "100%",
                      }}
                    >
                      <Select
                        defaultValue="6+"
                        style={{
                          width: "100%",
                        }}
                        value={problemSolvingRating}
                        onChange={setProblemSolvingRating}
                        options={options}
                      />
                    </Space>
                  </div>
                  <div className="mb-4">
                    <label>Communication Skills</label>
                    <Space
                      direction="vertical"
                      style={{
                        width: "100%",
                      }}
                    >
                      <Select
                        defaultValue="6+"
                        style={{
                          width: "100%",
                        }}
                        value={communicationRating}
                        onChange={setCommunicationRating}
                        options={options}
                      />
                    </Space>
                  </div>
                  <div className="mb-4">
                    <label>Behavioral Skills</label>
                    <Space
                      direction="vertical"
                      style={{
                        width: "100%",
                      }}
                    >
                      <Select
                        defaultValue="6+"
                        style={{
                          width: "100%",
                        }}
                        value={behavioralSkillsRating}
                        onChange={setBehavioralSkillsRating}
                        options={options}
                      />
                    </Space>
                  </div>
                  <div className="mb-4">
                    <label>Overall Impression</label>
                    <Space
                      direction="vertical"
                      style={{
                        width: "100%",
                      }}
                    >
                      <Select
                        defaultValue="6+"
                        style={{
                          width: "100%",
                        }}
                        value={overallRating}
                        onChange={setOverAllRating}
                        options={options}
                      />
                    </Space>
                  </div>
                </div>
              </Form>
            </Drawer>
          </div>

          <div className="mt-3 non-responsive-filter">
            <div className="row  d-flex mb-3 align-items-baseline">
              <div className="col-8 d-flex align-items-baseline custom-gap">
                <Searchbar
                  inputValue={searchInput}
                  setInputValue={setSearchInput}
                  onSearchSubmit={handleSearchSubmit}
                />
                <StatusFilterApplicant
                  onStatusChange={setSelectedStatus}
                  selectedStatus={selectedStatus}
                />
                <Reset onReset={handleReset} />
              </div>
              <div
                className="col-4 d-flex align-items-baseline justify-content-end"
                style={{ gap: "4px" }}
              >
                <SortFilterApplicant
                  onSortChange={handleSortChange}
                  selectedValue={sortCriteria}
                />
              </div>
            </div>
          </div>
          <div className="responsive_filter">
            <div className="row  d-flex g-3 mb-1 align-items-baseline">
              <div className="col-12 d-flex align-items-baseline">
                <Searchbar
                  inputValue={searchInput}
                  setInputValue={setSearchInput}
                  onSearchSubmit={handleSearchSubmit}
                />
                <Reset onReset={handleReset} />
              </div>
              <div className="col-12 ">
                <div className="row">
                  <div className="col-6 d-flex flex-column align-items-baseline">
                    <span className="sort-by-label sort_p1">Status:</span>

                    <StatusFilterApplicant
                      onStatusChange={setSelectedStatus}
                      selectedStatus={selectedStatus}
                    />
                  </div>
                  <div className="col-6 d-flex flex-column align-items-baseline">
                    <span className="sort-by-label sort_p1">Sort By:</span>
                    <SortFilterApplicant
                      onSortChange={handleSortChange}
                      selectedValue={sortCriteria}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {isLoadingTable ? (
              <SkeletonLoader
                excludeDetails={true}
                includeTable={true}
                tableRows={5}
                columnWidths={["25%", "20%", "20%", "15%", "10%", "10%"]}
              />
            ) : (
              <Table
                columns={columns}
                dataSource={jobApplicants}
                pagination={{
                  pageSize: 12,
                  total: jobsCount,
                  current: currentPage,
                  onChange: handlePageChange,
                  showSizeChanger: false,
                }}
              />
            )}
          </div>
        </>
      </div>
      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="custom_modal_body">
          <StayConnected
            isClosing={isClosingJob}
            onConfirm={handleModalConfirm}
            onCancel={() => setIsModalOpen(false)}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={isTranscriptModalOpen}
        onHide={handleTranscriptModalCancel}
        title="Basic Modal"
        size="xl"
        centered
      >
        <Modal.Body className="custom_modal_body">
          <div className="Transcript_conatiner">
            <Tabs
              className="tablists"
              size={"large"}
              tabPosition={"left"}
              onChange={(key) => setActiveTab(key)}
              items={[
                // {
                //   label: "Resume",
                //   key: "1",
                //   children: <div className="Transcript_main"></div>,
                // },
                {
                  label: "Profile",
                  key: "1",
                  children: (
                    <div className="Transcript_main">
                      <div className="profile-header">
                        {selectedApplicant && (
                          <>
                            <div className="profile_name">
                              Profile: {selectedApplicant?.user_full_name}
                            </div>
                            {selectedApplicant?.status === "Fit-For-Job" ? (
                              <div className="resumePdf">N/A</div>
                            ) : (
                              <div className="resumePdf">
                                <img src={resumeicon} alt="" />
                                <a
                                  className="pdf"
                                  href={selectedApplicant?.resume}
                                  download={`${
                                    selectedApplicant?.user_full_name
                                  }_Resume.${getResumeFileExtension(
                                    selectedApplicant?.resume
                                  )}`}
                                >
                                  {selectedApplicant?.user_full_name}_Resume.
                                  {getResumeFileExtension(
                                    selectedApplicant?.resume
                                  )}
                                </a>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <span className="bold_text">
                        Current Annual Compensation
                      </span>
                      <div className="profile-section profile-ctc">
                        <div className="profile-ctc-value">
                          {selectedApplicant?.currency}{" "}
                          {selectedApplicant?.current_ctc}
                        </div>
                      </div>
                      {selectedApplicant?.ctc_options ? ( // Checks if ctc is not null and not undefined
                        <div>
                          <strong className="bold_text">
                            Annual Compensation Breakdown
                          </strong>
                          <div className="profile-section profile_base">
                            {selectedApplicant?.ctc_options}
                          </div>
                        </div>
                      ) : null}

                      {selectedApplicant?.physically_challenged ? ( // Checks if ctc is not null and not undefined
                        <div>
                          <strong className="bold_text">
                            Assistance Benefits
                          </strong>
                          <div className="profile-section profile_base">
                            {selectedApplicant?.physically_challenged}
                          </div>
                        </div>
                      ) : null}

                      <strong className="bold_text">Resignation Notice</strong>
                      <div className="profile-section profile-notice">
                        {selectedApplicant?.notice_period}
                      </div>
                    </div>
                  ),
                },
                {
                  label: "Transcript",
                  key: "2",
                  children: (
                    <div className="Transcript_main">
                      <div className="Transcript_main_pd">
                        <div className="transcript_header">
                          <div className="transcript_header_sub">
                            <h5>
                              Interview Transcription : {jobsDetails?.title}{" "}
                              Position
                            </h5>
                            <h6>
                              Duration:{" "}
                              {calculateTimeSpan(
                                selectedApplicant?.interview_details
                                  ?.started_at,
                                selectedApplicant?.interview_details?.ended_at
                              )}{" "}
                              min
                            </h6>
                          </div>
                          <button
                            className="transcript_download_button"
                            onClick={handleDownloadPdf}
                          >
                            {loadingPdf ? ( // Show spinner if loadingPdf is true
                              <ThreeCircles
                                color="#1865c1"
                                height={20}
                                width={20}
                              />
                            ) : (
                              <img src={download_icon} alt="" />
                            )}
                          </button>
                        </div>
                        <strong className="strong_pd">
                          Timestamp:{" "}
                          {selectedApplicant?.interview_details?.date &&
                          selectedApplicant?.interview_details?.started_at
                            ? formatDateTimeToLocalWithOffset(
                                selectedApplicant?.interview_details?.date,
                                selectedApplicant?.interview_details?.started_at
                              )
                            : "Timestamp unknown"}
                        </strong>
                        {selectedApplicant?.interview_details
                          ?.tab_switch_count >= 3 && (
                          <p className="terminated">
                            <img src={alert_terminated} alt="termitated" />{" "}
                            Interview Terminated
                          </p>
                        )}
                      </div>
                      <hr />
                      {selectedTranscript && (
                        <div className="Transcript_main_action">
                          {[...selectedTranscript]
                            .reverse()
                            .map((item, index, arr) => (
                              <div key={index}>
                                {index === arr.length - 1 ? (
                                  <p className="trans_question">
                                    <strong>
                                      Question {index + 1} : Hairo AI
                                    </strong>{" "}
                                    <br />
                                    {item?.ai_question}
                                  </p>
                                ) : (
                                  <>
                                    <p className="trans_question">
                                      <strong>
                                        Question {index + 1} : Hairo AI
                                      </strong>{" "}
                                      <br />
                                      {item?.ai_question}
                                    </p>
                                    <p className="trans_answer">
                                      <strong className="strong_answer">
                                        Answer :{" "}
                                        {selectedApplicant?.user_full_name}{" "}
                                        {item?.answer_duration && (
                                          <>
                                            {" "}
                                            | <img
                                              src={clock_icon}
                                              alt=""
                                            />{" "}
                                            {formatDurationTime(
                                              item?.answer_duration
                                            )}
                                          </>
                                        )}
                                        <br />
                                      </strong>{" "}
                                      {item?.candidate_answer}
                                      {/* {item.candidate_audio && (
                                        <AudioPlayer
                                          url={item.candidate_audio}
                                          isPlaying={
                                            activeAudioIndex ===
                                            `answer-${index}`
                                          }
                                          onTogglePlay={() =>
                                            handleTogglePlay(`answer-${index}`)
                                          }
                                        />
                                      )} */}
                                    </p>
                                    <p className="trans_interpretation">
                                      <strong>
                                        AI interpretation :
                                        <br />
                                      </strong>{" "}
                                      {item?.ai_interpretation}
                                    </p>
                                    <hr className="mr-left" />
                                  </>
                                )}
                              </div>
                            ))}
                          {selectedApplicant?.interview_details
                            ?.tab_switch_count >= 3 && (
                            <div className="termineted_section">
                              <h4 className="terminated">
                                Interview Terminated
                              </h4>
                              <p className="terminated_p">
                                The candidate's interview was terminated due to
                                frequently being distracted by switching tabs,
                                even after several reminders.
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  label: "Assessment",
                  key: "3",
                  children: (
                    <div className="Transcript_main">
                      <div className="Transcript_main_pd">
                        <div className="transcript_header">
                          <div className="transcript_header_sub">
                            <h5>
                              Interview Assesment : {jobsDetails?.title}{" "}
                              Position
                            </h5>
                            <h6>
                              Duration:{" "}
                              {calculateTimeSpan(
                                selectedApplicant?.interview_details
                                  ?.started_at,
                                selectedApplicant?.interview_details?.ended_at
                              )}{" "}
                              min
                            </h6>
                          </div>
                          <button
                            className="transcript_download_button"
                            onClick={() =>
                              handleDownloadAssessmentPdf(
                                selectedAssessment,
                                selectedApplicant
                              )
                            }
                          >
                            {loadingPdf ? ( // Show spinner if loadingPdf is true
                              <ThreeCircles
                                color="#1865c1"
                                height={20}
                                width={20}
                              />
                            ) : (
                              <img src={download_icon} alt="" />
                            )}
                          </button>
                        </div>
                        <strong className="strong_pd">
                          Timestamp:{" "}
                          {selectedApplicant?.interview_details?.date &&
                          selectedApplicant?.interview_details?.started_at
                            ? formatDateTimeToLocalWithOffset(
                                selectedApplicant?.interview_details?.date,
                                selectedApplicant?.interview_details?.started_at
                              )
                            : "Timestamp unknown"}
                        </strong>
                      </div>
                      <hr />
                      {selectedAssessment && (
                        <div className="Transcript_main_action">
                          {selectedAssessment.map((item, index) => (
                            <ul className="Transcript_page">
                              <li className="trans_question">
                                <span>Problem Solving:</span>
                                <span className="rating_section">
                                  {" "}
                                  Rating : {item?.data?.problem_solving?.rating}
                                  /10
                                </span>{" "}
                                <br />
                                {item?.data?.problem_solving?.description}
                              </li>
                              <li className="trans_question">
                                <span>Behavioral skills:</span>
                                <span className="rating_section">
                                  {" "}
                                  Rating :{" "}
                                  {item?.data?.behavioral_skills?.rating}/10
                                </span>{" "}
                                <br />
                                {item?.data?.behavioral_skills?.description}
                              </li>
                              <li className="trans_question">
                                <span>Communication Skills:</span>
                                <span className="rating_section">
                                  {" "}
                                  Rating :{" "}
                                  {item?.data?.communication_skills?.rating}/10
                                </span>{" "}
                                <br />{" "}
                                {item?.data?.communication_skills?.description}
                              </li>
                              <li className="trans_question">
                                <span>Technical Skills:</span>
                                <span className="rating_section">
                                  {" "}
                                  Rating :{" "}
                                  {item?.data?.technical_skills?.rating}/10
                                </span>{" "}
                                <br />
                                {item?.data?.technical_skills?.description}
                              </li>
                              <li className="trans_question">
                                <span>Overall Rating:</span>
                                <span className="rating_section">
                                  {" "}
                                  Rating : {item?.data?.overall_rating}/10
                                </span>{" "}
                                <br />
                                {item?.data?.overall_impression}
                              </li>
                            </ul>
                          ))}
                        </div>
                      )}
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showreport}
        onHide={handleClosereport}
        className="report_modal_sec"
      >
        <div className="report_modal_sec_inner">
          <div className="report_modal_sec_inner_head invite_modal">
            <div className="invite_modal_head">
              <h6>Invite</h6>
              <h6
                className="copylink"
                onClick={() => {
                  navigator.clipboard
                    .writeText(
                      `${window.location.origin}/jobs/${jobsDetails?.slug}` ||
                        "example.com/share-link"
                    )
                    .then(
                      () => {
                        toast.success("Link copied to clipboard!");
                      },
                      () => {
                        toast.error("Failed to copy the link.");
                      }
                    );
                }}
              >
                <img scr="" alt="" />
                Copy link
              </h6>
            </div>
            <button onClick={HideMod}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="report_modal_sec_inner_body">
            <div className="invite_mod_inner">
              <Invites onItemsChange={handleItemsChange} />
              <div className="Invite_btn_main">
                <button
                  className="Invite_btn"
                  onClick={handleReportSubmit}
                  disabled={invitedItems.length === 0}
                  style={{
                    backgroundColor: invitedItems.length > 0 ? "blue" : "gray",
                    color: "white",
                    cursor: invitedItems.length > 0 ? "pointer" : "default",
                  }}
                >
                  Invite
                </button>
              </div>
            </div>

            <div className="">
              <div className="sub_footer">
                <div>
                  <p>
                    <i className="fas fa-lock"> </i>&nbsp; Only people invited
                    to this job post
                  </p>
                </div>
                {/* <div><p>can apply</p></div> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewJobsData;

const StayConnected = ({ isClosing, onConfirm, onCancel }) => {
  const closingImageUrl = close_job; // Path to closing job image
  const reactivatingImageUrl = Question_Mark; // Path to reactivating job image

  return (
    <div className="stay_connect_sec">
      <div className="Qustion_mark">
        <img src={!isClosing ? closingImageUrl : reactivatingImageUrl} alt="" />
      </div>

      <p>
        {!isClosing
          ? "Are you sure you want to close this Job?"
          : "Are you sure you want to reactivate this Job?"}
      </p>
      <div className="button-container">
        <button className="not-confirm" onClick={onCancel}>
          No
        </button>
        <button
          className={!isClosing ? "confirm-closing" : "confirm"}
          onClick={onConfirm}
        >
          Yes
        </button>
      </div>
    </div>
  );
};
