import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoaderApply from "../components/CommonComponents/Loader/LoaderApply";

const CheckAuthAndRedirectJobs = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (token) {
      navigate(`/applicant-dashboard/related-jobs/${jobId}`);
    } else {
      navigate(`/jobs/related-jobs/${jobId}`);
    }
  }, [token, jobId, navigate]);

  return (
    <div>
      <LoaderApply />
    </div>
  );
};

export default CheckAuthAndRedirectJobs;
