import { Table } from "antd";
import moment from "moment/moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search_icon } from "../../../../../assets/images/images";
import { loadCreditHistory } from "../../../../../redux/actions/creditsActions";
import { formatDate } from "../../../../../utils/utils";
import { applicantnotfound } from '../../../../../assets/images/images'

const CreditHistory = () => {
  const token = useSelector((state) => state.auth.token);
  const jobsCount = useSelector((state) => state?.job?.jobsCount);

  const dispatch = useDispatch();
  const { history, loading, error } = useSelector((state) => state?.credits);
  const { is_premium } = useSelector((state) => state?.company?.details);
  const [sortCriteria, setSortCriteria] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (token) {
      dispatch(loadCreditHistory(token, sortCriteria, searchInput));
    }
  }, [dispatch, token, sortCriteria, searchInput]); // Added token as a dependency

  const calculateDuration = (start, end) => {
    const startDate = moment(start, "HH:mm:ss");
    const endDate = moment(end, "HH:mm:ss");
    return endDate.diff(startDate, "minutes"); // Returns the duration in minutes
  };

  // Helper function to format time
  const formatTime = (time) => {
    return moment(time, "HH:mm:ss").format("hh:mm A"); // Converts to AM/PM format
  };
  const columns = [
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
      render: (text) => <div data-th="Job Title">{text || '-'}</div>,  // Show dash if empty
    },
    {
      title: "Candidate's Name",
      dataIndex: "candidate_name",
      key: "candidate_name",
      render: (text) => <div data-th="Candidate's Name">{text || '-'}</div>,  // Show dash if empty
    },
    {
      title: "Date",
      dataIndex: "transaction_date",
      key: "Date",
      render: (text) => <div data-th="Date">{text ? formatDate(text) : '-'}</div>,  // Use formatDate or show dash if empty
    },
    {
      title: "Interview Duration",
      dataIndex: "interview_duration",
      key: "interview_duration",
      render: (text) => <div data-th="Interview Duration">{text || '-'}</div>,  // Show dash if empty
    },
    {
      title: "Time",
      dataIndex: "ended_at",
      key: "time",
      render: (text) => <div data-th="Time">{text ? formatTime(text) : '-'}</div>,  // Format or show dash if empty
    },
    {
      title: "Credits",
      dataIndex: "credits",
      key: "credits",
      render: (text) => <div data-th="Credits">{text ? <span style={{ color: "#BE0000" }}>-{text}</span> : '-'}</div>,  // Format and show dash if empty
    },
    {
      title: "Add Ons",
      dataIndex: "addons_credits",
      key: "addons_credits",
      render: (text) => <div data-th="Add Ons">{text ? <span style={{ color: "#BE0000" }}>-{text}</span> : '-'}</div>,  // Format and show dash if empty
    },
  ];
  

  console.warn(history);
  const tableData = history.map((item) => ({
    key: item?.id,
    job_title: item?.job_title,
    candidate_name: item?.candidate_name,
    transaction_date: item?.transaction_date,
    interview_duration: item?.interview_details?.ended_at
      ? `${calculateDuration(
        item?.interview_details?.started_at,
        item?.interview_details?.ended_at
      )} minutes`
      : "N/A",
    ended_at: item?.interview_details?.ended_at
      ? formatTime(item?.interview_details?.ended_at)
      : "N/A",
    credits: item?.credits,
    addons_credits: item?.addons_credits,
  }));
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSearchSubmit = (searchValue) => {
    setSearchInput(searchValue);
  };

  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
  };

  const handleReset = () => {
    setSearchInput("");
    setSortCriteria("All");
    setCurrentPage(1);
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-lg-7 col-md-8 col-sm-3">
            <div className="Search_jobs credit-search">
              <Searchbar
                inputValue={searchInput}
                setInputValue={setSearchInput}
                onSearchSubmit={handleSearchSubmit}
              />
            </div>
          
          </div>
          <div class="col-4 dropdown-inline-container cutoff-res">
            <SortFilter
              onSortChange={handleSortChange}
              selectedValue={sortCriteria}
              className="bg-white"
            />
          </div>
          <div class="col-1 dropdown-inline-container cutoff-res">
            <Reset onReset={handleReset} />
          </div>
          <div className="responsive_filter mt-3">
            <div className="row  d-flex g-3 mb-1 align-items-baseline">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-6 d-flex flex-column align-items-baseline">
                  {/* <span>Sort By</span> */}
                    <SortFilter
                            onSortChange={handleSortChange}
                            selectedValue={sortCriteria}
                            className="bg-white"
                          />
                  </div>
                  <div className="col-6 d-flex flex-column  justify-content-end">
                  <Reset onReset={handleReset} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {
          is_premium ? (
            <Table
              columns={columns}
              dataSource={tableData}
              pagination={{
                pageSize: 5,
                total: jobsCount,
                current: currentPage,
                onChange: (page) => handlePageChange(page),
                showSizeChanger: false,
              }}
            />
          ) : <Table
            columns={columns}
          />
        }
      </div>
    </>
  );
};

export default CreditHistory;

export const SortFilter = ({ onSortChange, selectedValue }) => {
  return (
    <>
      <div className="me-3 ">Sort by:</div>
      <div className="">
        <select
          className="Sortby"
          aria-label="Default select example"
          onChange={(e) => onSortChange(e.target.value)}
          value={selectedValue}
        >
          <option value="">All</option>
          <option value="-transaction_date">Newest Job</option>
          <option value="transaction_date">Oldest Job</option>
          <option value="a-z">A-Z</option>
          <option value="z-a">Z-A</option>
        </select>
      </div>
    </>
  );
};

export const Reset = ({ onReset }) => {
  return (
    <div className="reset_container">
      <button className="reset_btn" onClick={onReset}>
        Reset
      </button>
    </div>
  );
};

export const Searchbar = ({ onSearchSubmit, inputValue, setInputValue }) => {
  const inputRef = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    onSearchSubmit(inputValue);
    if (inputRef.current) {
      inputRef.current.focus(); // Maintain focus after submit
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus when the component mounts or updates
    }
  }, []);

  return (
    <div className="Search_job me-3">
      <img
        src={Search_icon}
        alt="Search_icon"
        className="Search_icon"
        onClick={handleSubmit}
        style={{ cursor: "pointer" }}
      />
      <input
        ref={inputRef}
        type="text"
        placeholder="Search Job title "
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress}
        style={{ width: "100%" }}
      />
    </div>
  );
};
