import { create } from "zustand";
import * as Yup from "yup";
import validator from "validator";

interface RegistrationFormData {
  fullName: string;
  companyName: string;
  email: string;
  contact: string;
  countryCode: string;
  password: string;
  confirmPassword: string;
  userType: "jobseeker" | "recruiter" | "";
}

interface FormErrors {
  [key: string]: string | undefined;
}

interface StoreState {
  formData: RegistrationFormData;
  formErrors: FormErrors;
  setFormData: (name: keyof RegistrationFormData, value: string) => void;
  validateForm: () => Promise<boolean>;
  validateField: (name: keyof RegistrationFormData, value: string) => void;
}

const isEmail = (email: string) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu|ai|gov|mil|biz|info|mobi|name|aero|jobs|museum|co|io|in|uk|us|ca|au|za|de|fr|eu|cn|ru|br|kr|jp|mx|es|it|nl|se|no|fi|dk|ar|cl|co|hk|my|nz|sg|th|tw|vn)$/i;
  return validator.isEmail(email) && regex.test(email);
}

const schema = Yup.object<RegistrationFormData>({
  userType: Yup.mixed<"recruiter" | "jobseeker">().required(
    "User type is required"
  ),
  fullName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z\s]*$/, "Full name must contain only letters and spaces")
    .max(30, "Full name must be no longer than 30 characters")
    .required("Please fill out your full name"),
  companyName: Yup.string()
    .trim()
    .when("userType", {
      is: "recruiter",
      then: (schema) =>
        schema
          .matches(
            /^[a-zA-Z0-9\s]*$/,
            "Company name must contain only alphanumeric characters and spaces"
          )
          .max(50, "Company name must be no longer than 50 characters")
          .required("Company name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  email: Yup.string()
    // .email("Invalid email address")
    // .matches(
    //   // Regex for more stringent email validation
    //   /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
    //   "Please use valid email address"
    // )
    .required("Email is required")
    .test("is-valid-email", "Invalid email address", (value) => isEmail(value ?? "")),

  contact: Yup.string()
    .trim()
    .matches(/^\+?\s?\d+(\s|\d|\(|\)|-)*$/, "Invalid contact number format")
    .min(14, "Contact number should be at least 10 digits")
    .max(20, "Contact number should not exceed 20 characters")
    .required("Contact is required"),

  // contact: Yup.string()
  //   .trim()
  //   .matches(/^[0-9]*$/, "Contact number must contain only digits")
  //   .min(10, "Contact number should be at least 10 digits")
  //   .max(20, "Contact number should not exceed 10 digits")
  //   .required("Contact is required"),

  password: Yup.string()
    .trim() // Optional, depends on whether you want to ignore leading/trailing spaces.
    .matches(
      /^(?=.*\S).+$/,
      "Password must contain at least one non-whitespace character"
    )
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),

  confirmPassword: Yup.string()
    .trim()
    .matches(
      /^(?=.*\S).+$/,
      "Confrim Password must contain at least one non-whitespace character"
    )
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm password is required"),
});

const registrationStore = create<StoreState>((set, get) => ({
  formData: {
    fullName: "",
    companyName: "",
    email: "",
    contact: "",
    countryCode: "",
    password: "",
    confirmPassword: "",
    userType: "jobseeker",
  },
  formErrors: {},
  setFormData: (name, value) =>
    set((state) => ({
      formData: { ...state.formData, [name]: value },
    })),
  validateForm: async () => {
    try {
      await schema.validate(get().formData, { abortEarly: false });
      set({ formErrors: {} });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = err.inner.reduce<FormErrors>(
          (acc, { path, message }) => {
            if (path) {
              acc[path as keyof RegistrationFormData] = message;
            }
            return acc;
          },
          {}
        );
        set({ formErrors: errors });
      }
      return false;
    }
  },
  validateField: async (name: keyof RegistrationFormData, value: string) => {
    try {
      await schema.validateAt(name, { ...get().formData, [name]: value });
      set({ formErrors: { ...get().formErrors, [name]: undefined } });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        set({ formErrors: { ...get().formErrors, [name]: error.message } });
      }
    }
  },
  setFormErrors: (errors: any) =>
    set((state) => ({
      formErrors: { ...state.formErrors, ...errors },
    })),
}));

export default registrationStore;
