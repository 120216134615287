import { Box, FormHelperText } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleInUp from "../../components/CommonComponents/GoogleInUp";
import {
  sendLoginOTP,
  verifyLoginOTP,
} from "../../redux/actions/otpVerificationActions";
import "../Login/Login.css";
// import { login_img } from "../../assets/images/images";
// import { ReactComponent as LoginImg } from "../../assets/images/login-img.svg";
import loginImg from "../../assets/images/login-img.webp";

import { ThreeCircles } from "react-loader-spinner";
import DashboardHeader from "../../components/CommonComponents/DashboardHeader";
import { CustomOtpInput } from "../../components/CustomComponents/CustomOtpInput";
import { setOtpLoading } from "../../redux/reducers/otpVerificationsReducer";
import { getFirebaseVerificationErrorMessage } from "../../utils/firebaseErrorFunction";
import { validatePhoneNumber } from "../../utils/utils";
import { new_bg } from "../../assets/images/images";

const Login = () => {
  const [show, setShow] = useState(false);
  const [timer, setTimer] = useState(60);
  const dispatch = useDispatch();
  const redirectUrl = useSelector((state) => state.auth.redirectUrl);
  const otpVerification = useSelector((state) => state?.otpVerification);
  const location = useSelector((state) => state?.location?.userCountry);
  const [confirmationResponse, setConfirmationResponse] = useState();
  const [phone, setPhone] = useState("");
  const [isValidNumber, setIsValidNumber] = useState(true);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit: handleSubmitOTP,
    watch,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      otp: "",
    },
  });
  const otpValue = watch("otp");
  const handleClose = () => {
    setShow(false);
    setValue("otp", "");
  };

  // const validateForm = () => {
  //   if (!validateEmail(email)) {
  //     toast.error("Invalid email address");
  //     return false;
  //   }
  //   if (password.length < 6) {
  //     toast.error("Password should be at least 6 characters long");
  //     return false;
  //   }
  //   return true;
  // };

  const handlePhoneNumberChange = (phone) => {
    setPhone(phone);
    setIsValidNumber(validatePhoneNumber(phone));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validatePhoneNumber(phone)) {
      try {
        await dispatch(sendLoginOTP(phone)).then((confirmationResult) => {
          setConfirmationResponse(confirmationResult);
          toast.success("Verification code sent successfully");
          setShow(true);
        });
        setTimer(60);
      } catch (error) {
        const errorMessage = error.message || "An unknown error occurred";
        toast.error(errorMessage);
      }
    } else {
      toast.error("Invalid phone number");
      setIsValidNumber(false);
    }
  };

  const handleVerify = async (data) => {
    toast.dismiss();
    try {
      if (confirmationResponse !== null) {
        dispatch(setOtpLoading(true));
        const response = await confirmationResponse.confirm(data.otp);
        if (redirectUrl !== null) {
          dispatch(verifyLoginOTP(response, navigate, redirectUrl));
        } else {
          dispatch(verifyLoginOTP(response, navigate));
        }
      }
    } catch (error) {
      const errorMessage = getFirebaseVerificationErrorMessage(error.code);
      dispatch(setOtpLoading(false));
      reset({ otp: "" });
      toast.error(errorMessage);
    }
  };

  const handleShowEdit = () => {
    reset({ otp: "" });
    setShow(false);
  };

  const handleResendOTP = async () => {
    if (validatePhoneNumber(phone)) {
      try {
        await dispatch(sendLoginOTP(phone)).then((confirmationResult) => {
          setConfirmationResponse(confirmationResult);
          toast.success("Verification code resent successfully");
        });
      } catch (error) {
        toast.error(error);
      }
      // Reset the OTP field in the form to be empty
      reset({ otp: "" });

      // Reset and start the timer again for the OTP resend cooldown
      setTimer(60);
    } else {
      toast.error("Invalid phone number");
    }
  };

  useEffect(() => {
    let interval = null;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [timer]);

  const handleBack = () => {
    navigate("/");
  };

  return (
    <>
      <ToastContainer />
      <div id="recaptcha-container"></div>
      <section className="login_sec_new">
      <div className="singin_sec_new_left">
          <div className="post-banner">
            <h2>Welcome to Haire </h2>
            <p>Experience Smart Recruitment in AI-Driven Recruitment Excellence.</p>
          </div>
          <div className="singin_sec_new_text">
              <div className="a">
                <h4 className="signin_qoutes">
                  Step into the future of hiring with our AI tool that not only
                  matches candidates to ideal positions but also initiates smart
                  interviews.
                </h4>
              </div>
              <div className="b">
                <h4 className="signin_qoutes">
                  Get ready for interviews that matter. Our AI-driven interview
                  process is tailored to highlight your unique capabilities,
                  connecting you directly with opportunities where you can
                  thrive.
                </h4>
              </div>
          </div>
          <img src={new_bg} alt="" />
          {/* <img src={sign_in} alt="" /> */}
        </div>

        <div className="login_sec_new_right">
          <DashboardHeader title="" backButtonLink={handleBack} />
          <form className="login_sec_new_right_inner">
            <h6>Log in</h6>
            <p>
              Unlock the full potential of your career by joining our AI-driven
              Recruitment platform.
            </p>
            <div className="inner_input_box">
              <label>Enter Mobile Number</label>
              <div className="inner_input_box_select">
                <MuiPhoneNumber
                  defaultCountry={location}
                  value={phone}
                  onChange={handlePhoneNumberChange}
                  className="mobile_number_input"
                />
              </div>
            </div>
            <div className="login_btn_blue">
              <button
                type="submit"
                disabled={!isValidNumber || !phone}
                onClick={handleSubmit}
              >
                {otpVerification?.loading ? (
                  <ThreeCircles
                    visible={true}
                    height="20"
                    width="20"
                    color="#fff"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  "Log in"
                )}
              </button>
            </div>
            <span className="or_text">Or</span>
            <GoogleInUp
              className="signup_google_sec"
              forContent="Log in with Google"
              disabled={phone == null}
            />
            <hr className="spacer20px" />
            {/* <AppleInUp
              forContent="Log in with Apple"
              className="signup_google_sec"
              disabled={phone !== null}
            /> */}
            <hr className="spacer20px" />
            <span className="alredy_have_account">
              Don’t have an account?{" "}
              <a className="login_btn_alredy" href="/sign-up">
                Sign Up
              </a>
            </span>
          </form>
        </div>
      </section>

      <Modal show={show} onHide={handleClose} className="verifictaion_mod">
        <h6>Verification Code</h6>
        <div className="phone_edit_sec">
          <span>{phone}</span>
          <Link className="edit_link_btn" onClick={handleShowEdit}>
            Edit
          </Link>
        </div>
        <form
          className="enter_otp_box_sec acc_veri"
          onSubmit={handleSubmitOTP(handleVerify)}
        >
          <label>Enter Verification Code</label>
          <div className="enter_otp_box_sec_inputs">
            <Controller
              control={control}
              rules={{ validate: (value) => value.length === 6 }}
              render={({ field, fieldState }) => (
                <Box>
                  <CustomOtpInput sx={{ gap: 1 }} {...field} length={6} />
                  {fieldState.invalid && (
                    <FormHelperText error>
                      Verification code invalid
                    </FormHelperText>
                  )}
                </Box>
              )}
              name="otp"
            />
          </div>
          <div className="resend_btn_new">
            <button
              type="button"
              onClick={handleResendOTP}
              className="resend_otp_btn_new"
              disabled={timer > 0}
            >
              Resend Verification code
            </button>
            <span>{timer < 10 ? `00:0${timer}` : `00:${timer}`}</span>
          </div>
          <div className="login_btn_blue send_verfy_btn">
            <button
              className="blue_btn_links"
              type="submit"
              disabled={otpValue?.length < 6}
            >
              {otpVerification?.loading ? (
                <ThreeCircles
                  visible={true}
                  height="20"
                  width="20"
                  color="#fff"
                  ariaLabel="loading-indicator"
                />
              ) : (
                "Verify"
              )}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Login;
