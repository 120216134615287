import { useState, useEffect, useCallback, useRef } from "react";
import * as Sentry from "@sentry/react";

const useInterviewWebSocket = (url, onResultsReceived, interviewStatusDetails, initialCountDownDone) => {
  const greetingHasBeenSent = useRef(!interviewStatusDetails?.greeting ? true : false);
  const [socket, setSocket] = useState(null);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const [shouldReconnect, setShouldReconnect] = useState(true);
  const reconnectTimeoutRef = useRef(null);

  const [socketLoading, setSocketLoading] = useState(false);
  const combinedMessage = useRef("");
  const [audioPath, setAudioPath] = useState("");
  const [interviewPercentage, setInterviewPercentage] = useState(0);
  const [interviewResult, setInterviewResult] = useState(null);
  const [autoEnd, setAutoEnd] = useState(false);

  const convertBlobtoWav = async (audioBlob) => {
    return audioBlob?.arrayBuffer();
  };

  // Function to determine the part of the day
  const getPartOfDayGreeting = () => {
    const hours = new Date().getHours();
    if (hours < 12) return "Good Morning!";
    if (hours < 18) return "Good Afternoon!";
    return "Good Evening!";
  };

  const initializeWebSocket = () => {
    const ws = new WebSocket(url);

    ws.onopen = () => {
      console.log("WebSocket connected");
      setReconnectAttempts(0); // Reset reconnect attempts on successful connection
      if (!greetingHasBeenSent.current) {
        const greeting = getPartOfDayGreeting();
        ws.send(
          JSON.stringify({
            interview_stage: "processing",
            ai_question: "",
            candidate_answer: greeting,
          })
        );
        greetingHasBeenSent.current = true; // Update the flag to indicate that the greeting has been sent
      } else {
        // check if audio and question process is done and its generating new question.
        // if(combinedMessage.current == "") {
        //   ws.send(
        //     JSON.stringify({
        //       interview_stage: "processing",
        //       ai_question: "",
        //       candidate_answer: "It seems there was an interruption. Let's continue from where we left off without knowledge to this, directly generate question.",
        //     })
        //   );
        // }
      }
    };

    ws.onmessage = (event) => {
      setSocketLoading(true);
      try {
        const data = JSON.parse(event.data);
        // Handle the WebSocket messages
        if (data.type === "result") {
          setInterviewResult(data.result);
          onResultsReceived();
        }
        if(combinedMessage.current !== data.ai_question) {
          combinedMessage.current = data.ai_question; 
          setAudioPath(data.audio_path || "");
        }
        setInterviewPercentage(data?.completion_percentage || 0);
        setTimeout(() => setSocketLoading(false), 0);

        setAutoEnd(data?.end_signal !== null ? data?.end_signal : false);
      } catch (error) {
        console.error("Error parsing message data:", error);
        setSocketLoading(false);
      }
    };

    ws.onclose = () => {
      if (shouldReconnect) {
        const delay = calculateReconnectDelay();
        console.log(`WebSocket disconnected. Reconnecting in ${delay}ms`);
        reconnectTimeoutRef.current = setTimeout(initializeWebSocket, delay);
      }
    };

    ws.onerror = (event) => {
      console.error("WebSocket error:", event);
      Sentry.captureException(new Error(`WebSocket error: ${event.data}`));
    };

    setSocket(ws);
  };

  useEffect(() => {
    if(initialCountDownDone.current){
      setShouldReconnect(true);
      initializeWebSocket();
      
      return () => {
        setShouldReconnect(false);
        clearTimeout(reconnectTimeoutRef.current);
        if (socket?.readyState === WebSocket.OPEN) {
          console.log('this hook is not working properly')
          socket?.close();
        }
      };
    }
  }, [url, initialCountDownDone.current]);

  const calculateReconnectDelay = () => {
    const maxDelay = 10000;
    let delay = 500 * Math.pow(2, reconnectAttempts);
    delay = Math.min(delay, maxDelay);
    setReconnectAttempts((attempts) => attempts + 1);
    return delay;
  };

  const sendData = useCallback(
    async ({ data, candidateAudio }) => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        combinedMessage.current = "";
        setSocketLoading(true);
        if (candidateAudio) {
          const audioData = await convertBlobtoWav(candidateAudio);
          const metaDataJson = JSON.stringify(data);
          const separator = new TextEncoder().encode("--metadata--");
          const metaDataBytes = new TextEncoder().encode(metaDataJson);
          const combinedArray = new Uint8Array(
            metaDataBytes?.byteLength +
              separator?.byteLength +
              audioData?.byteLength
          );
          combinedArray.set(new Uint8Array(metaDataBytes), 0);
          combinedArray.set(separator, metaDataBytes?.byteLength);
          combinedArray.set(
            new Uint8Array(audioData),
            metaDataBytes?.byteLength + separator?.byteLength
          );
          socket.send(combinedArray);
        } else {
          socket.send(JSON.stringify(data));
        }
      } else {
        console.error("WebSocket is not connected.");
        setSocketLoading(false);
      }
    },
    [socket]
  );

  return {
    socket,
    sendData,
    socketLoading,
    combinedMessage,
    audioPath,
    setAudioPath,
    interviewPercentage,
    interviewResult,
    autoEnd,
    greetingHasBeenSent,
    setSocketLoading
  };
};

export default useInterviewWebSocket;
