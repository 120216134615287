// store.ts
import { create } from "zustand";
import {
  auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "../config/firebaseConfig";
import Cookies from "js-cookie";
import { Dispatch } from "redux";
// @ts-ignore
import instance from "../api/instance";
import {
  setAuthToken,
  setJobseekerId,
  setNewUser,
  setUser,
} from "../redux/reducers/authReducer";
import { toast } from "react-toastify";
// @ts-ignore
import { sendVerificationOtp } from "./apis/sendVerificationOtp";
import { verifyEmailOtp } from "./apis/verifyEmailOtp";

export interface User {
  token: string | null;
  verified: boolean;
  user_id: string | null;
  full_name: string | null;
  email: string | null;
  type: number | null;
  role: number | null;
  country_code: number | null;
  phone: string | null;
  key?: string | null;
  creditsAlloted?: boolean | null;
  registered_company_name: string | null;
  company_id: number | null;
  job_seeker_id: number | null;
  languages: Record<string, string> | null;
  websites: Record<string, string> | null;
  profile_pic: string | null;
  designation: string | null;
  dob: string | null;
  gender: string | null;
  address: string | null;
  introduction: string | null;
  resume: string | null;
  resume_json: string | null;
  is_active: boolean | null;
  is_deleted: boolean | null;
  profile_completed: boolean | null;
  companyName: string | null;
}

interface UserState {
  user: User | null;
  userId: string | null;
  error: string | null;
  loading: boolean;
  modalOpen: boolean;
  registerWithEmailPassword: (
    email: string,
    password: string,
    onOtpSent: Function
  ) => void;
  verifyOtp: (
    user_id: string,
    name: string,
    email: string,
    contact: string,
    countryCode: string,
    otp: number,
    role: number,
    dispatch: Dispatch,
    navigate: Function,
    companyName?: string
  ) => Promise<boolean>;
  verifyTryOtp: (
    gmailid: string,
    full_name: string,
    country_code: string,
    otp: number,
    mob: string,
    company: string,
    industry: string,
    job_profile: string,
    dispatch: Dispatch,
    navigate: Function,
    zoho_link: string
  ) => Promise<{ verified: boolean; allowDemo: boolean }>;
  resendVerificationOtp: (email: string) => void;
  openModal: () => void;
  closeModal: () => void;
}

const userStore = create<UserState>((set) => ({
  user: null,
  userId: null,
  error: null,
  loading: false,
  modalOpen: false,
  registerWithEmailPassword: async (email, password, onOtpSent) => {
    set({ loading: true });
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const { user } = userCredential;

      set({ userId: user.uid });

      const message = await sendVerificationOtp(email);
      toast.success(message);
      onOtpSent();
    } catch (error) {
      if (error instanceof Error) {
        set({ error: error.message });
        if (error.message.includes("auth/email-already-in-use")) {
          try {
            const signInCredential = await signInWithEmailAndPassword(
              auth,
              email,
              password
            );
            const { user } = signInCredential;

            if (!user.emailVerified) {
              const message = await sendVerificationOtp(email);
              toast.success(message);
              onOtpSent();
            } else {
              toast.error(
                "Email already in use and verified. Please use a different email or log in."
              );
            }
          } catch (signInError) {
            toast.error(
              "The email is already registered with a different password. Please log in to access your account."
            );
          }
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      }
    } finally {
      set({ loading: false });
    }
  },
  verifyOtp: async (
    user_id,
    name,
    email,
    contact,
    countryCode,
    otp,
    role,
    dispatch,
    navigate,
    companyName
  ) => {
    set({ loading: true });
    try {
      await verifyEmailOtp(email, otp);

      const userDetails = {
        user_id: user_id,
        full_name: name,
        email: email,
        type: 1,
        phone: contact,
        country_code: countryCode,
        key: user_id,
        role: role,
        registered_company_name: companyName,
      };

      // Send the user details to your backend
      const response = await instance.post(
        "/accounts/social-login/",
        userDetails
      );

      // Example of handling the response
      Cookies.set("token", response.data.data.token);
      dispatch(setAuthToken(response.data.data.token));
      dispatch(setNewUser(response.data.data.is_new_user));
      dispatch(setJobseekerId(response.data.data.jobseeker_id));

      const user: User = {
        user_id: response.data.data.user.id,
        job_seeker_id: response.data.data.jobseeker_id,
        full_name: response.data.data.user.full_name,
        email: email,
        type: 3,
        country_code: null,
        phone: contact,
        key: response.data.data.user.id,
        role: response.data.data.user.role,
        registered_company_name:
          response.data.data.user.registered_company_name,
        company_id: response.data.data.company_id,
        languages: null,
        websites: null,
        profile_pic: null,
        designation: null,
        dob: null,
        gender: null,
        address: null,
        introduction: null,
        resume: response.data.data.user.resume,
        resume_json: null,
        is_active: null,
        is_deleted: null,
        profile_completed: response.data.data.profile_completed,
        token: response.data.data.token,
        companyName: companyName ? companyName : null,
        verified: true,
      };

      dispatch(setUser(user));

      set({
        user: user,
        error: null,
      });

      // Navigate user to the dashboard
      navigate(role === 1 ? "/dashboard" : "/resume-builder");
      return true; // Indicate successful verification
    } catch (error) {
      if (error instanceof Error) {
        set({ error: error.message });
      }
      return false; // Indicate failed verification
    } finally {
      set({ loading: false });
    }
  },

  verifyTryOtp: async (
    gmailid: string,
    full_name: string,
    country_code: string,
    otp: number,
    mob: string,
    company: string,
    industry: string,
    job_profile: string,
    dispatch: any,
    navigate: any,
    zoho_link: string
  ) => {
    set({ loading: true });
    set({ loading: true });
    try {
      await verifyEmailOtp(gmailid, otp);

      const userDetails = {
        gmailid: gmailid,
        full_name: full_name,
        country_code: country_code,
        mob: mob,
        company: company,
        industry: industry,
        job_profile: job_profile,
      };

      const response = await instance.post("/demo/create/", userDetails);

      if (response.data.allow_demo) {
        Cookies.set("token", response.data.user.token);
        dispatch(setAuthToken(response.data.user.token));
        const user: User = {
          user_id: response.data.id,
          job_seeker_id: null,
          full_name: response.data.full_name,
          email: gmailid,
          type: 3,
          country_code: null,
          phone: mob,
          key: response.data.id,
          creditsAlloted: response.data.free_credits,
          role: response.data.user.role,
          registered_company_name: response.data.user.registered_company_name,
          company_id: null,
          languages: null,
          websites: null,
          profile_pic: null,
          designation: null,
          dob: null,
          gender: null,
          address: null,
          introduction: null,
          resume: null,
          resume_json: null,
          is_active: null,
          is_deleted: null,
          profile_completed: null,
          token: response.data.user.token,
          companyName: company ? company : null,
          verified: true,
        };
        await dispatch(setUser(user));
        set({
          user: user,
          error: null,
        });
        navigate(
          `/try-haire/interview/${response.data.interview_demo_id}/get-ready/`
        );
      } else {
        window.open(zoho_link, "_blank");
      }
      return { verified: true, allowDemo: response.data.allow_demo };
    } catch (error) {
      if (error instanceof Error) {
        set({ error: error.message });
      }

      return { verified: false, allowDemo: false };
    } finally {
      set({ loading: false });
    }
  },

  resendVerificationOtp: async (email) => {
    set({ loading: true });
    try {
      const message = await sendVerificationOtp(email);
      toast.success(message);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        set({ error: error.message });
      }
      return false;
    } finally {
      set({ loading: false });
    }
  },

  openModal: () => set({ modalOpen: true }),
  closeModal: () => set({ modalOpen: false, error: null }),
}));

export default userStore;
