import { Form, Input, Slider } from "antd";
import { thick_right_icn, tick } from "../../../../../assets/images/images";
import {
  businessFeatures,
  enterpriseFeatures,
  freeFeatures,
} from "../../../../../utils/constants";
import { UpgradePlan } from "./UpgradePlan";
import { useMemo, useState } from "react";
import { generateSliderMarks, getKeyByValue } from "../../../../../utils/utils";
import { useNavigate } from "react-router-dom";

const Plans = ({
  free,
  business,
  enterprise,
  country,
  subscriptionPlan,
  handleSubscribePlan,
}) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    navigate("/sign-up");
  };
  const enterpriseMarks = useMemo(
    () => generateSliderMarks(enterprise),
    [enterprise, country]
  );

  const [enterpriseSliderValue, setEnterpriseSliderValue] = useState(
    getKeyByValue(
      enterpriseMarks,
      subscriptionPlan?.credits_grant?.toString()
    ) ?? 0
  );

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const shouldShowContent = enterpriseSliderValue === 1500;

  const [isBusinessLoading, setIsBusinessLoading] = useState(false);
  const [isEnterpriseLoading, setIsEnterpriseLoading] = useState(false);

  const businessMarks = useMemo(
    () => generateSliderMarks(business),
    [business, country]
  );

  const [businessSliderValue, setBusinessSliderValue] = useState(
    getKeyByValue(businessMarks, subscriptionPlan?.credits_grant?.toString()) ??
      0
  );

  const getBusinessPlan = () => {
    if (business.length > 0) {
      const index = Math.round((businessSliderValue / 100) * (business.length - 1));
      return business[index] || business[0];
    }
    return null;
  };
  
  const getEnterprisePlan = () => {
    if (enterprise.length > 0) {
      const index = Math.round((enterpriseSliderValue / 100) * (enterprise.length - 1));
      return enterprise[index] || enterprise[0];
    }
    return null;
  };

  return (
    <div className="pricing-container">
      <div className="pricing-card">
        <div className="min-h">
          <div className="pricing-header">Free</div>
          <div className="pricing-amount">{free[0]?.currency}0</div>
          {free?.map((plan) => (
            <div key={plan.id}>
              <div className="pricing-description min-hb">
                {plan?.credits_grant} Free Credits - {plan?.currency}
                {plan?.price}
              </div>
              <div>
                <div className="pricing-description">
                  Features in free plan :
                </div>
                <div className="pricing-features">
                  <ul>
                    {freeFeatures.map((feature, index) => (
                      <li key={index}>
                        <img src={tick} alt="" className="tick" /> {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
        {handleSubscribePlan ? (
          <button className="pricing-button2" onClick={() => {}}>
            Explore!
          </button>
        ) : (
          <button className="pricing-button" onClick={handleClick}>
            {"Request Access"}
          </button>
        )}
      </div>

      <div className="pricing-card">
        <div className="min-h">
          <div className="pricing-header">Business</div>
          <div className="pricing-amount">
            {getBusinessPlan()?.currency}
            {getBusinessPlan()?.price} <span>/month</span>
          </div>
          <div className="slider-container">
            <div className="credits_span1">
              {getBusinessPlan()?.credits_to_dollar}
            </div>
            <Slider
              marks={businessMarks}
              value={businessSliderValue}
              onChange={setBusinessSliderValue}
              step={null}
            />
          </div>

          <div className="pricing-description">Features in business plan :</div>
          <div className="pricing-features">
            <ul>
              {businessFeatures.map((feature, index) => (
                <li key={index}>
                  <img src={tick} alt="" /> {feature}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {handleSubscribePlan ? (
          <UpgradePlan
            credits={subscriptionPlan?.credits_grant}
            isCurrentPlan={
              subscriptionPlan?.credits_grant ===
              getBusinessPlan()?.credits_grant
            }
            subscribePlan={() =>
              handleSubscribePlan(
                getBusinessPlan()?.price,
                getBusinessPlan()?.id,
                setIsBusinessLoading
              )
            }
            isLoading={isBusinessLoading}
          />
        ) : (
          <button className="pricing-button" onClick={handleClick}>
            {"Get Started"}
          </button>
        )}
      </div>

      <div className="pricing-card">
        {shouldShowContent ? (
          <>
            {!hasSubmitted ? (
              <div className="min-h">
                <div className="pricing-header">Custom Plan</div>
                <div className="custom_plan_head">
                  Unlock all the features of both the Business and Enterprise
                  plans{" "}
                </div>
                <div className="pricing-features">
                  <div className="billing-form-data">
                    <p>Share your details and customize your plan with us! </p>
                    <Form
                      name="billing-form"
                      className="job_details_from billing_form billing_data_form"
                    >
                      <div className="row">
                        <Form.Item name="fullName" label="Full Name">
                          <Input placeholder="Enter Full Name" />
                        </Form.Item>
                        <Form.Item name="contactNumber" label="Contact Number">
                          <Input placeholder="Enter Contact Number" />
                        </Form.Item>
                        <Form.Item name="email" label="Email ID">
                          <Input placeholder="Enter Email ID" />
                        </Form.Item>
                        <div className="ContactMe">
                          <button
                            type="submit"
                            className="pricing-button"
                            onClick={() => setHasSubmitted(true)}
                          >
                            Contact Me
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            ) : (
              <div className="thankyou">
                <div>
                  <img src={thick_right_icn} alt="Checkmark" />
                  <h3>Thank you!</h3>
                  <p>Our team will contact you soon.</p>
                </div>
              </div>
            )}
          </>
        ) : (
          // The default view with the min-h div
          <>
            <div className="min-h">
              <div className="pricing-header">Enterprise</div>
              <div className="pricing-amount">
                {getEnterprisePlan()?.currency}
                {getEnterprisePlan()?.price} <span>/month</span>
              </div>
              <div className="slider-container">
                <div className="credits_span1">
                  {getEnterprisePlan()?.credits_to_dollar}
                </div>
                <Slider
                  marks={enterpriseMarks}
                  value={enterpriseSliderValue}
                  onChange={setEnterpriseSliderValue}
                  step={null}
                />
              </div>
              <div className="pricing-description">
                Features in business plan :
              </div>
              <div className="pricing-features">
                <ul>
                  {enterpriseFeatures.map((feature, index) => (
                    <li key={index}>
                      <img src={tick} alt="" /> {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {handleSubscribePlan ? (
              <UpgradePlan
                credits={subscriptionPlan?.credits_grant}
                isCurrentPlan={
                  subscriptionPlan?.credits_grant ===
                  getEnterprisePlan()?.credits_grant
                }
                subscribePlan={() =>
                  handleSubscribePlan(
                    getEnterprisePlan()?.price,
                    getEnterprisePlan()?.id,
                    setIsEnterpriseLoading
                  )
                }
                isLoading={isEnterpriseLoading}
              />
            ) : (
              <button className="pricing-button" onClick={handleClick}>
                {"Get Started"}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Plans;
