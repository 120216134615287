import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const DashboardHeader = ({
  title,
  backButtonLink,
  backButtonText = "Back",
  style
}) => {
  return (
    <div className="header" style={style}>
      <button className="back-button" onClick={backButtonLink}>
        <FontAwesomeIcon icon={faArrowLeft} /> <b>{backButtonText}</b>
      </button>
      <h1 className="header-title">{title}</h1>
    </div>
  );
};

export default DashboardHeader;
