// useJobSkillsGenerator.js
import { useState, useEffect } from "react";
import axios from "axios";

const useJobSkillsGenerator = (keyword, previousSkills) => {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const generateJobSkills = async () => {
      if (keyword?.trim() !== "") {
        try {
          const response = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
              model: "gpt-3.5-turbo-1106",
              messages: [
                {
                  role: "user",
                  content: `List up to 7 unique one to three word keywords related to ${keyword} job, separated by newlines, without bullet points or repetition. Also don't include this again ${previousSkills}`,
                },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                "Content-Type": "application/json",
              },
            }
          );

          // Assuming the response includes a list of skills in the last message from the assistant
          const lastMessage = response.data.choices[0].message.content;
          const newSkills = lastMessage
            .split("\n")
            .filter((skill) => skill.trim() !== "");

          const processedSkills = newSkills.map(
            (skill) => skill.replace(/^\d+\.\s*/, "").trim() // Remove leading numbers and periods
          );
          setSkills(processedSkills);
        } catch (error) {
          console.error("Error generating job skills:", error);
        }
      } else {
        setSkills([]); // Clear skills if keyword is empty
      }
    };

    generateJobSkills();
  }, [keyword, previousSkills]); // Only re-run the effect if keyword changes

  return skills;
};

export default useJobSkillsGenerator;
