import moment from "moment";
import "moment-timezone";
import { dollar, full3 } from "../assets/images/images";
import { businessFeatures, enterpriseFeatures, freeFeatures } from "./constants";
import { format, parseISO } from 'date-fns';

function getCountryCode(phoneNumber) {
  const countryCodeRegex = /^(\+\d+)/;
  const match = phoneNumber.match(countryCodeRegex);

  if (match) {
    return match[1];
  } else {
    return null;
  }
}

function getPhoneNumberWithoutCountryCode(phoneNumber) {
  // Remove country code
  const numberWithoutCountryCode = phoneNumber
    .replace(/^\+\d+\s*/, "")
    .replace(/[\s-]/g, "");

  return numberWithoutCountryCode;
}

function getFileListFromImageUrl(imageUrl) {
  if (!imageUrl) return [];

  return [
    {
      uid: "-1", // Ensure a unique identifier
      name: "image.png", // Any name for display purposes
      status: "done", // Indicates the file is already uploaded
      url: imageUrl,
    },
  ];
}

function isEmpty(obj) {
  if (obj === undefined || obj === null) {
    return true;
  }

  return Object.keys(obj).length === 0;
}

export {
  getCountryCode,
  getFileListFromImageUrl,
  getPhoneNumberWithoutCountryCode,
  isEmpty,
};

export const formatDate = (dateString) => {
  try {
    // Parse the ISO date string
    const date = parseISO(dateString);

    // Format the date as "dd-MM-yyyy"
    return format(date, 'dd-MM-yyyy');
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Invalid Date";
  }
};

export const calculateDaysAgo = (dateString) => {
  const [day, month, year] = dateString.split("-").map((num) => parseInt(num));
  const date = new Date(year, month - 1, day);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const diffTime = currentDate - date;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 0) {
    return "Today";
  } else if (diffDays === 1) {
    return "1 day ago";
  } else {
    return `${diffDays} days ago`;
  }
};

export const renderPlainHtml = (htmlString) => {
  const doc = new DOMParser().parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
};

export const getCurrentFormattedDateTime = () => {
  const now = new Date();

  const date = now.toISOString().split("T")[0];

  let hours = now.getHours();
  let minutes = now.getMinutes();

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  const time = `${hours}:${minutes}`;

  return { date, time };
};

export const formatDateAndTime = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return dateTime.toLocaleDateString("en-US", options);
};

//debounce function for search
export const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

// Function to transform the object to an array of { value, label } objects
export const transformOptions = (optionsObject) => {
  if (!optionsObject) return [];
  return Object.keys(optionsObject).map((key) => ({
    value: key,
    label: optionsObject[key],
  }));
};

///Function to decide the currency symbol to be rendered
export const currencyIcons = {
  USD: dollar,
  INR: full3,
};

export const renderCurrencyIcon = (currencyCode) => {
  const Icon = currencyIcons[currencyCode];
  if (Icon) {
    return <img src={Icon} alt={`${currencyCode} icon`} />;
  }
  return null;
};

export const scrollToFirstError = (errorFields) => {
  if (errorFields.length > 0) {
    setTimeout(() => {
      const firstErrorFieldId = `${errorFields[0].name[0].charAt(
        0
      )}${errorFields[0].name[0].slice(1)}`;
      const errorElement = document.getElementById(firstErrorFieldId);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 100);
  }
};

export function formatNextRenewalDateISO(dateISO) {
  // Create a date object from the ISO string
  const renewalDate = new Date(dateISO);

  // Use Intl.DateTimeFormat to format the date
  const formatter = new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  // Combine the formatted date with the prefix 'Next Renewal'
  return `${formatter.format(renewalDate)}`;
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const validatePhoneNumber = (phone) => {
  // Regex to match Indian and US phone number formats
  const regex =
    /^(\+91 \d{5}-\d{5})|(\+1[ ]?(?:\(\d{3}\)|\d{3})[ -]?\d{3}-\d{4})$/;
  return regex.test(phone);
};

export const formatPhoneNumber = (phone) => {
  // Remove any non-digit characters from the phone number
  const cleanedPhoneNumber = phone.replace(/\D/g, "");

  // Check if the cleaned phone number is missing the country code prefix
  if (cleanedPhoneNumber.length >= 10 && cleanedPhoneNumber.length <= 15) {
    // Add the country code prefix if missing
    return `%2B${cleanedPhoneNumber}`;
  } else {
    throw new Error(
      "Invalid phone number format. Please provide a valid phone number."
    );
  }
};

export function formatDurationTime(timeString) {
  // Split the string into hours, minutes, seconds, and microseconds
  const parts = timeString.split(":");
  const secondsAndMicroseconds = parts[2].split(".");

  // Extract minutes and seconds
  const minutes = parts[1];
  const seconds = secondsAndMicroseconds[0]; // ignore microseconds for mm:ss format

  // Return the formatted time
  return `${minutes}:${seconds}`;
}

export const calculateTimeSpan = (startTime, endTime) => {
  const start = new Date(`1970-01-01T${startTime}Z`);
  const end = new Date(`1970-01-01T${endTime}Z`);
  const diff = end - start; // Difference in milliseconds

  const minutes = Math.floor(diff / 60000);
  const seconds = ((diff % 60000) / 1000).toFixed(0);

  return `${minutes}:${seconds.padStart(2, "0")}`; // Format to "mm:ss"
};

export const formatDateTimeToLocalWithOffset = (
  dateString,
  timeString,
  timeZone = "Asia/Kolkata" // Default timezone is set to IST
) => {
  if (!dateString || !timeString) {
    console.error("Invalid date or time string provided.");
    return "unknown";
  }

  // Create a moment object in UTC with the given date and time
  const momentDate = moment.utc(`${dateString}T${timeString}`);

  // Convert to the desired timezone
  momentDate.tz(timeZone);

  // Format the date to the desired output format including the timezone abbreviation
  return momentDate.format(
    `dddd, MMMM D, YYYY [at] h:mm:ss A [${momentDate.tz(timeZone).format("z")}]`
  );
};

export const isPositiveInteger = (value) => {
  const number = Number(value);
  return Number.isInteger(number) && number > 0;
};

export const generateSliderMarks = (plans) => {
  const marks = {};
  plans?.forEach((plan, index) => {
    const position = (100 / (plans.length - 1)) * index;
    marks[position] = `${plan.credits_grant}`;
  });
  return marks;
};

export const renderFeatures = (subscriptionType) => {
  switch (subscriptionType) {
    case "Business":
      return businessFeatures;
    case "Enterprise":
      return enterpriseFeatures;
    default:
      return freeFeatures;
  }
};
