import React, { useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { mic_down, mic_up } from "../../assets/images/images";

const TranscriptionTest = ({ onDone }) => {
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const [testStarted, setTestStarted] = useState(false);
  const [hasReceivedInput, setHasReceivedInput] = useState(false);

  const handleButtonClick = () => {
    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      resetTranscript();
      setHasReceivedInput(false);
      SpeechRecognition.startListening({ continuous: true });
      setTestStarted(true);
    }
  };

  useEffect(() => {
    if (transcript) {
      setHasReceivedInput(true);
    }
  }, [transcript]);

  useEffect(() => {
    return () => {
      SpeechRecognition.stopListening();
    };
  }, []);

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return <div>Your browser does not support speech recognition.</div>;
  }

  // Dynamic class or style for the button based on testStarted and hasReceivedInput states
  const doneButtonClass =
    testStarted && hasReceivedInput
      ? "blue_bg_btn"
      : "blue_bg_btn disabled_button";
  const doneButtonStyle =
    testStarted && hasReceivedInput
      ? {}
      : { backgroundColor: "grey", cursor: "not-allowed" };

  return (
    <>
      <div className="InterviewModal_main">
        <div className="InterviewModal_main_inner">
          <div className="test_speaker">
            <h2>Test Your Microphone</h2>
          </div>
          <div>
            <p className="text-left listening">
              Listening: {listening ? "Yes" : "No"}
            </p>
            <p className="Transcript_box">
              {transcript || "Ex: Hello, my name is John Doe."}
            </p>
          </div>
        </div>
        <div className="space-bt">
          <div>
            <button
              className="blue_bg_btn"
              onClick={handleButtonClick}
              tabIndex="0"
            >
              <img
                src={listening ? mic_down : mic_up}
                alt=""
                style={{ width: "20px", marginRight: "10px" }}
              />
              {listening ? "Stop Listening" : "Start Listening"}
            </button>
          </div>
          <button
            disabled={!testStarted || !hasReceivedInput}
            className={doneButtonClass}
            style={doneButtonStyle}
            onClick={onDone}
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
};

export default TranscriptionTest;
