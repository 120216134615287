import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useS3Uploader from "../../hooks/useS3Uploader";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UploadComponent = ({ fileList, setFileList, onS3UrlsChange }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const { handleUpload } = useS3Uploader();
  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (file.error) {
      // Skip preview if the file has an error
      return;
    }
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = async ({ fileList: newFileList }) => {
    let shownJpgOrPngError = false;
    let shownSizeError = false;
    const jpgOrPngToastId = "jpgOrPngError";

    // Filter out files that aren't of the correct type or are too large
    const filteredFiles = newFileList.filter((file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      const isLessThan10MB = file.size / 1024 / 1024 < 10;
      if (
        (!isJpgOrPng && !shownJpgOrPngError) ||
        (!isLessThan10MB && !shownSizeError)
      ) {
        if (toast.isActive(jpgOrPngToastId)) {
          toast.dismiss(jpgOrPngToastId);
        }
        toast.error(
          "You can only upload JPG/PNG files! and Image must be smaller than 10MB!",
          { toastId: jpgOrPngToastId }
        );
        shownJpgOrPngError = true;
      }
      // if (!isLessThan10MB && !shownSizeError) {
      //   if (toast.isActive(sizeToastId)) {
      //     toast.dismiss(sizeToastId);
      //   }
      //   toast.error("Image must be smaller than 10MB!", { toastId: sizeToastId });
      //   shownSizeError = true;
      // }

      return isJpgOrPng;
    });

    // Update the file list with filtered files
    setFileList(filteredFiles);
    const filesToUpload = newFileList.filter(
      (file) => !file.s3Uploaded && file.originFileObj && !file.error
    );

    if (filesToUpload.length > 0) {
      // Use the handleUpload function from the useS3Uploader hook
      const uploadResults = await handleUpload(
        filesToUpload.map((file) => file.originFileObj)
      );
      if (uploadResults instanceof Map) {
        const s3UrlsArray = Array.from(uploadResults.values());

        // Call the callback function provided by the parent component
        onS3UrlsChange(s3UrlsArray);
        toast.success("Image uploaded successfully");
      } else {
        console.error("Unexpected uploadResults format:", uploadResults);
      }
    }
  };
  const handleRemove = (file) => {
    // Remove file from fileList
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(newFileList);
    toast.success("Image deleted successfully!");
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        accept=".jpg,.jpeg,.png"
        action=""
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleRemove}
        multiple={true}
        beforeUpload={() => false}
      >
        {fileList?.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="Preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadComponent;

// function resizeImage(file, maxWidth, maxHeight, callback) {
//   const reader = new FileReader();
//   reader.onload = (event) => {
//     const img = new Image();
//     img.onload = () => {
//       const canvas = document.createElement("canvas");
//       let width = img.width;
//       let height = img.height;

//       if (width > height) {
//         if (width > maxWidth) {
//           height *= maxWidth / width;
//           width = maxWidth;
//         }
//       } else {
//         if (height > maxHeight) {
//           width *= maxHeight / height;
//           height = maxHeight;
//         }
//       }

//       canvas.width = width;
//       canvas.height = height;

//       const ctx = canvas.getContext("2d");
//       ctx.drawImage(img, 0, 0, width, height);

//       ctx.canvas.toBlob(
//         (blob) => {
//           const resizedFile = new File([blob], file.name, {
//             type: "image/jpeg",
//             lastModified: Date.now(),
//           });
//           callback(resizedFile);
//         },
//         "image/jpeg",
//         0.7
//       );
//     };
//     img.src = event.target.result;
//   };
//   reader.readAsDataURL(file);
// }

// const beforeUpload = (file) => {
//   if (file.error) {
//     return;
//   }
//   const isImage = file.type.startsWith("image/");
//   if (!isImage) {
//     toast.error("You can only upload image files!");
//     return false;
//   }

//   const isLessThan10MB = file.size / 1024 / 1024 < 10;
//   if (!isLessThan10MB) {
//     toast.error("Image must be smaller than 10MB!");
//     return false;
//   }
//   return new Promise((resolve, reject) => {
//     resizeImage(file, 1024, 1024, (resizedFile) => {
//       resolve(resizedFile);
//     });
//   });
// };
