import { Dispatch } from "redux";
// @ts-ignore
import instance from "../../api/instance";
import {
  setSubscriptionError,
  setSubscriptionLoading,
  setSubscriptions,
} from "../reducers/subscriptionReducer";

export const loadSubscriptions = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setSubscriptionLoading(true));
    // Replace `/subscription/config/` with the actual API endpoint to fetch subscription data
    const response = await instance.get(`/subscription/config/`);
    const subscriptionsData = response.data;

    // Assuming the API response matches the structure you provided
    dispatch(setSubscriptions(subscriptionsData));
  } catch (error: any) {
    dispatch(
      setSubscriptionError(error.message || "Failed to load subscriptions")
    );
  } finally {
    dispatch(setSubscriptionLoading(false));
  }
};

export const createStripeCheckoutSession =
  (token: string, subscriptionPlanId: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(setSubscriptionLoading(true));

      const response = await instance.post(
        "/subscription/create-checkout-session/",
        { subscription_plan: subscriptionPlanId },
        { headers: { Authorization: `Token ${token}` } }
      );

      window.location.href = response.data.session_url;

      return response;
      //   dispatch(setCheckoutSession(response.data));
    } catch (error: any) {
      dispatch(
        setSubscriptionError(
          error.response?.data?.message ||
            error.message ||
            "Failed to create checkout session"
        )
      );
    } finally {
      dispatch(setSubscriptionLoading(false));
    }
  };

export const createRazorpayCheckoutSession =
  (token: string, amount: number, subscriptionPlanId: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setSubscriptionLoading(true));

      const response = await instance.post(
        "/subscription/razorpay/order/create/",
        {
          amount: amount,
          currency: "INR",
          subscription_plan: subscriptionPlanId,
        },
        { headers: { Authorization: `Token ${token}` } }
      );

      return response;
      //   dispatch(setCheckoutSession(response.data));
    } catch (error: any) {
      dispatch(
        setSubscriptionError(
          error.response?.data?.message ||
            error.message ||
            "Failed to create checkout session"
        )
      );
    } finally {
      dispatch(setSubscriptionLoading(false));
    }
  };

export const completeRazorpayOrder =
  (
    token: string,
    razorpay_subscription_id: string,
    razorpay_payment_id: string,
    razorpay_signature: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setSubscriptionLoading(true));
    try {
      await instance.post(
        "/subscription/razorpay/order/complete/",
        {
          razorpay_subscription_id,
          razorpay_payment_id,
          razorpay_signature,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      // Handle success response if needed
    } catch (error: any) {
      dispatch(
        setSubscriptionError("Failed to complete order: " + error.message)
      );
    } finally {
      dispatch(setSubscriptionLoading(false));
    }
  };

export const fetchRzPaymentStatus =
  (token: string, subscriptionId: string) => async (dispatch: Dispatch) => {
    try {
      console.log(token);
      const response = await instance.get(
        `/subscription/check-rz-payment-status/${subscriptionId}/`, // Empty object for data (as there's no payload for the request)
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      // Handle success response if needed
      return response.data; // Return the response to be used in the component
    } catch (error: any) {
      console.error("Failed to check status:", error);
      dispatch(
        setSubscriptionError("Failed to check status: " + error.message)
      );
      return null; // Return null or throw an error if you want to propagate the failure
    }
  };

export const cancelSubscriptions =
  (token: string, reason: string) => async (dispatch: Dispatch) => {
    dispatch(setSubscriptionLoading(true));
    try {
      await instance.post(
        `/subscription/cancel-subscription/`,
        { "cancel-subscription": "true", reason: reason },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
    } catch (error: any) {
      dispatch(setSubscriptionError("Failed to report job: " + error.message));
      dispatch(setSubscriptionLoading(false));
    } finally {
      dispatch(setSubscriptionLoading(false));
    }
  };
