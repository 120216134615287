import { useState, useCallback } from "react";
import AWS from "aws-sdk";

const useS3Uploader = () => {
  const [uploadStatus, setUploadStatus] = useState({
    loading: false,
    errors: [],
  })

  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION, // e.g., 'us-west-2'
  });

  const uploadFile = async (file) => {
    // Define the folder path in the bucket
    const folderPath = process.env.REACT_APP_AWS_BUCKET_FOLDER; // Adjust the folder path as needed
    const fileName = `${folderPath}${Date.now()}-${file.name}`;

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: fileName,
      Body: file,
    };

    try {
      const { Location } = await s3.upload(params).promise();
      return { url: Location, file, error: null };
    } catch (error) {
      console.error(error);
      return { error, file };
    }
  };

  const handleUpload = useCallback(
    async (files) => {
      setUploadStatus((prevStatus) => ({ ...prevStatus, loading: true }));
      const uploadPromises = files?.map((file) =>
        uploadFile(file.originFileObj || file)
      );

      const results = await Promise.all(uploadPromises);

      const errors = results
        .filter((result) => result.error)
        .map((result) => result.error);
      setUploadStatus({ loading: false, errors });

      return new Map(results.map((result) => [result.file, result.url]));
    },
    [s3]
  );

  return { uploadStatus, handleUpload };
};

export default useS3Uploader;
