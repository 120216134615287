import React, { useEffect, useRef, useState } from "react";
import { Modal, ToastContainer } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resume_builder1 } from "../../../../assets/images/images";
import useS3Uploader from "../../../../hooks/useS3Uploader";
import { createJobSeeker } from "../../../../redux/actions/jobSeekerActions";
import "../../../Login/Login.css";
import PostJobModal from "../../RecruiterDashboard/CommonRecruiterComponant/PostJobModal";
import "../ResumeBuilder/ResumeBuilder.css";
import { signOut } from "../../../../redux/actions/authActions";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ResumeBuilder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const job_seeker_id = useSelector(
    (state) => state.auth.user?.job_seeker_id ?? state.auth?.jobseeker_id
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileModal, setShowFileModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const { uploadStatus, handleUpload } = useS3Uploader();
  const [pageNumber, setPageNumber] = useState(1); // Set to display the first page
  const pdfPreviewRef = useRef(null);
  const [fileInputValue, setFileInputValue] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");

  // const [showVerificationModal, setShowVerificationModal] = useState(false);

  const token = useSelector((state) => state.auth.token);
  // const [timer, setTimer] = useState(60);
  // const [phone, setPhone] = useState(user?.phone || "");
  // const [isValidNumber, setIsValidNumber] = useState(true);
  // const { control, handleSubmit: handleSubmitOTP } = useForm({
  //   defaultValues: {
  //     otp: "",
  //   },
  // });
  // const countryCode = phone !== null && phone !== "" && getCountryCode(phone);
  // const verificationId = useSelector(
  //   (state) => state.otpVerification.verificationId
  // );

  // const handleShowEdit = () => {
  //   setshowMenu(true);
  //   setShow(false);
  // };
  // const handleCloseEdit = () => setshowMenu(false);

  // const handlePhoneNumberChange = (phone) => {
  //   setPhone(phone);
  //   setIsValidNumber(phone);
  // };

  // const handleVerification = async (data) => {
  //   navigate("/confirm-information");
  // };

  // useEffect(() => {
  //   if (showFileModal && !isJobSeekerCreationLoading) {
  //     const timer = setTimeout(() => {
  //       setShowFileModal(false);
  //       navigate("/account-verification");
  //       // setShowVerificationModal(true);
  //     }, 6000);

  //     return () => clearTimeout(timer);
  //   }
  // }, [showFileModal, navigate]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const fileSize = file.size;
    const minSize = 1000; // Minimum file size: 1 KB (in bytes)
    const maxSize = 5242880; // Maximum file size: 5 MB (in bytes)

    if (fileSize < minSize || fileSize > maxSize) {
      let errorMessage = "";
      if (fileSize < minSize) {
        errorMessage = `File size must be above 1 KB. Current file size is ${(
          fileSize / 1024
        ).toFixed(2)} KB.`;
      } else {
        errorMessage = `File size must be below 5 MB. Current file size is ${(
          fileSize / 1048576
        ).toFixed(2)} MB.`;
      }
      toast.error(errorMessage);
      return;
    }
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // MIME type for .docx
    ];

    if (file && allowedTypes.includes(file.type)) {
      setSelectedFile(file);
      setShowFileModal(true);

      // setShowVerificationModal(false);

      const uploadResults = await handleUpload([file]);
      const fileUrl = uploadResults.get(file) || uploadResults[file];

      if (fileUrl) {
        setSelectedFileType(file.type);
        setShowFileModal(true);
        setPdfUrl(fileUrl);
        try {
          await dispatch(createJobSeeker(token, fileUrl, ["English", "Hindi"]));
          if (
            file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            toast.success("Your resume is uploaded successfully");
          } else {
            toast.success(
              `${file.type
                .split("/")[1]
                .toUpperCase()} file selected and uploaded successfully`
            );
          }
          setShowFileModal(false);
          navigate("/confirm-information", { replace: true });
          setFileInputValue("");
        } catch (error) {
          setShowFileModal(false);
          toast.error("Please reupload your resume.");
        }
      }
    } else {
      toast.error("Only PDF, DOCX, DOC, JPEG, JPG, and PNG files are allowed");
    }
  };

  useEffect(() => {
    setPdfUrl(selectedFile);
    if (job_seeker_id) {
      navigate("/applicant-dashboard", { replace: true });
    }
  }, []);

  const handleBack = () => {
    dispatch(signOut());
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   if (phone) {
  //     $("#verify_sec").show();
  //     $("#verify_sec_amin").hide();
  //     toast.success("OTP sent successfully");
  //     dispatch(sendOTP(phone));
  //   } else {
  //     toast.error("Invalid phone number");
  //     return false;
  //   }
  //   handleCloseEdit();
  // };

  // const onSubmit = async (data) => {
  //   if (verificationId) {
  //     try {
  //       await dispatch(
  //         verifyOTP(
  //           verificationId,
  //           data.otp,
  //           countryCode,
  //           getPhoneNumberWithoutCountryCode(phone)
  //         )
  //       );
  //       navigate("/confirm-information");
  //     } catch (error) {
  //       toast.error(error.message || "Invalid OTP code. Please try again.");
  //     }
  //   } else {
  //     toast.error("Verification ID is missing");
  //   }
  // };

  // const handleResendOTP = () => {
  //   toast.success("OTP resent successfully");
  //   dispatch(sendOTP(phone));
  //   setTimer(60);
  // };

  return (
    <>
      <ToastContainer />
      <div id="recaptcha-container"></div>
      <section className="resume_builder_sec">
        <div className="">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="container resume-builder-header-back">
              <DashboardHeader title="" backButtonLink={handleBack} />
              </div>
              <div className="resume_builder_sec_inner">
                <div className="resume_builder_sec_inner_left">
                  <img src={resume_builder1} alt="" />
                </div>
                <div>
                
               
                <div className="resume_builder_sec_inner_right">
                  
                  <h6>AI Resume Enhancer</h6>
                  <p>
                    Optimize your job search and AI interviews <br />
                    with AI-enhanced resumes.
                  </p>
                  <div className="upload_resume_btn scanning_page">
                    <label for="myfile">Upload Resume</label>
                    <input
                      type="file"
                      id="myfile"
                      name="myfile"
                      value={fileInputValue}
                      onChange={handleFileChange}
                    />
                  </div>
                  <hr className="spacer10px" />
                </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Modal
        show={showVerificationModal}
        onHide={() => setShowVerificationModal(false)}
        className="verifictaion_mod"
      >
        <h6>Verification Code</h6>
        <div className="phone_edit_sec">
          <span>+91 9892697725</span>
          <Link className="edit_link_btn" onClick={handleShowEdit}>
            Edit
          </Link>
        </div>
        <form
          class="enter_otp_box_sec acc_veri"
          onSubmit={handleSubmitOTP(onSubmit)}
        >
          <label>Enter Verification Code</label>
          <div className="enter_otp_box_sec_inputs">
            <Controller
              control={control}
              rules={{ validate: (value) => value.length === 6 }}
              render={({ field, fieldState }) => (
                <Box>
                  <MuiOtpInput sx={{ gap: 1 }} {...field} length={6} />
                  {fieldState.invalid ? (
                    <FormHelperText error>OTP invalid</FormHelperText>
                  ) : null}
                </Box>
              )}
              name="otp"
            />
          </div>
          <div className="resend_btn_new">
            <button
              onClick={handleResendOTP}
              className="resend_otp_btn_new"
              disabled={timer > 0}
            >
              Resend OTP
            </button>
            <span>{timer < 10 ? `00:0${timer}` : `00:${timer}`}</span>
          </div>
          <div className="login_btn_blue send_verfy_btn">
            <button type="submit" className="blue_btn_links">
              Verify
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        show={showMenu}
        onHide={handleCloseEdit}
        className="verifictaion_mod"
      >
        <form onSubmit={handleSubmit} className="">
          <h6>Update Mobile Number</h6>
          <p>Please provide your new mobile number for verification process.</p>

          <div className="inner_input_box">
            <label>Enter Mobile number</label>
            <div className="inner_input_box_select">
              <MuiPhoneNumber
                defaultCountry={"us"}
                value={phone}
                onChange={handlePhoneNumberChange}
                className="mobile_number_input"
              />
            </div>
            {!isValidNumber && (
              <div style={{ color: "red" }}>
                Please enter a valid 10 digit phone number
              </div>
            )}
          </div>

          <div className="login_btn_blue send_verfy_btn">
            <button
              type="submit"
              onClick={handleSubmit}
              className="blue_btn_links"
            >
              Verify
            </button>
          </div>
        </form>
      </Modal> */}

      <Modal
        show={showFileModal}
        onHide={() => setShowFileModal(false)}
        size="lg" // or you can leave it empty for default size
        className="pdf_view_scan_modal scan"
        centered
      >
        <Modal.Body
          className={
            selectedFileType ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ? "b-gg1"
              : "b-gg fingerprint"
          }
          style={{
            padding: "20px",
            // overflowY: "auto", // Changed to auto to allow scrolling
            // overflowX: "hidden",
          }}
        >
          <div
            ref={pdfPreviewRef}
            className="file_preview"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {pdfUrl &&
              (selectedFileType.includes("image/") ? (
                <img
                  src={pdfUrl}
                  alt="Preview"
                  style={{ width: "100%", height: "auto" }}
                />
              ) : selectedFileType === "application/pdf" ? (
                <Document
                  file={pdfUrl}
                  onLoadSuccess={({ numPages }) => {
                    setPageNumber(1); // Adjust as necessary
                  }}
                >
                  <Page
                    pageNumber={pageNumber}
                    width={
                      pdfPreviewRef.current
                        ? pdfPreviewRef.current.offsetWidth - 40
                        : 600
                    }
                  />
                </Document>
              ) : selectedFileType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                // <iframe
                //   id="iframedoc"
                //   ref={iframeRef}
                //   src={`https://docs.google.com/gview?url=${pdfUrl}&embedded=true`}
                //   style={{ height: "600px", border: "none", height: "700px" }}
                // ></iframe> // DOCX preview handling
                <div>
                  <PostJobModal name="doc" />
                </div>
              ) : (
                <div>Unsupported file format.</div>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResumeBuilder;
