import { useNavigate } from "react-router-dom";
import "../../Home/home.css";
import "../../Home/home_responsive.css";

const ChosePlan = () => {
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    navigate("/sign-up");
  };
  return (
    <section className="home_banner_sec_new1">
    <div className="container-fluid banner-container1">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          {/* <header className="header-banner">
            <span><img src={star_bg} alt="star"/> Discover the Future of Recruitment</span>
          </header> */}
          <div className="AI-Driven-Smart">
            <h1>Choose the Plan <br/>
            that Suits Your Recruitment Goals.</h1>
            <p>
            Flexible pricing options to streamline your hiring process and find the best talent
            </p>
            <div class="scroll-down">
              <span class="scroll-dot"></span>
          </div>
          </div>
          <footer className="footer">
            <div className="wave-pattern"></div>
          </footer>
        </div>
      </div>
    </div>
  </section>
  );
};

export default ChosePlan;
