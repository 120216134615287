import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";

const HaireRecruiters = () => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    navigate("/sign-up");
  };
  return (
    <section className="haire_key_sec_new">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="haire_key_sec_new_inner">
              <div className="haire_key_sec_new_inner_left">
                <h5 className="wow animate__slideInLeft" data-wow-duration="4s">
                  Haire Key Features for
                  <br /> <span>Recruiters</span>
                </h5>
                <button
                  class="sign_up_btn_new after_mobile gradient-button wow animate__slideInLeft"
                  data-wow-duration="4s"
                  onClick={handleClick}
                >
                  Sign up as Recruiter
                </button>
              </div>
              <div className="haire_key_sec_new_inner_right">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item
                    eventKey="0"
                    className="wow animate__slideInUp"
                    data-wow-duration="4"
                  >
                    <Accordion.Header>
                      Comprehensive Candidate Filtering<span>1</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Minimizes errors and biases, ensuring
                        <br />
                        precise job seeker selection.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="1"
                    className="wow animate__slideInUp"
                    data-wow-duration="4.2s"
                  >
                    <Accordion.Header>
                      Anti-Cheating Measures<span>2</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Minimizes errors and biases, ensuring
                        <br />
                        precise job seeker selection.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="2"
                    className="wow animate__slideInUp"
                    data-wow-duration="4.4s"
                  >
                    <Accordion.Header>
                      Detailed Analytics<span>3</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Minimizes errors and biases, ensuring
                        <br />
                        precise job seeker selection.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="3"
                    className="wow animate__slideInUp"
                    data-wow-duration="4.6s"
                  >
                    <Accordion.Header>
                      User-Friendly Interface<span>4</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Minimizes errors and biases, ensuring
                        <br />
                        precise job seeker selection.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HaireRecruiters;
