import moment from "moment";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  int_frm,
  int_img,
  spin_circle,
  thick_right_icn,
  time,
} from "../../../../assets/images/images";
import DateCalendarValue from "../../../../components/CommonComponents/Calendar";
import LoaderApply from "../../../../components/CommonComponents/Loader/LoaderApply";
import ResponsiveTimePickers from "../../../../components/CommonComponents/Timepicker";
import { Navbar } from "../../../../components/DashboardComponents/Navbar/Navbar";
import { createInterview } from "../../../../redux/actions/jobSeekerActions";
import "../InterviewModule/InterviewModule.css";

const InterviewModule = () => {
  const UserName = useSelector((state) => state?.auth?.user);
  const SceduleInterviewJob = useSelector((state) => state?.job?.details);
  const jobDetails = useSelector((state) => state?.job?.details);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [lgShow, setLgShow] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const handleDateChange = (formattedDate) => {
    setSelectedDate(formattedDate);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleTimeChange = (formattedTime) => {
    setSelectedTime(formattedTime);
  };
  const [loading, setLoading] = useState(false);

  const [continueLoading, setContinueLoading] = useState(false);

  const handleStartInterviewClick = async () => {
    setLoading(true);
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const jobIdNumber =
        typeof jobId === "string" ? jobDetails?.id : parseInt(jobId);
      const data = await dispatch(
        createInterview(jobIdNumber, token, 1, undefined, undefined, timezone)
      );
      const newInterviewId = data.data.id;
      navigate(
        `/applicant-dashboard/related-jobs/${jobId}/start-interview/${newInterviewId}`,
        { state: { data: data.data } }
      );
    } catch (error) {
      setLoading(false);
    }
  };
  const ScheduleInterviewButton = async () => {
    setContinueLoading(true);
    try {
      const jobIdNumber =
        typeof jobId === "string" ? jobDetails?.id : parseInt(jobId);
      const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
      const formattedTime = moment(selectedTime, "HH:mm").format("HH:mm");
      const data = await dispatch(
        createInterview(jobIdNumber, token, 2, formattedDate, formattedTime)
      );
      setLgShow(false);
      setShow(true);
    } catch (error) {
      console.error("Error creating interview:", error);
      setShow(false);
      setContinueLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <section className="hire_ai_interview max_margin">
        <div className="container-fluid">
          <div className="row">
            <div className="hire_ai_interview_inner">
              <div className="hire_ai_interview_inner_left">
                <h6>Haire AI Interview</h6>
                <p>
                  Welcome to our AI Interview platform! If you're ready to begin
                  your interview right away, Please specify your interview
                  preference.
                </p>
                <ul className="hire_left_btn_sec">
                  <li>
                    <button
                      onClick={handleStartInterviewClick}
                      className="start_interview"
                      disabled={loading}
                    >
                      {loading ? <LoaderApply /> : "Start Interview"}
                    </button>
                  </li>
                  {/* <li>
                    <Link
                      className="shedule_int"
                      onClick={() => {
                        setLgShow(true);
                        setContinueLoading(false);
                      }}
                    >
                      Schedule Interview<i className="far fa-calendar"></i>
                    </Link>
                  </li> */}
                </ul>
              </div>
              <div className="hire_ai_interview_inner_right">
                <img src={int_img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        size="xl"
        show={lgShow}
        // dialogClassName="modal-100w"
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body className="mod_body_padding">
          <Container>
            <Row>
              <Col xs={6} md={4}>
                <div>
                  <DateCalendarValue onDateChange={handleDateChange} />
                  <div className=" selected_date">
                    <p>
                      {selectedDate},{selectedTime}
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="time">
                  <ResponsiveTimePickers onTimeChange={handleTimeChange} />
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="candidate_data">
                  <div>
                    <div>
                      <h6>{UserName.full_name}</h6>
                      <p className="des_name">{SceduleInterviewJob.title}</p>
                    </div>
                    <div>
                      <p className="image_time">
                        <span>
                          <img src={time} alt="" />
                          {/* <i class="fas fa-clock clock-icon"></i> */}
                        </span>
                        45 Mins
                      </p>
                      <p>
                        {selectedDate},{selectedTime}
                      </p>
                    </div>
                  </div>

                  <div className="button_section">
                    {/* Conditional rendering of loader or confirm text */}
                    <button
                      className="confirm_btn"
                      onClick={ScheduleInterviewButton}
                      disabled={continueLoading}
                    >
                      {continueLoading ? <LoaderApply /> : "Confirm"}
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          {/* <div className="datedetails_main">
            <div className="datetimepicker_container">
              <div>
                <DateCalendarValue onDateChange={handleDateChange} />
                <div className=" selected_date">
                  <p>
                    {selectedDate},{selectedTime}
                  </p>
                </div>
              </div>
              <div>
                <ResponsiveTimePickers onTimeChange={handleTimeChange} />
              </div>
            </div>
            <div className="candidate_data">
              <div>
                <div>
                  <h6>{UserName.full_name}</h6>
                  <p className="des_name">{SceduleInterviewJob.title}</p>
                </div>
                <div>
                  <p className="image_time">
                    <span>
                      <img src={time}/>
                    </span>
                    45 Mins
                  </p>
                  <p>
                    {selectedDate},{selectedTime}
                  </p>
                </div>
              </div>

              <div className="button_section" onClick={ScheduleInterviewButton}>
                <button className="confirm_btn">Confirm</button>
              </div>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className="confirm-modal"
      >
        <Modal.Body>
          <section className="spacer">
            <div className="cong_sec_new_inner_head_inner bg-blue">
              <img src={thick_right_icn} alt="" />
              <h6>This Interview is scheduled Successfully</h6>
              <p>We'll Notify You Before Your Interview Begins</p>
            </div>
            <div className="intervier_details_main">
              <div>
                <p>
                  <span>What </span> {UserName.full_name}
                </p>
              </div>
              <div>
                <p>
                  <span>When </span> {selectedDate},{selectedTime}
                </p>
              </div>
              <div>
                <p>
                  <span>Who </span> Artificial Intelligent (AI)
                </p>
              </div>
            </div>
            <div className="bestof">
              <h4> We wish you the best of luck!</h4>
              <button
                variant="secondary"
                onClick={handleClose}
                className="back_job"
              >
                Back to Job
              </button>
            </div>
          </section>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default InterviewModule;

export const StayPop = (status) => {
  return (
    <div className="stay_connect_sec">
      <h5>Stay Connected!</h5>
      <p>Your application is currently being analyzed.</p>
      <img src={spin_circle} alt="" />
    </div>
  );
};

export const Congrats = (status) => {
  return (
    <div className="congratu_sec">
      <img src={thick_right_icn} alt="" />
      <h5>Congratulations! </h5>
      <p>You have qualified for the interview with AI.</p>
    </div>
  );
};

export const Sheduleint = ({ jobId, interviewId, onlyBackButton }) => {
  return (
    <div className="Sheduleint_sec">
      {onlyBackButton ? (
        <>
          <img src={int_frm} alt="" />
          <h6>Your application is under review.</h6>
          <ul className="Sheduleint_sec_list">
            <li>
              <Link className="back_jobs" to="/applicant-dashboard">
                Back to Jobs
              </Link>
            </li>
          </ul>
        </>
      ) : (
        <>
          <img src={int_frm} alt="" />
          <h6>Would you like to move forward with your AI interview now?</h6>
          <ul className="Sheduleint_sec_list">
            <li>
              <Link className="back_jobs" to="/applicant-dashboard">
                Back to Jobs
              </Link>
            </li>
            <li>
              <Link
                to={`/applicant-dashboard/related-jobs/${jobId}/interview-module/${interviewId}`}
                className="yes_btn"
              >
                Yes
              </Link>
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

// const createInterview = async (jobId, token,status) => {
//   const { date, time } = getCurrentFormattedDateTime();
//   const url =
//     "https://backend-haire-web.mycro.news/interviews/create-interview/";
//   const body = {
//     date,
//     start_time: time,
//     job: jobId,
//   };
//   const headers = {
//     Authorization: `Token ${token}`,
//     "Content-Type": "application/json",
//   };

//   try {
//     const response = await axios.post(url, body, { headers });
//     return response.data;
//   } catch (error) {
//     console.error(
//       "Error creating interview:",
//       error.response?.data || error.message
//     );
//     throw error; // Rethrow to handle it in the component
//   }
// };
