import React, { useEffect } from "react";
import { haire_logo, video_img } from "../../../../assets/images/images";
import { formatDateAndTime } from "../../../../utils/utils";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const InterviewModuleNav = ({ data = {} }) => {
  const location = useLocation();

  useEffect(() => {
    if (data) {
      localStorage.setItem("interviewData", JSON.stringify(data));
    } else {
      return;
    }
  }, [data]);

  // Retrieve data from local storage
  const storedData = JSON.parse(localStorage.getItem("interviewData"));

  // If no data is available directly from props, use the stored data
  const displayData = data || storedData;

  const formattedDateTime = formatDateAndTime(
    displayData?.date + "T" + displayData?.start_time
  );

  useEffect(() => {
    // Clear local storage when the component is unmounted
    return () => {
      localStorage.removeItem("interviewData");
    };
  }, []);

  const shouldHideDate = /^\/try-haire\/interview\/\d+$/.test(location.pathname);

  return (
    <>
      <section className="inter_view_panel_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="inter_view_panel_sec_inner">
                <div className="inter_view_panel_sec_inner_left">
                  <img src={video_img} className="vid_img" alt="" />
                  <img src={haire_logo} className="logo_img_sec" alt="" />
                </div>
                <div className="inter_view_panel_sec_inner_right">
                  <h6>
                    {displayData?.job_title && (
                      <>{displayData?.job_title} Interview </>
                    )}
                    {displayData?.company_name && (
                      <> - {displayData?.company_name}</>
                    )}
                  </h6>
                  {!shouldHideDate && <p>{formattedDateTime && <>{formattedDateTime}</>}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InterviewModuleNav;
