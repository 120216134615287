// useJobSkillsGenerator.js
import { useState, useEffect } from "react";
import axios from "axios";

const useResponsibilitiesGenerator = (
  keyword,
  experience,
  previousResponsibilities
) => {
  const [responsibilities, setResponsibilities] = useState([]);

  useEffect(() => {
    const generateResponsibilities = async () => {
      if (keyword?.trim() !== "") {
        try {
          const response = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
              model: "gpt-3.5-turbo",
              messages: [
                {
                  role: "user",
                  content: `Provide 5 succinct tasks for a ${keyword} with ${experience} years of experience, each task consisting of 3 to 4 words maximum. Also don't include this again ${previousResponsibilities}.

                  
                  `,
                },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                "Content-Type": "application/json",
              },
            }
          );

          // Assuming the response includes a list of skills in the last message from the assistant
          const lastMessage = response.data.choices[0].message.content;
          const newResponsibilities = lastMessage
            .split("\n")
            .filter((responsibility) => responsibility.trim() !== "");

          const processedResponsibilities = newResponsibilities.map(
            (responsibility) => responsibility.replace(/^\d+\.\s*/, "").trim() // Remove leading numbers and periods
          );
          setResponsibilities(processedResponsibilities);
        } catch (error) {
          console.error("Error generating job skills:", error);
        }
      } else {
        setResponsibilities([]); // Clear skills if keyword is empty
      }
    };

    generateResponsibilities();
  }, [keyword, previousResponsibilities]); // Only re-run the effect if keyword changes

  return responsibilities;
};

export default useResponsibilitiesGenerator;
